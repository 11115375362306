import api from "./Api";

export const getTablePlanFilterOtions = async (date) => {
  try {
    const response = await api.get(`/table-plan/parties?monthYear=${date}`);
    return response;
  } catch (error) {
    return error;
  }
};
