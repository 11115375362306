import api from './Api';

export const viewLotData = async (orderId, oeId) => {
  try {
    const response = await api.get('/lot/getLot', {
      params: {
        orderId: orderId,
        oeId: oeId,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
