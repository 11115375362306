import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { LicenseManager } from "ag-grid-enterprise";

const ProdKey =
  "Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-067435}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{JC_Hometex}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{JCHome}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{JCHome}_need_to_be_licensed___{JCHome}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{21_October_2025}____[v3]_[0102]_MTc2MTAwMTIwMDAwMA==2ca90d7abec676d80c1dee879bcd082f";
LicenseManager.setLicenseKey(ProdKey);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
