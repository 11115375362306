import api from "./Api";

async function addDraftApi(draftData) {
    try {
        const response = await api.post("/draft/upsert", draftData);
        return response;
    } catch (error) {
        console.error("Failed to fetch orders:", error);
        return error;
    }
}


const getAllDrafts = async (oeId) => {
    try {
        const response = await api.get('/draft/getAll', {
            params: {
                oeId: oeId,
            },
        });
        return response;
    } catch (error) {
        return error;
    }
};

const getSingleDraft = async (oeId,id) => {
    try {
        const url = `/draft/get?oeId=${oeId}&id=${id}`;
        const response = await api.get(url);
        return response;
    } catch (error) {
        return error;
    }
};


export { addDraftApi, getAllDrafts, getSingleDraft };
