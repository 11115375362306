import React, { useCallback, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import TableBox from "../../../styles/common/TableBox";
import { Grid, IconButton } from "@mui/material";
import CustomButton from "../../common/buttons/CustomButton";
import PrintIcon from "@mui/icons-material/Print";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { exportToPDF } from "../../common/tables/PdfMaker";
const AgGridTable = ({
  colData,
  rowData,
  pagination,
  page,
  defaultColDef,
  pinnedBottomRowData,
  onCellValueChanged,
}) => {
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef();
  const gridContainerStyle = {
    height: "auto",
    width: "100%",
    color: "white",
  };

  const cellStyle = {
    color: "white",
    borderRight: "1px solid #d0d0d0",
  };
  const getRowStyle = useCallback((params) => {
    if (params.node.data?.ot) {
      return { background: "blue" };
    }
    if (params.node.rowPinned) {
      return {
        background: "white",
        fontWeight: "bold",
        color: "black",
        border: "0.5px groove #454545",
        pointerEvents: "none",
      };
    }
    return null;
  }, []);
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };

  const onBtPrint = () => {
    var eGridDiv = document.querySelector("#myGrid");
    eGridDiv.style.width = "";
    eGridDiv.style.height = "";
    gridApi.sizeColumnsToFit();
    gridApi.setDomLayout("print");
    window.print();
    gridApi.setDomLayout("autoHeight");
  };

  return (
    <TableBox>
      {page !== "viewpage" && (
        <Grid
          container
          spacing={1}
          style={{ padding: "10px", justifyContent: "end" }}
          alignItems="center"
        >
          <Grid
            item
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
            sx={{
              justifyContent: "end",
              display: "flex",
            }}
          >
            <IconButton onClick={() => onBtPrint(gridRef)}>
              <PrintIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            xs="auto"
            sm="auto"
            md="auto"
            lg="auto"
            xl="auto"
            sx={{
              justifyContent: "end",
              display: "flex",
            }}
          >
            <CustomButton
              variant="contained"
              onClick={() => exportToPDF(gridApi)}
            >
              Export To PDF
            </CustomButton>
          </Grid>
        </Grid>
      )}
      <div
        id="myGrid"
        className={"ag-theme-quartz-dark"}
        style={gridContainerStyle}
      >
        <AgGridReact
          ref={gridRef}
          columnDefs={colData}
          rowData={rowData}
          cellStyle={cellStyle}
          headerCellStyle={{ color: "white" }}
          pagination={pagination}
          paginationPageSizeSelector={[5, 10, 25, 50]}
          domLayout={"autoHeight"}
          paginationPageSize={10}
          defaultColDef={defaultColDef}
          page={page}
          onGridReady={onGridReady}
          pinnedBottomRowData={pinnedBottomRowData}
          getRowStyle={getRowStyle}
          onCellValueChanged={(event) => onCellValueChanged(event.data)}
        />
      </div>
    </TableBox>
  );
};

export default AgGridTable;
