import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import { fetchOrders } from "../../services/OrderListServices";
import { getAllDrafts } from "../../services/CuttingPlan";
import sortOrderIds from "../../components/common/SortOrders";
import CustomButton from "../../components/common/buttons/CustomButton";
import CustomAutoComplete from "../../components/common/dropdowns/CustomAutoComplete";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import OrderDetailModel from "../../components/specific/lotplan/OrderDetailsModel";
import DraftCustomTable from "../../components/specific/draft/DraftTableAg";
import EditIcon from "@mui/icons-material/Edit";
import CustomAlert from "../../components/common/CustomAlert";
import {
  freezeDraft,
  uploadFreezeDraftFiles,
} from "../../services/FreezeDraft";
import * as ENUMTEXT from "../../constants/AlertText";
import eventBus from "../../services/PubSub";
import CustomTextField from "../../components/common/textBoxs/CustomTextField";
import CustomStaticDropdown from "../../components/common/dropdowns/CustomStaticDropdown";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AddLayerConfirmation from "../../components/specific/draft/AddLayerConfirmation ";
import MerchantConfirmation from "../../components/specific/draft/MerchantConfirmation";

export default function Drafts() {
  const alertRef = useRef();
  const location = useLocation();
  const { orderInformation, draftId, freeze } = location.state || {
    orderInformation: {
      OrderNumberValue: null,
      Stylevalue: null,
    },
  };
  const initialDataLoaded = useRef(false);
  const [orderData, setOrderData] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState({});
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
  });
  const issuedFabric = 80000;
  const [fabricFreezeConfirmation, setFabricFreezeConfirmation] =
    useState(false);
  const [merchantConfirmationPopup, setMerchantConfirmationPopup] =
    useState(false);
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const [drafts, setDrafts] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState(0);
  const selectedDraftDetails = useRef({});
  const [rowData, setRowData] = useState([]);
  const [freezed, setFreezed] = useState(false);
  const sizeWithExactQuantity = useRef({});
  const [bottomRowData, setBottomRowData] = useState([]);

  useEffect(() => {
    const handleEditDraftEvent = (data) => {
      const draftId = data.message;
      const layerId = data.layerId;
      navigate("/cutting-plan/drafts/edit", {
        state: {
          orderInformation: orderInfo,
          draftId: Number(draftId),
          layerId: Number(layerId),
        },
      });
    };
    eventBus.on("editDraft", handleEditDraftEvent);
    return () => {
      eventBus.off("editDraft", handleEditDraftEvent);
    };
  }, [selectedDraft]);

  const ActionCellRenderer = (props) => {
    const handleEditDraft = () => {
      let draftId = props.node.data.id;
      let layerId = props.node.data.layerId;
      eventBus.emit("editDraft", { message: draftId, layerId: layerId });
    };
    return (
      <span>
        <IconButton onClick={handleEditDraft} style={{ cursor: "pointer" }}>
          <EditIcon />
        </IconButton>
      </span>
    );
  };
  const uploadFile = async (file, layerRowData) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("layerId", layerRowData.layerId);
    formData.append("draftId", layerRowData.id);

    const fileResponse = await uploadFreezeDraftFiles(formData);
    if (fileResponse.status === 200) {
      if (fileResponse.data) {
        alertRef.current.showAlert(
          ENUMTEXT.SUCCESS.FILEUPLOADED,
          ENUMTEXT.SUCCESS.SUCCESS_SERV
        );
      } else {
        alertRef.current.showAlert(
          ENUMTEXT.SUCCESS.FILEUPLOADED,
          ENUMTEXT.SUCCESS.SUCCESS_SERV
        );
      }
    } else {
      alertRef.current.showAlert(fileResponse, ENUMTEXT.ERROR.ERROR_SERV);
    }
  };

  const NewActionCellRenderer = (props) => {
    const fileInputRefDXF = useRef(null);
    const fileInputRefPDF = useRef(null);

    const handleUploadDXF = () => {
      fileInputRefDXF.current.click();
    };

    const handleUploadPDF = () => {
      fileInputRefPDF.current.click();
    };

    const handleFileChangeDXF = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.type !== "application/pdf") {
          uploadFile(file, props.node.data);
        } else {
          alertRef.current.showAlert(
            ENUMTEXT.WARNING.NONPDF,
            ENUMTEXT.WARNING.WARNING_SERV
          );
        }
      }
    };

    const handleFileChangePDF = (event) => {
      const file = event.target.files[0];
      if (file) {
        if (file.type === "application/pdf") {
          uploadFile(file, props.node.data);
        } else {
          alertRef.current.showAlert(
            ENUMTEXT.WARNING.PDF,
            ENUMTEXT.WARNING.WARNING_SERV
          );
        }
      }
    };

    return (
      <span>
        <IconButton style={{ cursor: "pointer" }} onClick={handleUploadDXF}>
          <UploadIcon />
        </IconButton>
        <input
          type="file"
          ref={fileInputRefDXF}
          style={{ display: "none" }}
          onChange={handleFileChangeDXF}
        />
        <IconButton style={{ cursor: "pointer" }} onClick={handleUploadPDF}>
          <UploadFileIcon />
        </IconButton>
        <input
          type="file"
          ref={fileInputRefPDF}
          style={{ display: "none" }}
          accept="application/pdf"
          onChange={handleFileChangePDF}
        />
      </span>
    );
  };

  const [colDefs, setColDefs] = useState([
    {
      field: "layerNo",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      field: "layerLength",
    },
    { field: "sizeCombinations" },
    { field: "layerWidth" },
    {
      field: "Sizes",
      children: [
        {
          field: "demandedQty",
          children: [
            {
              field: "allowedQty",
              children: [
                {
                  field: "remainingQty",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      field: "total",
      children: [
        {
          headerName: "0",
          field: "0",
          children: [
            {
              headerName: "0",
              field: "0",
              children: [
                {
                  headerName: "0",
                  field: "draftTotal",
                  aggFunc: "sum",
                },
              ],
            },
          ],
        },
      ],
    },
    { field: "efficiency" },
    { field: "totalFabric" },
    { field: "markerAverage" },

    {
      field: "action",
      cellRenderer: ActionCellRenderer,
    },
  ]);

  const processSizeWithQty = (sizeWithQty, sizeWithQuantities) => {
    if (!sizeWithQty || Object.keys(sizeWithQty).length === 0) {
      return [];
    }

    return Object.keys(sizeWithQty).map((size, index) => ({
      field: size,
      children: [
        {
          field:
            sizeWithQuantities && sizeWithQuantities[size]
              ? `${sizeWithQuantities[size].actualQuantity}`
              : "0",
          children: [
            {
              field:
                sizeWithQuantities && sizeWithQuantities[size]
                  ? `${sizeWithQuantities[size].allowedQuantity}`
                  : "0",
              children: [
                {
                  headerName:
                    sizeWithQuantities && sizeWithQuantities[size]
                      ? `${sizeWithQuantities[size].remainingCutQuantity}`
                      : "0",
                  field: size,
                },
              ],
            },
          ],
        },
      ],
    }));
  };

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    setOrderInfo({ ...orderInfoRef.current });
    if (!freezed) {
      setFreezed(false);
    }
    setRowData([]);
    setBottomRowData([]);
    setOrderDetailData({});
    setDrawerOpen(false);
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === "0") {
              child.headerName = `0`;
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "0") {
                grandchild.headerName = `0`;
              }
              grandchild.children.forEach((grandofgrandchild) => {
                if (grandofgrandchild.field === "draftTotal") {
                  grandofgrandchild.headerName = `0`;
                }
              });
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
    setDrafts([]);
  }, []);

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    setOrderInfo({ ...orderInfoRef.current });
    if (!freezed) {
      setFreezed(false);
    }
    setRowData([]);
    setBottomRowData([]);
    setOrderDetailData({
      orderNumber: orderInfoRef.current.OrderNumberValue,
      buyerName: orderInfoRef.current.buyerName,
      merchantName: orderInfoRef.current.merchantName,
      orderImage: orderInfoRef.current.orderImage || "",
    });
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === "0") {
              child.headerName = `0`;
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "0") {
                grandchild.headerName = `0`;
              }
              grandchild.children.forEach((grandofgrandchild) => {
                if (grandofgrandchild.field === "draftTotal") {
                  grandofgrandchild.headerName = `0`;
                }
              });
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  const loadOrders = async () => {
    try {
      const orderAllData = await fetchOrders();
      if (orderAllData.status === 200) {
        setOrderData(orderAllData.data.response);
        const orderIds = Object.keys(orderAllData.data.response.orders);
        const sortedOrderIds = sortOrderIds(orderIds);
        setOrderOptions(sortedOrderIds);
      } else {
        alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    loadOrders();
  }, []);

  useEffect(() => {
    if (freezed) {
      loadOrders();
      setColDefs((prevColDefs) => {
        const updatedColDefs = prevColDefs.map((colDef) => {
          if (colDef.field === "action") {
            return {
              ...colDef,
              cellRenderer: NewActionCellRenderer,
            };
          }
          return colDef;
        });
        return updatedColDefs;
      });
    }
  }, [freezed]);

  useEffect(() => {
    if (
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    )
      if (orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
          orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
          orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
            label: `${order.style ?? ""} ${order.color ?? ""} ${
              order.size ?? ""
            } ${order.remarks ?? ""}`,
            oeid: order.oeId ?? "",
            sizeWithQty: order.sizeWithQty,
          }));
          if (orderInfoRef.current.optionsStyle.length === 1) {
            orderInfoRef.current.Stylevalue =
              orderInfoRef.current.optionsStyle[0];
          }
          setOrderInfo({ ...orderInfoRef.current });
          setOrderDetailData({
            orderNumber: orderInfoRef.current.OrderNumberValue,
            buyerName: orderInfoRef.current.buyerName,
            merchantName: orderInfoRef.current.merchantName,
            orderImage: selectedOrder[0]?.imagePath || "",
          });
          setDrawerOpen(true);
        }
      } else {
        orderInfoRef.current.buyerName = "";
        orderInfoRef.current.merchantName = "";
        orderInfoRef.current.optionsStyle = [];
        setOrderInfo({ ...orderInfoRef.current });
      }
  }, [orderInfo.OrderNumberValue, orderData]);

  useEffect(() => {
    if (
      orderInfo.Stylevalue !== undefined &&
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    ) {
      if (orderInfo.Stylevalue && orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          if (orderDetail) {
            if (!orderDetail.fabAvl) {
              setDialogOpen(true);
            } else {
              orderInfoRef.current.fabricName = orderDetail.fabricName;
              orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
              orderInfoRef.current.maxNoPly = orderDetail.maxNoOfPly;
              setOrderInfo({ ...orderInfoRef.current });
              setOrderDetailData((prevData) => ({
                ...prevData,
                style: orderDetail.style,
                color: orderDetail.color,
                fabricName: orderDetail.fabricName,
                fabricQuality: orderDetail.fabricQuality,
                fabricWidth: orderDetail.fabricWidth,
                availableFabric: orderDetail.avlFabric,
                consumedFabric: orderDetail.consumedFabric,
                remainingFabric: orderDetail.remainingFabric,
                maxNoOfPly: orderDetail.maxNoOfPly,
                fabricInhouse: orderDetail.fabInHouse,
                ppApproval: orderDetail.ppApproval,
                cuttingStart: orderDetail.cuttingStartDate,
                cuttingEnd: orderDetail.cuttingEndDate,
                stitchingStart: orderDetail.stitchingStartDate,
                stitchingEnd: orderDetail.stitchingEndDate,
                deliveryDate: orderDetail.deliveryDate,
                sizeWithQty: orderDetail.sizeWithQty,
                sumOfDemandedQty: orderDetail.sumOfDemandedQty,
                noOfLots: orderDetail.noOfLots,
                merchantAvg: orderDetail.merchantAvg,
                plannedAvg: orderDetail.plannedAvg,
                sumOfAllowedQty: orderDetail.sumOfAllowedQty,
                sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
                fabricWidthDetails: orderDetail.fabricWidthDetails,
                addOnQuantity: orderDetail.addOnQuantity,
                totalNoOfParts: orderDetail.totalNoOfParts,
                fusingParts: orderDetail.fusingParts,
                handCuttingParts: orderDetail.handCuttingParts,
                reCuttingParts: orderDetail.reCuttingParts,
                pinning: orderDetail.pinning,
              }));
              const getDrafts = async () => {
                try {
                  let draftResponse = await getAllDrafts(
                    orderInfo.Stylevalue.oeid
                  );
                  if (draftResponse.status === 200) {
                    if (draftResponse.data.response.length === 0) {
                      alertRef.current.showAlert(
                        ENUMTEXT.WARNING.NO_DRAFT_FOUND,
                        ENUMTEXT.WARNING.WARNING_SERV
                      );
                    } else {
                      const newCols = processSizeWithQty(
                        orderInfo.optionsStyle[0].sizeWithQty,
                        draftResponse.data.response[selectedDraft]
                          .sizeWithQuantities
                      );
                      const updateColumnDefs = (prevCols) => {
                        let newColumnDefs = [...colDefs];
                        newColumnDefs.forEach((colDef) => {
                          if (colDef.field === "total") {
                            colDef.children.forEach((child) => {
                              if (child.headerName === "0") {
                                child.headerName = `${orderDetail.sumOfDemandedQty}`;
                              }
                              child.children.forEach((grandchild) => {
                                if (grandchild.field === "0") {
                                  grandchild.headerName = `${draftResponse.data.response[selectedDraft].sumOfSizeWithAllowedQty}`;
                                }
                                grandchild.children.forEach(
                                  (grandofgrandchild) => {
                                    if (
                                      grandofgrandchild.field === "draftTotal"
                                    ) {
                                      grandofgrandchild.headerName = `${draftResponse.data.response[selectedDraft]?.sumOfSizeWithCutQty}`;
                                    }
                                  }
                                );
                              });
                            });
                          }
                        });
                        if (prevCols.length === 10) {
                          const insertIndex = 5;
                          return [
                            ...prevCols.slice(0, insertIndex),
                            ...newCols,
                            ...prevCols.slice(insertIndex),
                          ];
                        } else {
                          let value = prevCols;
                          value.splice(5, newCols.length, ...newCols);
                          return value;
                        }
                      };
                      setColDefs((prevCols) => updateColumnDefs(prevCols));
                      const transformData = (response) => {
                        const extractedFields = newCols.map(
                          (item) => item.field
                        );
                        return response.layers.map((layer) => {
                          const draftData = {
                            id: response.id,
                            layerId: layer.layerId,
                            layerNo: layer.number,
                            layerLength: layer.length,
                            sizeCombinations: layer.sizeCombo,
                            layerWidth: layer.width,
                            efficiency: layer.efficiency,
                            totalFabric: layer.totalFabric,
                            markerAverage: layer.avg,
                            remainingQty: layer.qty,
                            // draftTotal: layer.qty,
                            sizeWithRemainingQty: layer.sizeWithRemainingQty,
                          };
                          extractedFields.forEach((size) => {
                            draftData[size] = layer.sizeWithQty[size];
                          });

                          return draftData;
                        });
                      };
                      sizeWithExactQuantity.current =
                        draftResponse.data.response[
                          selectedDraft
                        ].sizeWithQuantities;
                      selectedDraftDetails.current = {
                        id: draftResponse.data.response[selectedDraft].id,
                        oeId: draftResponse.data.response[selectedDraft].oeId,
                      };
                      setRowData(
                        transformData(
                          draftResponse.data.response[selectedDraft]
                        )
                      );

                      const transformBottomRowData = (response) => {
                        const extractedFields = newCols.map(
                          (item) => item.field
                        );
                        const totalData = {
                          layerNo: "Total",
                          markerAverage: response.layerAvg,
                          totalFabric: response.totalFabricConsumed,
                          efficiency: response.totalEfficiency,
                          layerLength: response.avgLayerLength,
                          remainingQty: response.totalPlyQty,
                          draftTotal: response.piecesCut,
                        };
                        extractedFields.forEach((size) => {
                          totalData[size] =
                            response.sizeWithQuantities[size].sumOfCutQuantity;
                        });
                        return [totalData];
                      };
                      setBottomRowData(
                        transformBottomRowData(
                          draftResponse.data.response[selectedDraft]
                        )
                      );
                      if (freezed) {
                        setRowData(
                          transformData(
                            draftResponse.data.response[selectedDraft]
                          )
                        );
                        setBottomRowData(
                          transformBottomRowData(
                            draftResponse.data.response[selectedDraft]
                          )
                        );
                      }

                      setDrafts(draftResponse.data.response);
                    }
                  } else {
                    alertRef.current.showAlert(
                      draftResponse,
                      ENUMTEXT.ERROR.ERROR_SERV
                    );
                  }
                } catch (e) {}
              };
              getDrafts();
            }
          }
        }
      } else {
        orderInfoRef.current.fabricName = "";
        orderInfoRef.current.fabricQuality = "";
        orderInfoRef.current.maxNoPly = "";
        setOrderInfo({ ...orderInfoRef.current });
      }
    }
  }, [
    orderInfo.Stylevalue,
    orderData,
    orderInfo.OrderNumberValue,
    selectedDraft,
  ]);

  useEffect(() => {
    const setOrderInfoData = async () => {
      if (
        !initialDataLoaded.current &&
        orderInformation.OrderNumberValue &&
        orderInformation.Stylevalue
      ) {
        orderInfoRef.current.OrderNumberValue =
          orderInformation.OrderNumberValue;
        orderInfoRef.current.Stylevalue = orderInformation.Stylevalue;
        orderInfoRef.current.buyerName = orderInformation.buyerName;
        orderInfoRef.current.fabricName = orderInformation.fabricName;
        orderInfoRef.current.fabricQuality = orderInformation.fabricQuality;
        orderInfoRef.current.maxNoPly = orderInformation.maxNoPly;
        orderInfoRef.current.merchantName = orderInformation.merchantName;
        orderInfoRef.current.optionsStyle = orderInformation.optionsStyle;
        orderInfoRef.current.avlFabric = orderInformation.avlFabric;
        setOrderInfo({ ...orderInfoRef.current });
        setSelectedDraft(draftId);
        if (freeze) {
          setFreezed(true);
        }
        initialDataLoaded.current = true;
      }
    };
    setOrderInfoData();
  }, [orderInfo.OrderNumberValue, orderInfo.Stylevalue]);

  const handleDraftClick = (draftNumber, id, oeId) => {
    setSelectedDraft(draftNumber);
    selectedDraftDetails.current = { id: id, oeId: oeId };
  };

  const navigate = useNavigate();

  const handleAddDraftNavigate = () => {
    if (drafts.length < 6) {
      navigate("/cutting-plan/drafts/add", {
        state: { orderInformation: orderInfo },
      });
    } else {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.DRAFT_LIMIT_EXCEED,
        ENUMTEXT.WARNING.WARNING_SERV
      );
    }
  };

  const compareDraftNavigate = () => {
    navigate("/cutting-plan/drafts/compare", {
      state: { orderInformation: orderInfo },
    });
  };
  const submitfreezedDraft = async () => {
    if (freezed) {
      let response = await freezeDraft(
        selectedDraftDetails.current.id,
        selectedDraftDetails.current.oeId,
        freezeData
      );
      if (response.status === 200) {
        alertRef.current.showAlert(
          ENUMTEXT.SUCCESS.DRAFTFREEZED,
          ENUMTEXT.SUCCESS.SUCCESS_SERV
        );
        navigate("/cutting-plan", { state: { orderInformation: orderInfo } });
      } else {
        alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
      }
    } else {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.MIN_ONE_DRAFT,
        ENUMTEXT.WARNING.WARNING_SERV
      );
    }
  };

  const FreezeDraftTable = async () => {
    console.log(bottomRowData);
    const reqData = bottomRowData[0];
    if (reqData.totalFabric > issuedFabric) {
      setFabricFreezeConfirmation(true);
    } else if (
      reqData.totalFabric / reqData.draftTotal + 0.06 >
      orderDetailData.merchantAvg
    ) {
      setMerchantConfirmationPopup(true);
    } else {
      submitfreezedDraft();
    }
  };

  // const getCompareDraftsData = () => {
  //   if (selectedOptions.includes("All")) {
  //     return optionsDrafts.slice(1);
  //   } else {
  //     return selectedOptions;
  //   }
  // };

  const freezedObject = {
    totalNumberParts: 0,
    FusingParts: 0,
    handCuttingParts: 0,
    reCuttingParts: 0,
    pinning: false,
  };
  const [freezeData, setFreezeData] = useState(freezedObject);
  const handleFreezeData = (e) => {
    const { name, value } = e.target;

    setFreezeData((prevObject) => ({
      ...prevObject,
      [name]: value,
    }));
  };

  // const [pinningValue, setPinningValue] = useState();
  // const handleStaticdropdown = (e) => {
  //   setPinningValue(e.target.value);
  //   console.log("setpinningValue", e.target.value);
  // };

  const setfreezed = () => {
    setMerchantConfirmationPopup(false);
    setFreezed(true);
  };

  const handleFreeze = () => {
    setfreezed();
  };

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <ResponsiveDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}></Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box style={{ marginRight: "15px" }}>
              <CustomButton
                variant="contained"
                sx={{ height: "max-content" }}
                onClick={handleAddDraftNavigate}
              >
                Add Drafts
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {freezed ? (
          <>
            <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
              <Grid container spacing={2} style={{ padding: "10px" }}>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomTextField
                    value={freezeData.totalNumberParts}
                    onChange={handleFreezeData}
                    type="number"
                    name="totalNumberParts"
                    label="Total Number of Parts"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomTextField
                    value={freezeData.FusingParts}
                    onChange={handleFreezeData}
                    type="number"
                    name="FusingParts"
                    label="Fusing Parts"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomTextField
                    value={freezeData.handCuttingParts}
                    onChange={handleFreezeData}
                    type="number"
                    name="handCuttingParts"
                    label="Hand Cutting Parts"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomTextField
                    value={freezeData.reCuttingParts}
                    onChange={handleFreezeData}
                    type="number"
                    name="reCuttingParts"
                    label="Re-Cutting Parts"
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
                  <CustomStaticDropdown
                    value={freezeData.pinning}
                    onChange={handleFreezeData}
                    label="Pinning"
                    name="pinning"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <DraftCustomTable
                colDefs={colDefs}
                drawerOpen={drawerOpen}
                rowData={rowData}
                whichPage={"Freezed"}
                selectedDraftDetails={selectedDraftDetails}
                setFreezed={setFreezed}
                freezed={freezed}
                sizeWithQty={sizeWithExactQuantity.current}
                pinnedBottomRowData={bottomRowData}
              />
            </Box>
            <Box style={{ marginTop: "30px", marginLeft: "15px" }}>
              <CustomButton
                variant="contained"
                type="submit"
                onClick={FreezeDraftTable}
              >
                Submit
              </CustomButton>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
              <Grid container spacing={1} style={{ padding: "10px" }}>
                <Grid item xs={12} sm={3} md={3} lg={2} xl={2}></Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={8}
                  xl={8}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Box>
                    {drafts.length > 1 && (
                      <Tabs value={selectedDraft} scrollButtons="auto" centered>
                        {drafts.map((draft, index) => (
                          <Tab
                            label={`Draft ${index + 1}`}
                            value={index}
                            onClick={() =>
                              handleDraftClick(index, draft.id, draft.oeId)
                            }
                          />
                        ))}
                      </Tabs>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  md={3}
                  lg={2}
                  xl={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Box>
                    {drafts.length > 1 && (
                      <CustomButton
                        variant="contained"
                        type="submit"
                        onClick={compareDraftNavigate}
                      >
                        Compare Draft
                      </CustomButton>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <DraftCustomTable
                colDefs={colDefs}
                drawerOpen={drawerOpen}
                rowData={rowData}
                whichPage={"draftPlan"}
                setFreezed={handleFreeze}
                selectedDraft={selectedDraft}
                sizeWithQty={sizeWithExactQuantity.current}
                pinnedBottomRowData={bottomRowData}
              />
            </Box>
          </>
        )}
        <AddLayerConfirmation
          open={fabricFreezeConfirmation}
          handleConfirm={submitfreezedDraft}
          handleUpdateLot={() => {
            console.log("call");
            navigate("/lot-plan/add", {
              state: { autoSelectLotId: orderInfo.OrderNumberValue },
            });
          }}
          confirmText={"Submit"}
          updateText={"Update Lot"}
          Dialogtext={
            "Fabric issued is less than fabric needed in the draft, please add fabric as it may impact quantity"
          }
          Dialogtitle={"Fabric Issue"}
        />
        <MerchantConfirmation
          open={merchantConfirmationPopup}
          handleConfirm={submitfreezedDraft}
          handleUpdateLot={() => {
            navigate("/lot-plan/receive-fabric", {
              state: { autoSelectOrderId: orderInfo.OrderNumberValue },
            });
          }}
          confirmText={"Submit"}
          updateText={"Update Average"}
        />
      </Box>
    </>
  );
}
