import { Box, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import DatePickerStatic from "../../components/common/calendars/DatePicker";
import CustomStaticDropdown from "../../components/common/dropdowns/CustomStaticDropdown";
import SelectDropdown from "../../components/common/dropdowns/DropDown";
import AgGridTable from "../../components/specific/cuttingTrack/CuttingTrackTable";
import CustomTextField from "../../components/common/textBoxs/CustomTextField";
import CustomButton from "../../components/common/buttons/CustomButton";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import OrderDetailModel from "../../components/specific/cuttingTrack/OrderDetailModel";
import { useLocation, useNavigate } from "react-router-dom";
import * as ENUMTEXT from "../../constants/AlertText";

import {
  GetEditTrack,
  SubmitEditTrack,
} from "../../services/CuttingTrackServices";
import CustomAlert from "../../components/common/CustomAlert";
import SwapPopUp from "./SwapPopUp";
import SaveChangeConfirmation from "./SaveChangeConfirmation";

export default function CuttingTrack() {
  const navigate = useNavigate();
  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      minWidth: 200,
      floatingFilter: true,
    }),
    []
  );
  const [swapconfirmation, setSwapConfirmation] = useState(false);
  const [saveswapconfirmation, setsaveswapconfirmation] = useState(false);
  const [rowData, setRowData] = useState([]);
  const alertRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [data, setData] = useState();
  const [bottomRowData, setBottomRowData] = useState([]);
  const [editData, setEditData] = useState();

  const location = useLocation();
  const Id = location.state.Id;
  const oeId = location.state.oeId;

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "Lot Details",

      children: [
        {
          field: "LotNumber",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
        },
        {
          field: "PlyLength",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
        },
        {
          field: "NumberofPly",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
      ],
      flex: 1,
    },
    {
      field: "Fabric Details",
      children: [
        {
          field: "FabricAvailable",
          flex: 1,
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
        {
          field: "FabricUsed",
          flex: 1,
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
        {
          field: "FabricReturn",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
        {
          field: "ThanNumber",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
        {
          field: "ThanMeter",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
        {
          field: "Balance",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
        {
          field: "Remarks",
          filter: "agSetColumnFilter",
          suppressSizeToFit: false,
          flex: 1,
          // editable: true,
          editable: (params) => {
            // Check if the row is a pinned bottom row
            return !params.node.rowPinned || params.node.rowPinned !== "bottom";
          },
        },
      ],
    },
  ]);

  const TrackEdit = async (Id, oeId) => {
    try {
      const response = await GetEditTrack(Id, oeId);
      if (response.status === 200) {
        const ResponseData = response.data.response;
        setData(ResponseData);
        console.log("ResponseData", ResponseData);
        return ResponseData;
      } else {
        console.error("Unexpected response status:", response.status);
      }
    } catch (err) {
      console.log("GetError", err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await TrackEdit(Id, oeId);
        setEditData(response);
        //particular layer data
        const layersizes =
          response.trackDetailsDTO.layerSizeCombinationForTickets.filter(
            (x) => x.plyQuantity !== 0
          );
        const sizeCombo = response.sizeCombination;
        const sizeComboArray = sizeCombo.split(/[\s,-]+/);
        console.log("sizeComboArray", sizeComboArray);

        let result = {
          field: "ticketSequencing",
          flex: 1,
          children: sizeComboArray.map((size) => ({
            field: size,
            children: [
              {
                headerName: "start",
                field: `${size}start`,
                flex: 1,
              },
              {
                headerName: "end",
                field: `${size}end`,
                flex: 1,
              },
            ],
          })),
        };
        setColumnDefs((prevCols) => [prevCols[0], result, prevCols[1]]);
        let tempRowData = [];
        console.log("layersizes", layersizes);
        const arr = layersizes?.map((item) => {
          let tempObject = {};
          for (let i = 0; i < item.layerSizeCombinations.length; i++) {
            tempObject[`${item.layerSizeCombinations[i].size}start`] =
              item.layerSizeCombinations[i].start;
            tempObject[`${item.layerSizeCombinations[i].size}end`] =
              item.layerSizeCombinations[i].end;
          }
          return {
            LotNumber: item.lotNumber,
            PlyLength: item.plyLength,
            NumberofPly: item.plyQuantity,
            FabricAvailable: item.fabricAvailable || null,
            FabricUsed: item.fabricUsed,
            FabricReturn: item.fabricReturn ?? 0,
            ThanNumber: item.thanNumber,
            ThanMeter: item.thanMeter,
            Balance: item.balance,
            Remarks: item.remarks,
            viewable: item.viewable,
            ticketSequenceId: item.ticketSequenceId,
            ...tempObject,
            lotId: item.lotId,
          };
        });
        setRowData(arr);

        const totalData = {
          LotNumber: "Total",
          PlyLength: arr.length > 0 ? arr[0].PlyLength : 0,
          NumberofPly: returnTotal("NumberofPly", arr),
          FabricAvailable: returnTotal("FabricAvailable", arr),
          FabricUsed: returnTotal("FabricUsed", arr),
          FabricReturn: returnTotal("FabricReturn", arr),
        };

        setBottomRowData([totalData]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [Id, oeId]);

  const handleSubmit = async (force) => {
    let tempData = [];
    for (let i = 0; i < rowData.length; i++) {
      let rowItem = rowData[i];
      let defaultRowItem =
        data.trackDetailsDTO.layerSizeCombinationForTickets[i];
      let layerSizeCombinations = [];
      for (let x = 0; x < defaultRowItem.layerSizeCombinations.length; x++) {
        const defaultCombination = defaultRowItem.layerSizeCombinations[x];
        layerSizeCombinations.push({
          id: defaultCombination.id,
          lotNumber: defaultCombination.lotNumber,
          start: rowItem[`${defaultCombination.size}start`],
          end: rowItem[`${defaultCombination.size}start`],
          size: defaultCombination.size,
        });
      }
      tempData.push({
        lotNumber: rowItem.LotNumber,
        ticketSequenceId: rowItem.ticketSequenceId,
        fabricUsed: rowItem.FabricUsed,
        fabricReturn: rowItem.FabricReturn,
        thanNumber: rowItem.ThanNumber,
        thanMeter: rowItem.ThanMeter,
        balance: rowItem.Balance,
        remarks: rowItem.Remarks,
        lotId: rowData[i].lotId,
        plyQuantity: rowData[i].NumberofPly,
        layerSizeCombinations: layerSizeCombinations,
      });
    }

    const finalObject = {
      layerName: data.trackDetailsDTO.layerName,
      oeId: oeId,
      layerSizeCombinationForTickets: tempData,
      forceSequence: force === undefined ? false : true,
    };

    const response = await SubmitEditTrack(Id, finalObject);

    if (response.data.code === 200) {
      const message = response.message || "An error occurred.";
      alertRef.current.showAlert(ENUMTEXT.SUCCESS.EDIT_SUCCESS);
    } else if (response.data.code === 400) {
      setSwapConfirmation(true);
      return;
    } else if (response.data.code === 409) {
      const message = response.message || "An error occurred.";
      alertRef.current.showAlert(ENUMTEXT.WARNING.ALREADY_EDITED);
    }
    navigate(`/cutting-track`);
  };
  const returnTotal = (key, data) => {
    const value = data.reduce(
      (accumulator, current) => accumulator + Number(current[key] ?? 0),
      0
    );
    return value;
  };
  const onCellValueChanged = (data) => {
    const tempData = rowData;
    const index = tempData.findIndex((obj) => obj.lotId === data.lotId);
    tempData[index] = data;

    setBottomRowData([
      {
        ...bottomRowData[0],
        NumberofPly: returnTotal("NumberofPly", tempData),
        FabricAvailable: returnTotal("FabricAvailable", tempData),
        FabricUsed: returnTotal("FabricUsed", tempData),
        FabricReturn: returnTotal("FabricReturn", tempData),
      },
    ]);
    setRowData(tempData);
  };
  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={data}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={2} style={{ padding: "15px" }}></Grid>

        <AgGridTable
          colData={columnDefs}
          rowData={rowData}
          editable={true}
          defaultColDef={defaultColDef}
          pinnedBottomRowData={bottomRowData}
          pagination={true}
          onCellValueChanged={onCellValueChanged}
        />
        <Grid container style={{ paddingTop: "10px" }}>
          <Grid item xs={12} style={{ padding: "20px" }}>
            <CustomButton
              variant="contained"
              onClick={() => setsaveswapconfirmation(true)}
              disabled={editData?.editable === false}
            >
              Save Changes
            </CustomButton>
          </Grid>
        </Grid>
      </Box>

      <SaveChangeConfirmation
        open={saveswapconfirmation}
        handleClose={() => setsaveswapconfirmation(false)}
        handleConfirm={() => handleSubmit()}
      />
      <SwapPopUp
        open={swapconfirmation}
        handleClose={() => setSwapConfirmation(false)}
        handleConfirm={() => handleSubmit(true)}
      />
    </>
  );
}
