import React, { forwardRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const CustomAutoComplete = forwardRef((props, ref) => {
  const { value, onChange, options, label, error, disabled } = props;

  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      id="controllable-states-demo"
      options={options}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          ref={ref}
          value={ref.current?.value}
          InputLabelProps={{ shrink: true }}
          error={error}
          label={label}
        />
      )}
    />
  );
});

export default CustomAutoComplete;
