import { Box } from "@mui/material";
import styled from "styled-components";

const TableBox = styled(Box)`
  width: ${({ drawerOpen }) => (drawerOpen ? "calc(100% - 290px)" : "98%")};
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
  border: 1px solid gray;
  border-radius: 10px;
  transition: "all 0.3s ease";

.ag-body-vertical-scroll {
    display: none;
  }
    .ag-layout-normal{
    overflow-y:hidden;
    }
`;

export default TableBox;
