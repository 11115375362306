import React from 'react';
import { Button, useTheme } from '@mui/material';



const CustomButton = (props) => {
  const theme = useTheme();
  const { background, text } = theme.palette.button;

  return <Button style={{
    backgroundColor: `${background}`, color: `${text}`, height: "32px", borderRadius: "10px", padding: '20px', cursor: 'pointer' ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.15)',
}} {...props} />;
};

export default CustomButton;
