import api from './Api';

export const getFabricQuality = async (fabricId) => {
  try {
    const response = await api.get('/fabric/get/quality', {
      params: {
        fabricId: fabricId,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};
