export default function sortOrderIds(orderIds) {
    return orderIds.sort((a, b) => {
      const regex = /^(\d+)\s*(.*)$/;
      const [, numA, alphaA] = a.match(regex);
      const [, numB, alphaB] = b.match(regex);
      const numericA = parseInt(numA, 10);
      const numericB = parseInt(numB, 10);
      if (numericA !== numericB) {
        return numericA - numericB;
      }
      return alphaA.localeCompare(alphaB);
    });
  }


  

  