import api from "./Api";

async function fetchOrders() {
  try {
    const response = await api.get("/order/all");
    return response;
  } catch (error) {
    console.log("Failed to fetch orders:", error);
    return error;
  }
}

async function fetchOrdersRecieveFabric() {
  try {
    const response = await api.get("/order/rec/fabs");
    return response;
  } catch (error) {
    console.log("Failed to fetch orders:", error);
    return error;
  }
}

export { fetchOrders, fetchOrdersRecieveFabric };
