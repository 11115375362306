import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Divider, Grid, IconButton, useTheme } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { fetchOrdersRecieveFabric } from "../../services/OrderListServices";
import { fetchFabrics } from "../../services/GetFabricServices";
import { getFabricQuality } from "../../services/GetFabricQuality";
import { addfabric } from "../../services/AddFabric";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import sortOrderIds from "../../components/common/SortOrders";
import CustomButton from "../../components/common/buttons/CustomButton";
import CustomAutoComplete from "../../components/common/dropdowns/CustomAutoComplete";
import CustomAlert from "../../components/common/CustomAlert";
import CustomTextField from "../../components/common/textBoxs/CustomTextField";
import { useLocation } from "react-router-dom";
import OrderDetailModel from "../../components/specific/lotplan/OrderDetailsModel";
import * as ENUMTEXT from "../../constants/AlertText";

function checkNull(data) {
  for (let item of data) {
    for (let key in item) {
      if (item[key] === null || item[key] === undefined || item[key] === "") {
        return false;
      }
    }
  }
  return true;
}

function findNextAlphabet(chars) {
  let result = "";
  chars.forEach((char) => {
    const alphaMatch = char.match(/[A-Za-z]/);
    if (alphaMatch) {
      const alpha = alphaMatch[0];
      const nextAlpha = String.fromCharCode(alpha.charCodeAt(0) + 1);
      if (nextAlpha > result) {
        result = nextAlpha;
      }
    }
  });
  if (result == "") {
    return "A";
  }
  return result;
}

function alphabetPosition(letter) {
  return letter.toUpperCase().charCodeAt(0) - "A".charCodeAt(0) + 1;
}

export default function SubOrder() {
  const location = useLocation();
  const { orderInformation, autoSelectOrderId } = location.state || {};
  const initialDataLoaded = useRef(false);
  const theme = useTheme();
  const alertRef = useRef();
  const [orderData, setOrderData] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
  });
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const [fabricNameValue, setFabricNameValue] = useState(null);
  const fabricNameInputValue = useRef();
  const selectFabricUseRef = useRef();
  const [fabricQualityValue, setFabricQualityValue] = useState(null);
  const fabricQualityInputValue = useRef();
  const [optionsFabricName, setOptionsFabricName] = useState([]);
  const [optionsFabricUse, setOptionsFabricUse] = useState([
    "Lining",
    "Shell",
    "Jacket",
    "Collar",
    "Sleeves",
  ]);
  const [optionsFabricQulaity, setOptionsFabricQuality] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState({});
  const [fabricData, setFabricData] = useState([]);
  const [deletedFabric, setDeletedFabric] = useState([]);
  const [checkQuanlity, setCheckQuality] = useState(false);
  const [rows, setRows] = useState([
    {
      id: 1,
      fabricName: "",
      fabricQuality: "",
      fabricWidth: "",
      fabricAverage: "",
      fabricUse: "",
      fId: "",
      qId: "",
    },
  ]);

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    setOrderDetailData({});
    setDrawerOpen(false);
    setOrderInfo({ ...orderInfoRef.current });
  }, []);

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    setOrderDetailData({
      orderNumber: orderInfoRef.current.OrderNumberValue,
      buyerName: orderInfoRef.current.buyerName,
      merchantName: orderInfoRef.current.merchantName,
      orderImage: orderInfoRef.current.orderImage || "",
    });
    setOrderInfo({ ...orderInfoRef.current });
  }, []);

  const loadOrders = async () => {
    try {
      const orderItems = await fetchOrdersRecieveFabric();
      if (orderItems.status === 200) {
        setOrderData(orderItems.data.response);
        const orderIds = Object.keys(orderItems.data.response.orders);
        const sortedOrderIds = sortOrderIds(orderIds);
        setOrderOptions(sortedOrderIds);
      } else {
        setOrderOptions([]);
        alertRef.current.showAlert(orderItems, ENUMTEXT.ERROR.ERROR_SERV);
      }
    } catch (error) {
      setOrderOptions([]);
      console.error(error);
    }
  };

  useEffect(() => {
    loadOrders();
  }, []);

  useEffect(() => {
    if (autoSelectOrderId !== undefined) {
      handleOrderChange(undefined, autoSelectOrderId);
    }
  }, []);

  useEffect(() => {
    if (
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    )
      if (orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
          orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
          orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
            label: `${order.style ?? ""} ${order.color ?? ""} ${
              order.size ?? ""
            } ${order.remarks ?? ""}`,
            oeid: order.oeId ?? "",
          }));
          if (orderInfoRef.current.optionsStyle.length === 1) {
            orderInfoRef.current.Stylevalue =
              orderInfoRef.current.optionsStyle[0];
          }
          setOrderInfo({ ...orderInfoRef.current });
          setOrderDetailData({
            orderNumber: orderInfoRef.current.OrderNumberValue,
            buyerName: orderInfoRef.current.buyerName,
            merchantName: orderInfoRef.current.merchantName,
            orderImage: selectedOrder[0]?.imagePath || "",
          });
          setDrawerOpen(true);
        }
      } else {
        orderInfoRef.current.buyerName = "";
        orderInfoRef.current.merchantName = "";
        orderInfoRef.current.optionsStyle = [];
        setOrderInfo({ ...orderInfoRef.current });
      }
  }, [orderInfo.OrderNumberValue, orderData]);

  useEffect(() => {
    const fetchLotData = async () => {
      if (orderInformation !== undefined)
        if (
          !initialDataLoaded.current &&
          orderInformation.OrderNumberValue &&
          orderInformation.Stylevalue
        ) {
          orderInfoRef.current.OrderNumberValue =
            orderInformation.OrderNumberValue;
          orderInfoRef.current.Stylevalue = orderInformation.Stylevalue;
          orderInfoRef.current.buyerName = orderInformation.buyerName;
          orderInfoRef.current.fabricName = orderInformation.fabricName;
          orderInfoRef.current.fabricQuality = orderInformation.fabricQuality;
          orderInfoRef.current.maxNoPly = orderInformation.maxNoPly;
          orderInfoRef.current.merchantName = orderInformation.merchantName;
          orderInfoRef.current.optionsStyle = orderInformation.optionsStyle;
          // orderInfoRef.current.ppApproval=orderInformation.ppApproval;
          setOrderInfo({ ...orderInfoRef.current });
          setRows([
            {
              id: 1,
              fabricName: "",
              fabricQuality: "",
              fabricWidth: "",
              fabricAverage: "",
              fId: "",
              qId: "",
              orderId: orderInformation.OrderNumberValue,
            },
          ]);
          initialDataLoaded.current = true;
        }
    };
    fetchLotData();
  }, [orderInfo.OrderNumberValue, orderInfo.Stylevalue]);

  useEffect(() => {
    if (
      orderInfo.Stylevalue !== undefined &&
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    )
      if (orderInfo.Stylevalue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder && selectedOrder.length > 0) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          const fetchFabricData = async () => {
            try {
              const response = await fetchFabrics();
              if (response.status === 200) {
                const fabricNameOptions = Object.entries(response.data).map(
                  ([key, value]) => {
                    return {
                      label: value,
                      value: key,
                    };
                  }
                );
                setOptionsFabricName(fabricNameOptions);
              } else {
                alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
              }
            } catch (error) {
              console.error("Failed to fetch fabrics:", error);
            }
          };

          if (orderDetail && orderDetail.fabAvl) {
            orderInfoRef.current.fabricName = orderDetail.fabricName;
            orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
            orderInfoRef.current.maxNoPly = orderDetail.maxNoOfPly;
            setOrderInfo({ ...orderInfoRef.current });
            setOrderDetailData((prevData) => ({
              ...prevData,
              style: orderDetail.style,
              color: orderDetail.color,
              ppApproval: orderDetail.ppApproval,
              cuttingStart: orderDetail.cuttingStartDate,
              cuttingEnd: orderDetail.cuttingEndDate,
              stitchingStart: orderDetail.stitchingStartDate,
              stitchingEnd: orderDetail.stitchingEndDate,
              deliveryDate: orderDetail.deliveryDate,
              // fabricName: orderDetail.fabricName,
              // fabricQuality: orderDetail.fabricQuality,
              // fabricWidth: orderDetail.fabricWidth,
              // availableFabric: orderDetail.avlFabric,
              // consumedFabric: orderDetail.consumedFabric,
              // remainingFabric: orderDetail.remainingFabric,
              // maxNoOfPly: orderDetail.maxNoOfPly,
              // fabricInhouse: orderDetail.fabInHouse,
              // sizeWithQty: orderDetail.sizeWithQty,
              // sumOfDemandedQty: orderDetail.sumOfDemandedQty,
              // noOfLots: orderDetail.noOfLots,
              // merchantAvg: orderDetail.merchantAvg,
              // plannedAvg: orderDetail.plannedAvg,
            }));
            if (orderDetail.fabricDTO) {
              setFabricData(orderDetail.fabricDTO);
              let arr = orderDetail.fabricDTO.map((x, i) => {
                handleFabricSelect({ label: x.name, value: x.fId });
                return {
                  id: 1 + i,
                  fabricName: x.name,
                  fabricQuality: x.quality,
                  fabricWidth: x.width,
                  fabricAverage: x.avg,
                  fId: x.fId,
                  qId: x.qId,
                  orderId: x.orderId,
                  oeId: x.oeId,
                };
              });
              setRows(arr);
            } else {
              setRows([
                {
                  id: 1,
                  fabricName: "",
                  fabricQuality: "",
                  fabricWidth: "",
                  fabricAverage: "",
                  fId: "",
                  qId: "",
                  orderId: orderDetail.orderId,
                },
              ]);
            }
            fetchFabricData();
          } else {
            setFabricData(orderDetail.fabricDTO);
            fetchFabricData();
          }
        }
      } else {
        setRows([
          {
            id: 1,
            fabricName: "",
            fabricQuality: "",
            fabricWidth: "",
            fabricAverage: "",
            fId: "",
            qId: "",
            orderId: orderInfo.OrderNumberValue,
          },
        ]);
      }
  }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

  const handleAddRow = () => {
    if (orderInfo.OrderNumberValue === null || orderInfo.Stylevalue === "") {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.UNSELECT_ORDER_STYLE,
        ENUMTEXT.WARNING.WARNING_SERV
      );
    } else {
      const lastRow = rows[rows.length - 1];
      const orderIds = rows.map((item) => item.orderId);
      if (checkNull([lastRow])) {
        const newRow = {
          id: alphabetPosition(findNextAlphabet(orderIds)) + 1,
          fabricName: "",
          fabricQuality: "",
          fabricWidth: "",
          fabricAverage: "",
          fId: "",
          qId: "",
          orderId: `${rows[0].orderId} ${findNextAlphabet(orderIds)}`,
        };
        setRows([...rows, newRow]);
      } else {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.BLANK_PREV_ROW,
          ENUMTEXT.WARNING.WARNING_SERV
        );
      }
    }
  };

  const handleDeleteRow = (rowId, index) => {
    setRows(rows.filter((row) => row.id !== rowId));
    setDeletedFabric([...deletedFabric, fabricData[index]]);
    fabricData.splice(index, 1);
  };

  const handleQualityCheck = (quality) => {
    const newRows = rows.slice(0, -1);
    for (let i = 0; i < newRows.length; i++) {
      const row = newRows[i];
      if (
        Number(quality?.value) === Number(row.qId) ||
        row.qId === "" ||
        row.qId === null
      ) {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.SAME_QUALITY,
          ENUMTEXT.WARNING.WARNING_SERV
        );
        setCheckQuality(true);
        break;
      } else {
        setCheckQuality(false);
      }
    }
    // newRows.forEach(row => {
    //   if (Number(quality?.value) === Number(row.qId) || row.qId === "" || row.qId === null) {
    //     alertRef.current.showAlert(ENUMTEXT.WARNING.SAME_QUALITY, ENUMTEXT.WARNING.WARNING_SERV);
    //     setCheckQuality(true);
    //   } else {
    //     setCheckQuality(false);
    //   }
    // })
  };

  const handleFabricSelect = async (fabric) => {
    try {
      const fabricQualityDetails = await getFabricQuality(fabric.value);
      if (fabricQualityDetails.status === 200) {
        const fabricQualityOptions = Object.entries(
          fabricQualityDetails.data
        ).map(([key, value]) => {
          return {
            label: value,
            value: key,
          };
        });
        setOptionsFabricQuality(fabricQualityOptions);
      } else {
        alertRef.current.showAlert(
          fabricQualityDetails,
          ENUMTEXT.ERROR.ERROR_SERV
        );
      }
    } catch (error) {
      console.error("Failed to fetch fabric details:", error);
    }
  };

  const handleSubmit = async () => {
    if (
      !orderInfo.OrderNumberValue ||
      !orderInfo.Stylevalue ||
      !checkNull(rows)
    ) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.REQUIRED_FIELD,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    if (checkQuanlity === true) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.SAME_QUALITY,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }

    const orderId = orderInfo.OrderNumberValue;
    const oeId = orderInfo.Stylevalue ? orderInfo.Stylevalue.oeid : null;

    const fabricDetails = [];
    const initialFabrics = fabricData || [];

    rows.forEach((row, index) => {
      const initialFabric = initialFabrics[index];
      const isInitialFabric = !!initialFabric;
      let initoeid;
      if (initialFabric) {
        initoeid = initialFabric.oeId;
      }
      if (isInitialFabric) {
        fabricDetails.push({
          fId: row.fId,
          qId: row.qId,
          width: row.fabricWidth,
          avg: parseFloat(row.fabricAverage),
          orderId: row.orderId,
          oeId: initoeid,
        });
      } else {
        if (row.id === 1) {
          fabricDetails.push({
            fId: row.fId,
            qId: row.qId,
            width: row.fabricWidth,
            avg: parseFloat(row.fabricAverage),
            orderId: row.orderId,
            oeId: oeId,
          });
        } else {
          fabricDetails.push({
            fId: row.fId,
            qId: row.qId,
            width: row.fabricWidth,
            avg: parseFloat(row.fabricAverage),
            orderId: row.orderId,
          });
        }
      }
    });

    deletedFabric.forEach((fabric) => {
      if (fabric) {
        fabricDetails.push({
          fId: fabric.fId,
          qId: fabric.qId,
          width: fabric.width,
          avg: parseFloat(fabric.avg),
          orderId: null,
          oeId: fabric.oeId,
        });
      }
    });

    const requestBody = {
      orderId,
      oeId,
      fabricDetails,
    };
    try {
      const response = await addfabric(requestBody);
      if (response.status === 200) {
        alertRef.current.showAlert(
          ENUMTEXT.SUCCESS.FABRICADDED,
          ENUMTEXT.SUCCESS.SUCCESS_SERV
        );
        orderInfoRef.current.OrderNumberValue = null;
        orderInfoRef.current.Stylevalue = null;
        setOrderInfo({ ...orderInfoRef.current });
        setFabricNameValue(null);
        setFabricQualityValue(null);
        setOrderDetailData({});
        setDrawerOpen(false);
        setRows([
          {
            id: 1,
            fabricName: "",
            fabricQuality: "",
            fabricWidth: "",
            fabricAverage: "",
            fId: "",
            qId: "",
            orderId: "",
          },
        ]);
        loadOrders();
      } else {
        alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
      }
    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}
      ></Divider>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid
          container
          spacing={2}
          style={{ padding: "10px", paddingTop: "20px" }}
        >
          {rows.map((row, index) => (
            <>
              <Grid item xs={12} lg={2} xl={2}>
                <CustomAutoComplete
                  value={row.fabricName ? row.fabricName : fabricNameValue}
                  onChange={(event, newValue) => {
                    setRows(
                      rows.map((r) =>
                        r.id === row.id
                          ? {
                              ...r,
                              fabricName: newValue?.label ?? "",
                              fabricQuality: null,
                              fId: newValue?.value ?? "",
                              qId: null,
                            }
                          : r
                      )
                    );
                    handleFabricSelect(newValue);
                  }}
                  options={optionsFabricName}
                  label="Select Fabric Name"
                  ref={fabricNameInputValue}
                ></CustomAutoComplete>
              </Grid>
              <Grid item xs={12} lg={2.5} xl={2.5}>
                <CustomAutoComplete
                  value={
                    row.fabricQuality ? row.fabricQuality : fabricQualityValue
                  }
                  onSelect={() => {
                    try {
                      let id = optionsFabricName.filter(
                        (x) => x.label == row.fabricName
                      )[0];
                      handleFabricSelect(id);
                    } catch (event) {}
                  }}
                  onChange={(event, newValue) => {
                    setRows(
                      rows.map((r) =>
                        r.id === row.id
                          ? {
                              ...r,
                              fabricQuality: newValue?.label ?? "",
                              qId: newValue?.value ?? "",
                            }
                          : r
                      )
                    );
                    handleQualityCheck(newValue);
                  }}
                  options={optionsFabricQulaity}
                  label="Select Fabric Quality"
                  ref={fabricQualityInputValue}
                ></CustomAutoComplete>
              </Grid>
              <Grid item xs={12} lg={2} xl={2}>
                <CustomTextField
                  key={row.id}
                  value={row.fabricWidth}
                  onChange={(e) =>
                    setRows(
                      rows.map((r) =>
                        r.id === row.id
                          ? { ...r, fabricWidth: e.target.value }
                          : r
                      )
                    )
                  }
                  type="text"
                  label="Fabric Width"
                />
              </Grid>
              <Grid item xs={12} lg={2} xl={2}>
                <CustomTextField
                  key={row.id}
                  value={row.fabricAverage}
                  onChange={(e) =>
                    setRows(
                      rows.map((r) =>
                        r.id === row.id
                          ? { ...r, fabricAverage: e.target.value }
                          : r
                      )
                    )
                  }
                  type="text"
                  label="Fabric Average"
                />
              </Grid>
              <Grid item xs={12} lg={2} xl={2} key={row.id}>
                <CustomAutoComplete
                  value={row.fabricUse}
                  onChange={(event, newValue) => {
                    setRows(
                      rows.map((r) =>
                        r.id === row.id
                          ? {
                              ...r,
                              fabricUse: newValue,
                            }
                          : r
                      )
                    );
                  }}
                  options={optionsFabricUse}
                  label="Select Fabric Use"
                  ref={selectFabricUseRef}
                ></CustomAutoComplete>
              </Grid>
              <Grid item xs={1} sx={{ display: "flex" }}>
                {index !== 0 && (
                  <IconButton onClick={() => handleDeleteRow(row.id, index)}>
                    <DeleteIcon
                      style={{ color: theme.palette.general.background }}
                    />
                  </IconButton>
                )}
                {index === rows.length - 1 && (
                  <IconButton onClick={() => handleAddRow()}>
                    <AddCircleIcon
                      style={{ color: theme.palette.general.background }}
                    />
                  </IconButton>
                )}
              </Grid>
            </>
          ))}
        </Grid>
        <Grid container spacing={2} style={{ padding: "10px" }}>
          <Grid item xs={12}>
            <CustomButton
              className="SubmitButtonMain"
              variant="contained"
              onClick={handleSubmit}
            >
              Submit
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
