import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const getHeaderToExport = (gridApi) => {
    const columns = gridApi.getAllDisplayedColumns();

    return columns.map((column) => {
        const { field } = column.getColDef();
        const sort = column.getSort();
        const headerName = column.getColDef().headerName ?? field;
        const headerNameUppercase =
            headerName[0].toUpperCase() + headerName.slice(1);
        const headerCell = {
            text: headerNameUppercase + (sort ? ` (${sort})` : ''),
            bold: true,
            margin: [0, 12, 0, 0],
        };
        return headerCell;
    });
};

const getRowsToExportPivot = (gridApi) => {
    const columns = gridApi.getAllDisplayedColumns();

    const getCellToExport = (column, node) => ({
        text: gridApi.getValue(column, node) ?? '',
        ...column.getColDef().cellStyle,
    });

    const rowsToExport = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => {
        if (node.group) {
            const rowToExport = columns.map((column) =>
                getCellToExport(column, node)
            );
            rowsToExport.push(rowToExport);
        }
    });

    return rowsToExport;
};

const getRowsToExport = (gridApi) => {
    if (gridApi.isPivotMode()) {
        return getRowsToExportPivot(gridApi);
    }
    const columns = gridApi.getAllDisplayedColumns();

    const getCellToExport = (column, node) => ({
        text: gridApi.getValue(column, node) ?? '',
        ...column.getColDef().cellStyle,
    });

    const rowsToExport = [];
    gridApi.forEachNodeAfterFilterAndSort((node) => {
        const rowToExport = columns.map((column) => getCellToExport(column, node));
        rowsToExport.push(rowToExport);
    });

    return rowsToExport;
};

const HEADER_ROW_COLOR = '#f8f8f8';
const EVEN_ROW_COLOR = '#fcfcfc';
const ODD_ROW_COLOR = '#fff';

const PDF_INNER_BORDER_COLOR = '#dde2eb';
const PDF_OUTER_BORDER_COLOR = '#babfc7';

const createLayout = (numberOfHeaderRows) => ({
    fillColor: (rowIndex) => {
        if (rowIndex < numberOfHeaderRows) {
            return HEADER_ROW_COLOR;
        }
        return rowIndex % 2 === 0 ? EVEN_ROW_COLOR : ODD_ROW_COLOR;
    },
    vLineWidth: (rowIndex, node) =>
        rowIndex === 0 || rowIndex === node.table.widths.length ? 1 : 0,
    hLineColor: (rowIndex, node) =>
        rowIndex === 0 || rowIndex === node.table.body.length
            ? PDF_OUTER_BORDER_COLOR
            : PDF_INNER_BORDER_COLOR,
    vLineColor: (rowIndex, node) =>
        rowIndex === 0 || rowIndex === node.table.widths.length
            ? PDF_OUTER_BORDER_COLOR
            : PDF_INNER_BORDER_COLOR,
});

const getDocument = (gridApi) => {
    const columns = gridApi.columnModel.columnDefs;

    const headerRow = getHeaderToExport(gridApi);
    const rows = getRowsToExport(gridApi);

    return {
        pageOrientation: 'landscape',
        content: [
            {
                table: {
                    headerRows: 1,
                    widths: `${100 / columns.length}%`,
                    body: [headerRow, ...rows],
                    heights: (rowIndex) => (rowIndex === 0 ? 40 : 15),
                },
                layout: createLayout(1),
            },
        ],
        pageMargins: [10, 10, 10, 10],
    };
};

export const exportToPDF = (gridApi) => {
    const doc = getDocument(gridApi);
    pdfMake.createPdf(doc).download();
};
