import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Grid,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MenuIcon from "@mui/icons-material/Menu";

import CustomAutoComplete from "../../common/dropdowns/CustomAutoComplete";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as ENUMTEXT from "../../../constants/AlertText";
import CustomAlert from "../../common/CustomAlert";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  getAllTable,
  getFloorDataOverlay,
  postTableAllotmentData,
} from "../../../services/FloorPlanService";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { parse, format } from "date-fns";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../common/buttons/CustomButton";
import { set } from "date-fns/fp/set";
import CustomLayerAutoComplete from "../../common/dropdowns/CustomLayerAutoComplete";

// Helper function to format the date

dayjs.extend(customParseFormat);

const OrderText = styled(Typography)`
  margin-bottom: 8px !important;
  font-size: 14px !important;
`;

const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "end",
  marginRight: "15px",
  minHeight: "40px",
  top: "63px",
  position: "absolute",
  right: "-3px",
}));

const OrderDrawer = styled(Box)`
  position: fixed;
  top: 67px;
  right: 0;
  width: 250px;
  height: 90%;
  border-left: 1px solid;
  z-index: 1000; /* Ensure it's on top of other components */
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  display: ${({ open }) => (open ? "block" : "none")};
  transition: "all 0.3s ease";
  //background-color: #364554;
  // overflow-y: auto;
`;
const FloorOverlay = ({
  drawerOpen,
  setDrawerOpen,
  layerOptions,
  TableOptions,
  ContractorOptions,
  RecuttingOptions,
  overlaySelectedData,
  setOverlaySelectedData,
  availableDates,
  setShowTableSch,
  setTableAllotId,
  setLayerAllotId,
  setLayerAllotIdLength,
  MatchedTableData,
  LayerTableData,
  setGetTableValue,
  reCuttingValue,
  editDataOnOverlay, //data come from parent which we want to render on the overlay
  isEditButtonClicked, // check wheather the edit button is clicked or not
  TableAllListData,
  TableAllListAvailableData,
  setTableOneRowData,
  tableNoLyrComOptions,
  cuttingDate,
  selectedData,
  fetchDataFromOeId,
  setLayerOptions,
  updateAllTable,
  clearFieldsOnDeleteId,
  setClearFieldsOnDeleteId,
  tableAllotId,
  resetTableList,
}) => {
  const theme = useTheme();
  const alertRef = useRef();
  const handleOrderModel = () => setDrawerOpen(true);
  const handleClose = () => setDrawerOpen(false);
  const [checked, setChecked] = useState(false);
  const initialDataLoaded = useRef(false);
  const [isPrefilledData, setIsPrefilledData] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState(null);
  const [selectedLayerId, setSelectedLayerId] = useState(null);
  const [selectedContractorId, setSelectedContractorId] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [initiallyChecked, setInitiallyChecked] = useState(false);
  const [tableIdRecutting, setTableIdRecutting] = useState();
  const [autoSelectedTableId, setAutoSelectedTableId] = useState();
  const [autoSelectedContractId, setAutoSelectedContractId] = useState();
  const [autoSelectedQuantity, setAutoSelectedQuantity] = useState();
  const [autoSelectedId, setAutoSelectedId] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [Quantity, setQuantity] = useState();
  const [overlayData, setOverlayData] = useState(undefined);

  const FloorOverlayDropdown = useRef({
    layerValue: null,
    tableValue: null,
    contractValue: null,
    recuttingValue: null,
  });
  const [floorSchedule, setFloorSchedule] = useState(
    FloorOverlayDropdown.current
  );

  //set the data when i select the layer and whose table is assign
  const [LayerOverlayAutoData, setLayerOverlayAutoData] = useState();

  const [recuttingDisabled, setRecuttingDisabled] = useState(false);
  //for edit the table
  const [editRowId, setEditRowId] = useState();

  //set the date

  const [openPopup, setOpenPopup] = useState(false);

  const handlePopUpClose = () => {
    setFloorSchedule((prevState) => ({
      ...prevState,
      recuttingValue: "Yes",
    }));
    setOpenPopup(false);
  };

  const handlePopUpYes = () => {
    setFloorSchedule((prevState) => ({
      ...prevState,
      recuttingValue: "No",
    }));

    setOpenPopup(false);
  };

  function formatDate(dateStr) {
    if (!dateStr) return "";
    const date = parse(dateStr, "dd-MM-yyyy", new Date());
    return format(date, "dd-MMM-yyyy");
  }

  useEffect(() => {
    if (clearFieldsOnDeleteId !== undefined) {
      handleClearAddTableAllotment();
      setClearFieldsOnDeleteId(undefined);
    }
  }, [clearFieldsOnDeleteId]);

  useEffect(() => {
    if (!initialDataLoaded.current && LayerTableData?.layerId) {
      FloorOverlayDropdown.current.tableValue =
        LayerTableData?.tableMaster?.tableNumber;
      FloorOverlayDropdown.current.recuttingValue =
        LayerTableData?.reCuttingApplied === true ? "Yes" : "No";
      FloorOverlayDropdown.current.contractValue =
        LayerTableData?.contractorName;
      setChecked(LayerTableData?.ot);
      setGetTableValue(LayerTableData?.tableMaster?.tableNumber);
      setAutoSelectedTableId(LayerTableData?.tableMaster?.id);
      setAutoSelectedQuantity(LayerTableData?.quantity);
      setAutoSelectedId(LayerTableData?.id);
      setAutoSelectedContractId(LayerTableData?.contractorId);

      if (LayerTableData?.ot === true) {
        setInitiallyChecked(true);
      } else {
        setInitiallyChecked(false);
      }

      setIsPrefilledData(true);
      if (LayerTableData?.allotmentDate) {
        const [day, month, year] = LayerTableData?.allotmentDate.split("-");
        const formattedDate = dayjs(`${month}/${day}/${year}`, "M/D/YYYY");
        setSelectedDate(formattedDate);
      }

      setLayerOverlayAutoData(LayerTableData);
      setFloorSchedule({ ...FloorOverlayDropdown.current });
    } else {
      // setIsPrefilledData(false);
    }

    if (FloorOverlayDropdown.current.tableValue) {
      const selectedTable = TableOptions.find(
        (table) =>
          table.tableNumber === FloorOverlayDropdown?.current?.tableValue
      );
      setTableAllotId(selectedTable?.id);
      setShowTableSch(true);
      setTableIdRecutting(selectedTable?.id);
    }

    if (!FloorOverlayDropdown.current.tableValue) {
      setIsPrefilledData(false);
    }
  }, [LayerTableData]);

  const [selectedLayerDetails, setSelectedLayerDetails] = useState({
    sizes: "",
    plyQty: "",
  });

  // this will call when we click edit button
  //to set the data in the form field

  useEffect(() => {
    FloorOverlayDropdown.current.layerValue = editDataOnOverlay?.layer;
    FloorOverlayDropdown.current.tableValue = editDataOnOverlay?.tableNumber;
    FloorOverlayDropdown.current.recuttingValue =
      editDataOnOverlay?.recuttingApplied;
    FloorOverlayDropdown.current.contractValue =
      editDataOnOverlay?.contractorName;
    setChecked(editDataOnOverlay?.ot);
    selectedLayerDetails.sizes = editDataOnOverlay?.sizeCombination;
    selectedLayerDetails.plyQty = editDataOnOverlay?.plyQty; // come from backend
    setFloorSchedule({ ...FloorOverlayDropdown.current });
    setEditRowId(editDataOnOverlay?.id);
    setOverlayData(editDataOnOverlay);
    setSelectedLayerId(editDataOnOverlay?.layerId);
    console.log("editDaata======", editDataOnOverlay);
    if (
      editDataOnOverlay !== undefined &&
      editDataOnOverlay.startDate !== undefined
    ) {
      setSelectedDate(dayjs(editDataOnOverlay?.startDate, "DD-MM-YYYY"));
    }
    setIsPrefilledData(true);
  }, [editDataOnOverlay, isEditButtonClicked]);

  //when i select any other order Number then i want the rest data feild should be clear
  const formattedAvailableDates = availableDates.map((date) =>
    dayjs(date, "DD-MM-YYYY")
  );

  // when i clicked the other order then this will run
  useEffect(() => {
    if (overlaySelectedData?.orderId) {
      // Clear layerValue and tableValue when orderNumber changes
      setOverlayData(overlaySelectedData);
      FloorOverlayDropdown.current.layerValue = null;
      FloorOverlayDropdown.current.tableValue = null;
      FloorOverlayDropdown.current.recuttingValue = null;
      FloorOverlayDropdown.current.contractValue = null;
      setFloorSchedule({ ...FloorOverlayDropdown.current });
      setSelectedLayerDetails({});
      setShowTableSch(false);
      setChecked(false);
      setSelectedDate(null);
      setIsPrefilledData(false);
      //to show the available dates
    }
  }, [overlaySelectedData?.orderId]);

  useEffect(() => {
    console.log(overlaySelectedData);
    if (
      overlayData?.orderId === overlaySelectedData?.orderId &&
      overlayData?.orderId !== undefined
    ) {
      FloorOverlayDropdown.current.recuttingValue =
        overlaySelectedData.recuttingValue;
      setFloorSchedule({ ...FloorOverlayDropdown.current });
    }
  }, [overlaySelectedData]);
  useEffect(() => {
    if (TableOptions.length === 0) return;
    const newtable = TableOptions.find((x) => x.id === tableAllotId);
    if (
      newtable === undefined ||
      newtable.tableNumber === floorSchedule.tableValue
    )
      return;
    handleTableChange(null, newtable.tableNumber);
  }, [tableAllotId]);

  const layerValueInput = useRef();
  const tableValueInput = useRef();
  const contractValueInput = useRef();
  const recuttingValueInput = useRef();

  const handleLayerChange = (event, newValue) => {
    FloorOverlayDropdown.current.layerValue = newValue;
    setFloorSchedule({ ...FloorOverlayDropdown.current });

    const selectedLayer = layerOptions.find(
      (layer) => layer?.layerName === newValue
    );
    console.log("selectedLayer", selectedLayer);
    setLayerAllotIdLength(selectedLayer?.length);
    setLayerAllotId(selectedLayer?.id);
    setSelectedLayerId(selectedLayer?.id);

    if (selectedLayer) {
      setSelectedLayerDetails({
        sizes: selectedLayer?.sizes,
        plyQty: selectedLayer?.plyQty,
      });
      FloorOverlayDropdown.current.tableValue = null;
      FloorOverlayDropdown.current.contractValue = null;
      if (selectedLayer?.reCuttingApplied === true) {
        FloorOverlayDropdown.current.recuttingValue = "Yes";
      } else {
        FloorOverlayDropdown.current.recuttingValue = "No";
      }
      if (selectedLayer.layerName.slice(-1) === "R") {
        FloorOverlayDropdown.current.recuttingValue = "Yes";
        setRecuttingDisabled(true);
      } else {
        setRecuttingDisabled(false);
      }
      setSelectedDate(null);
      setFloorSchedule({ ...FloorOverlayDropdown.current });
      setIsPrefilledData(false);

      console.log(reCuttingValue);
    }
    setChecked(false);
    if (!selectedLayer) {
      FloorOverlayDropdown.current.tableValue = null;
      FloorOverlayDropdown.current.recuttingValue = null;
      FloorOverlayDropdown.current.contractValue = null;
      setAutoSelectedId("");
      setSelectedDate(null);
      setFloorSchedule({ ...FloorOverlayDropdown.current });
      setSelectedLayerDetails({});
      setIsPrefilledData(false);
      setShowTableSch(false);
      resetTableList();
    }
    setAutoSelectedId();
    setFloorSchedule({ ...FloorOverlayDropdown.current });
  };

  const handleTableChange = (event, newValue) => {
    FloorOverlayDropdown.current.tableValue = newValue;
    setFloorSchedule({ ...FloorOverlayDropdown.current });
    setShowTableSch(true);

    const selectedTable = TableOptions.find(
      (table) => table?.tableNumber === newValue
    );
    console.log("selectedTable:", selectedTable);

    setTableAllotId(selectedTable?.id);
    setSelectedTableId(selectedTable?.id);

    if (selectedTable) {
      // Parse the first available date from the TableAllListAvailableData
      setGetTableValue(selectedTable?.tableNumber); // for assigning the name of the table in table Schedule table which is in bottom sheet
      const tableId = selectedTable?.id;
      const firstAvailableDate = TableAllListAvailableData[tableId]?.[0];
      if (firstAvailableDate) {
        // Convert the date format if necessary, e.g., from 'DD-MM-YYYY' to 'YYYY-MM-DD'
        const formattedDate = dayjs(firstAvailableDate, "DD-MM-YYYY");
        setSelectedDate(formattedDate);
        console.log("Selected date:", formattedDate);
      } else {
        //Available date from the getOrderList
        // if (formattedAvailableDates?.length > 0) {
        //   setSelectedDate(dayjs(formattedAvailableDates[0], "DD-MM-YYYY"));
        // }
      }
    }

    if (!selectedTable) {
      setSelectedDate(null);
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.TABLE_ALLOTMENT_TABLE_FIELD,
        ENUMTEXT.WARNING.WARNING_SERV
      );
    }
  };

  // const shouldDisableDate = (date) => {
  //   if (!cuttingStartDate || !cuttingEndDate) {
  //     return false; // If dates are not set yet, don't disable any dates
  //   }
  //   return (
  //     date.isBefore(cuttingStartDate, "day") ||
  //     date.isAfter(cuttingEndDate, "day")
  //   );
  // };

  // const shouldDisableDate = (date) => {
  //

  // const shouldDisableDate = (date) => {
  //   // Ensure the tableId is defined
  //   const tableId = selectedTableId; // Use the state or prop that holds the selected table ID
  //   if (!tableId || !TableAllListAvailableData[tableId]) {
  //     return true; // Disable all dates if no table is selected or no data is available
  //   }

  //   return !TableAllListAvailableData[tableId].some(availableDate =>
  //     dayjs(availableDate, 'DD-MM-YYYY').isSame(date, 'day')
  //   );
  // };
  const shouldDisableDate = (date) => {
    const tableId = selectedTableId; // Use the state or prop that holds the selected table ID

    if (tableId && TableAllListAvailableData[tableId]) {
      return !TableAllListAvailableData[tableId].some((availableDate) =>
        dayjs(availableDate, "DD-MM-YYYY").isSame(date, "day")
      );
    }

    // Check formattedAvailableDates if no dates available for the selected table
    return !formattedAvailableDates.some((availableDate) =>
      dayjs(availableDate, "DD-MM-YYYY").isSame(date, "day")
    );
  };
  const handleContractChange = (event, newValue) => {
    FloorOverlayDropdown.current.contractValue = newValue;

    const selectedContractor = ContractorOptions.find(
      (contractor) => contractor.name === newValue
    );

    setSelectedContractorId(selectedContractor?.id);
    setFloorSchedule({ ...FloorOverlayDropdown.current });
    if (!newValue) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.TABLE_ALLOTMENT_CONTRACTOR_FIELD,
        ENUMTEXT.WARNING.WARNING_SERV
      );
    }
  };

  const handleRecuttingChange = (event, newValue) => {
    if (!recuttingDisabled) {
      FloorOverlayDropdown.current.recuttingValue = newValue;
      // FloorOverlayDropdown.current.recuttingValue = newValue;
      console.log(
        "recuttingChange",
        FloorOverlayDropdown.current.recuttingValue
      );

      const hasReCuttingApplied = overlayData.reCuttingApplied;
      console.log("hasRecutting", hasReCuttingApplied);

      if (
        hasReCuttingApplied === true &&
        FloorOverlayDropdown.current.recuttingValue === "No"
      ) {
        console.log(
          "There is at least one layer with reCuttingApplied set to true."
        );
        setOpenPopup(true);
      } else {
        console.log("No layer has reCuttingApplied set to true.");
      }
      // TableAllListData

      setFloorSchedule({ ...FloorOverlayDropdown.current });
      if (!newValue) {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.TABLE_ALLOTMENT_RECUTTING_FIELD,
          ENUMTEXT.WARNING.WARNING_SERV
        );
      }
    }
  };

  const handleCheckboxChange = (event) => {
    // const newChecked = event.target.checked;

    // // Check if the checkbox was initially checked and the user tries to uncheck it
    // if (initiallyChecked) {
    //   console.log("here");
    //   alertRef.current.showAlert(
    //     ENUMTEXT.WARNING.TABLE_ALLOTMENT_CHECKBOX_FAILED,
    //     ENUMTEXT.WARNING.WARNING_SERV
    //   );
    //   return;
    // }

    setChecked(event.target.checked);
  };

  const handleClearTableAllotment = () => {
    setSelectedLayerDetails({});
    setLayerAllotId(undefined);
    FloorOverlayDropdown.current.layerValue = null;
    FloorOverlayDropdown.current.tableValue = null;
    FloorOverlayDropdown.current.recuttingValue = null;
    FloorOverlayDropdown.current.contractValue = null;
    setChecked(false);
    setSelectedDate(null);
    setFloorSchedule({ ...FloorOverlayDropdown.current });
    setIsPrefilledData(false);
    // setOverlaySelectedData({});
    setShowTableSch(false);
    resetTableList();
    setAutoSelectedId(undefined);
    setEditRowId(undefined);
    // setShowTableSch(false);

    // setFloorSchedule({ ...FloorOverlayDropdown.current });
    console.log(
      "Layer autocomplete",
      FloorOverlayDropdown.current.layerValue,
      layerValueInput.current
    );
  };

  const handleClearAddTableAllotment = () => {
    setSelectedLayerDetails({});
    setLayerAllotId(undefined);
    FloorOverlayDropdown.current.layerValue = null;
    FloorOverlayDropdown.current.tableValue = null;
    FloorOverlayDropdown.current.recuttingValue = null;
    FloorOverlayDropdown.current.contractValue = null;
    setChecked(false);
    setSelectedDate(null);
    setFloorSchedule({ ...FloorOverlayDropdown.current });
    setIsPrefilledData(false);
    // setShowTableSch(false);

    // setFloorSchedule({ ...FloorOverlayDropdown.current });
    console.log(
      "Layer autocomplete",
      FloorOverlayDropdown.current.layerValue,
      layerValueInput.current
    );
  };

  const handleAddTableAllot = async () => {
    if (
      !overlayData?.orderId ||
      !overlayData?.style ||
      !floorSchedule?.layerValue ||
      !floorSchedule?.tableValue ||
      !floorSchedule?.contractValue ||
      !selectedDate ||
      !floorSchedule.recuttingValue
    ) {
      // Show an alert message if any of the required fields are missing
      // alert("Please fill in all the required fields.");
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.REQUIRED_FIELD,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }

    let totalQuantity = 0;
    const getTotalSizeCount = (sizes) => {
      const sizeArray = sizes.split(/[\s,-]+/);
      return sizeArray.length;
    };
    if (selectedLayerDetails.sizes) {
      const totalSizeCount = getTotalSizeCount(selectedLayerDetails.sizes);
      console.log("Total Size Count:", totalSizeCount); // Total number of sizes
      console.log("quantityy", selectedLayerDetails.plyQty * totalSizeCount);
      totalQuantity = selectedLayerDetails.plyQty * totalSizeCount;
    }

    const formattedDate = dayjs(selectedDate).format("DD-MMM-YYYY");
    console.log("overlayData======>>>>>>>", overlayData);
    console.log("");
    const tableAllotmentData = {
      id: autoSelectedId || editRowId,
      orderId: overlayData?.orderId,
      oeId: overlayData?.oeId,
      orderRefId: overlayData?.orderCreationId || overlayData.orderRefId,
      floorPlanId:
        overlayData?.floorPlanId === null ||
        overlayData?.floorPlanId === undefined
          ? overlayData?.id
          : overlayData?.floorPlanId,
      // allotmentDate: LayerOverlayAutoData?.allotmentDate||formattedDate,
      allotmentDate: formattedDate,
      sizeCombination: selectedLayerDetails?.sizes,
      // sizeCombination:selectedLayerDetails?.sizes ,
      layerId: selectedLayerId,
      tableId: selectedTableId === null ? autoSelectedTableId : selectedTableId,
      layerName: floorSchedule?.layerValue,
      contractorId: autoSelectedContractId || selectedContractorId,
      contractorName: floorSchedule?.contractValue,
      quantity: totalQuantity,
      reCuttingApplied: floorSchedule.recuttingValue === "No" ? false : true,
      ot: checked,
    };
    console.log("tableAllotmentData", tableAllotmentData);

    try {
      const response = await postTableAllotmentData(tableAllotmentData);
      console.log("API Response", response);

      if (response.data.code === 200) {
        alertRef.current.showAlert(
          response.data.message,
          ENUMTEXT.ERROR.WARNING_SERV
        );
        updateAllTable(tableAllotmentData.floorPlanId);
        const tableData = await getAllTable(tableAllotmentData.floorPlanId);
        console.log(
          "post data response",
          tableData.data.response.tableWithTablePlanMap
        );
        const tableSetRowDataId = tableAllotmentData.tableId;
        console.log("tableIdPost", tableSetRowDataId);

        const tableRows =
          tableData.data.response.tableWithTablePlanMap[tableSetRowDataId];

        if (tableRows && tableRows.length > 0) {
          const transformedTableOneData = tableRows.map((item) => ({
            startDate: item?.allotmentDate,
            orderNo: item?.orderId,
            layer: item?.layerName,
            sizeCombination:
              item?.sizeCombination || selectedLayerDetails?.sizes,
            quantity: item?.quantity,
            recuttingApplied: item?.reCuttingApplied === true ? "Yes" : "No",
            tableNumber: item?.tableMaster.tableNumber,
            tableId: item?.tableMaster.id,
            ot: item?.ot,
            contractorId: item?.contractorId,
            contractorName: item?.contractorName,
            style: item?.style,
            plyQty: item?.plyQty, // come from Backend
            id: item?.id,
            floorPlanId: item?.floorPlanId,
            oeId: item?.oeId,
            orderId: item?.orderId,
            orderRefId: item?.orderRefId,
            allotmentDate: item?.allotmentDate,
            layerId: item?.layerId,
            layerName: item?.layerName,

            // tableNumber: item.tableMaster.tableNumber,
            // tableLength: item.tableMaster.length,
            // availableDate: item.allotmentDate,
            // noOfLayers: item.layerName,
            // totalQty:item.quantity,
          }));

          console.log("tableRowsData", tableRows);
          setTableOneRowData(transformedTableOneData);
          handleClearAddTableAllotment();
        }

        // const tableOneData=
        // setDataOnTableOne()
      } else {
        alertRef.current.showAlert(
          response.data.message,
          ENUMTEXT.ERROR.ERROR_SERV
        );
      }

      const newLayerOptions = await getFloorDataOverlay(
        tableAllotmentData?.oeId,
        tableAllotmentData?.floorPlanId
      );
      const layers = newLayerOptions?.data?.response?.layerDTOS || [];
      const layerNames = layers.map((layer) => ({
        id: layer.id,
        layerName: layer.layerName,
        sizes: layer.sizes,
        plyQty: layer.plyQty,
        length: layer.length,
        reCuttingApplied: layer.reCuttingApplied,
      }));
      setLayerOptions(layerNames);

      // Update selected layer details
      // const selectedLayer = layerNames.find(
      //   (layer) => layer?.layerName === floorSchedule.layerValue
      // );
      // if (selectedLayer) {
      //   setSelectedLayerDetails({
      //     sizes: selectedLayer?.sizes,
      //     plyQty: selectedLayer?.plyQty,
      //   });
      // }
    } catch (error) {
      console.error("Error posting table allotment data:", error);
      alertRef.current.showAlert(
        ENUMTEXT.ERROR.TABLE_ALLOTMENT_POST_ERROR,
        ENUMTEXT.WARNING.WARNING_SERV
      );
    }
  };
  useEffect(() => {
    if (floorSchedule.layerValue) {
      const selectedLayer = layerOptions.find(
        (layer) => layer?.layerName === floorSchedule.layerValue
      );
      if (LayerTableData?.layerId === null) {
        FloorOverlayDropdown.current.tableValue = null;
        FloorOverlayDropdown.current.recuttingValue = null;
        FloorOverlayDropdown.current.contractValue = null;
        setChecked(false);
        setFloorSchedule({ ...FloorOverlayDropdown.current });
        setSelectedDate(null);

        if (FloorOverlayDropdown.current.tableValue) {
          const selectedTable = TableOptions.find(
            (table) =>
              table.tableNumber === FloorOverlayDropdown?.current?.tableValue
          );
          setTableAllotId(selectedTable?.id);
          setShowTableSch(true);
          setTableIdRecutting(selectedTable?.id);
        }

        if (selectedLayer) {
          setSelectedLayerDetails({
            sizes: selectedLayer?.sizes,
            plyQty: selectedLayer?.plyQty,
          });
          // setFloorSchedule({ ...FloorOverlayDropdown.current });
        }
      }
    }
  }, [layerOptions]);

  return (
    <>
      <CustomAlert ref={alertRef} />

      <Dialog
        open={openPopup}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
        fullWidth={true}
        PaperProps={{
          style: {
            maxHeight: "80vh",
            borderRadius: "10px",
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="20px"
        >
          <ReportProblemOutlinedIcon
            fontSize="large"
            style={{ fontSize: "90px" }}
          />
          <DialogTitle
            id="responsive-dialog-title"
            align="center"
            style={{ fontSize: "25px", fontWeight: "bold", padding: "0" }}
          >
            {"Re-Cutting Apply"}
          </DialogTitle>
        </Box>
        <DialogContent
          style={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "20px",
            paddingTop: "0px",
          }}
        >
          <DialogContentText style={{ fontSize: "20px", textAlign: "center" }}>
            Are you sure you want to mark re-cutting as NO? All the re-cutting
            layers will be deleted, and plans related to those layers will also
            be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "20px",
          }}
        >
          <Box style={{ margin: "auto" }}>
            <CustomButton variant="contained" onClick={handlePopUpYes}>
              Confirm
            </CustomButton>
          </Box>
          <Box style={{ margin: "auto" }}>
            <CustomButton variant="contained" onClick={handlePopUpClose}>
              Cancel
            </CustomButton>
          </Box>
        </DialogActions>
      </Dialog>

      <OrderDrawer open={drawerOpen} onClose={handleClose}>
        <Box borderRadius={2} style={{ height: "100%", position: "relative" }}>
          <IconButton
            sx={{
              padding: "3px !important",
              zIndex: 100000000,
              position: "absolute",
              top: "10px",
              left: "-12px",
              border: "1px solid white",
              background: theme.palette.button.background,
              color: theme.palette.button.text,
              "&:hover": {
                background: "#efefef",
              },
            }}
            onClick={handleClose}
          >
            <ArrowForwardIosIcon sx={{ fontSize: "1.2rem !important" }} />
          </IconButton>
          <Box
            padding={2}
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              overflowX: "none",
              height: "calc(100% - 40px)",
              backgroundColor: "#364554",
            }}
          >
            <Typography align="center" fontSize="20px" paddingBottom="10px">
              Table Allotment
            </Typography>
            <OrderText>Order Number : {overlayData?.orderId}</OrderText>
            <OrderText>Style Details : {overlayData?.style}</OrderText>
            <Box sx={{ paddingTop: "10px" }}>
              <CustomLayerAutoComplete
                value={floorSchedule.layerValue}
                onChange={handleLayerChange}
                // options={layerOptions}
                tableData={TableAllListData}
                itemBackgroundColor={"red"}
                options={layerOptions.map((option) => option.layerName)}
                label="Select Layers"
                ref={layerValueInput}
                orderId={overlayData?.orderId}
              />
            </Box>
            <OrderText sx={{ paddingTop: "10px" }}>
              Size Combination:{selectedLayerDetails.sizes}
            </OrderText>
            <OrderText sx={{ paddingBottom: "10px" }}>
              Ply Qty: {selectedLayerDetails.plyQty}
            </OrderText>
            <Box>
              <CustomAutoComplete
                value={floorSchedule.tableValue}
                onChange={handleTableChange}
                options={TableOptions.map((option) => option.tableNumber)}
                label="Select Table"
                ref={tableValueInput}
              />
            </Box>

            <Box sx={{ paddingTop: "15px" }}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="en-gb"
              >
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    label={"Select Start Date"}
                    value={selectedDate}
                    onChange={(newValue) => setSelectedDate(newValue)}
                    shouldDisableDate={shouldDisableDate}
                    defaultCalendarMonth={selectedDate || dayjs()} // Default to selectedDate if available
                    openTo="day" // Open to the day view
                    views={["year", "month", "day"]} // A
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Box>

            <Box sx={{ paddingTop: "15px" }}>
              <CustomAutoComplete
                value={floorSchedule.contractValue}
                onChange={handleContractChange}
                options={ContractorOptions.map((option) => option.name)}
                label="Select Contractor Name "
                ref={contractValueInput}
              />
            </Box>
            <Box sx={{ paddingTop: "15px" }}>
              <CustomAutoComplete
                value={floorSchedule.recuttingValue}
                onChange={handleRecuttingChange}
                options={RecuttingOptions}
                label="Re-cutting Applied"
                ref={recuttingValueInput}
                disabled={recuttingDisabled}
              />
            </Box>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={handleCheckboxChange}
                    inputProps={{ "aria-label": "controlled" }}
                    disabled={disabled}
                  />
                }
                label="Mark as OT"
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CustomButton
                sx={{ backgroundColor: "white", color: "grey", width: "48%" }}
                onClick={handleAddTableAllot}
              >
                {!isPrefilledData ? "Add" : "Save"}
              </CustomButton>

              <CustomButton
                sx={{ backgroundColor: "white", color: "grey", width: "48%" }}
                onClick={handleClearTableAllotment}
              >
                Clear
              </CustomButton>
            </Box>
          </Box>
        </Box>
      </OrderDrawer>
    </>
  );
};
export default FloorOverlay;
