import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { Autocomplete, Grid, Box, TextField } from "@mui/material";
import ResponsiveDialog from "../components/common/NoStyleModel";
import { fetchOrders } from "../services/OrderListServices";
import sortOrderIds from "../components/common/SortOrders";
import CustomTable from "../components/common/tables/CustomTable";
import CustomAlert from "../components/common/CustomAlert";
import OrderDetailModel from "../components/specific/lotplan/OrderDetailsModel";
import CustomAutoComplete from "../components/common/dropdowns/CustomAutoComplete";
import CustomButton from "../components/common/buttons/CustomButton";
import {
  findLayerPlan,
  generateLayerPlan,
} from "../services/LayerPlanServices";
import * as ENUMTEXT from "../constants/AlertText";

export default function LayerPlan() {
  const newColDefs = [
    {
      field: "lotNumber",
    },
    {
      headerName: "Fabric Meter",
      field: "fabricMeter",
      aggFunc: "sum",
    },
    { field: "fabricWidth" },
    { field: "defect" },
    {
      field: "FabricAverage",
      children: [
        { field: "merchant" },
        {
          field: "production",
        },
        { field: "planned" },
      ],
    },
  ];
  const [colDefs, setColDefs] = useState(newColDefs);
  const [rowData, setRowData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const alertRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderOptions, setOrderOptions] = useState([]);
  const [bottomRowData, setBottomRowData] = useState([]);
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
  });
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const [orderDetailData, setOrderDetailData] = useState({});
  const [generateBtn, setGenerateBtn] = useState(false);

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    setOrderInfo({ ...orderInfoRef.current });
    setGenerateBtn(false);
    setOrderDetailData({});
    setDrawerOpen(false);
    setRowData([]);
    setBottomRowData([]);
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === child.headerName) {
              child.headerName = "0";
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "lotTotal") {
                grandchild.headerName = "0";
              }
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    setOrderInfo({ ...orderInfoRef.current });
    setOrderDetailData({
      orderNumber: orderInfoRef.current.OrderNumberValue,
      buyerName: orderInfoRef.current.buyerName,
      merchantName: orderInfoRef.current.merchantName,
      orderImage: orderInfoRef.current.orderImage || "",
    });
    setRowData([]);
    setBottomRowData([]);
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === child.headerName) {
              child.headerName = "0";
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "lotTotal") {
                grandchild.headerName = "0";
              }
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  useEffect(() => {
    if (
      orderInfo.Stylevalue !== undefined &&
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    ) {
      if (orderInfo.Stylevalue && orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          if (orderDetail) {
            if (!orderDetail.fabAvl) {
              setDialogOpen(true);
            } else {
              orderInfoRef.current.fabricName = orderDetail.fabricName;
              orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
              setOrderInfo({ ...orderInfoRef.current });

              setOrderDetailData((prevData) => ({
                ...prevData,
                style: orderDetail.style,
                color: orderDetail.color,
                fabricName: orderDetail.fabricName,
                fabricQuality: orderDetail.fabricQuality,
                fabricWidth: orderDetail.fabricWidth,
                availableFabric: orderDetail.avlFabric,
                consumedFabric: orderDetail.consumedFabric,
                remainingFabric: orderDetail.remainingFabric,
                maxNoOfPly: orderDetail.maxNoOfPly,
                fabricInhouse: orderDetail.fabInHouse,
                ppApproval: orderDetail.ppApproval,
                cuttingStart: orderDetail.cuttingStartDate,
                cuttingEnd: orderDetail.cuttingEndDate,
                stitchingStart: orderDetail.stitchingStartDate,
                stitchingEnd: orderDetail.stitchingEndDate,
                deliveryDate: orderDetail.deliveryDate,
                sizeWithQty: orderDetail.sizeWithQty,
                sumOfDemandedQty: orderDetail.sumOfDemandedQty,
                sumOfAllowedQty: orderDetail.sumOfAllowedQty,
                sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
                fabricWidthDetails: orderDetail.fabricWidthDetails,
                addOnQuantity: orderDetail.addOnQuantity,
                totalNoOfParts: orderDetail.totalNoOfParts,
                fusingParts: orderDetail.fusingParts,
                handCuttingParts: orderDetail.handCuttingParts,
                reCuttingParts: orderDetail.reCuttingParts,
                pinning: orderDetail.pinning,
                noOfLots: orderDetail.noOfLots,
                merchantAvg: orderDetail.merchantAvg,
                plannedAvg: orderDetail.plannedAvg,
              }));
            }
          }
        }
      } else {
      }
    }
  }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const orderAllData = await fetchOrders();
        if (orderAllData.status === 200) {
          setOrderData(orderAllData.data.response);
          const orderIds = Object.keys(orderAllData.data.response.orders);
          const sortedOrderIds = sortOrderIds(orderIds);
          setOrderOptions(sortedOrderIds);
        } else {
          alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadOrders();
  }, []);

  useEffect(() => {
    if (orderInfo.OrderNumberValue) {
      const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
      if (selectedOrder) {
        orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
        orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
        orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
          label: `${order.style ?? ""} ${order.color ?? ""} ${
            order.size ?? ""
          } ${order.remarks ?? ""}`,
          oeid: order.oeId ?? "",
          sizeWithQty: order.sizeWithQty,
        }));
        if (orderInfoRef.current.optionsStyle.length === 1) {
          orderInfoRef.current.Stylevalue =
            orderInfoRef.current.optionsStyle[0];
        }
        setOrderInfo({ ...orderInfoRef.current });
        setOrderDetailData({
          orderNumber: orderInfoRef.current.OrderNumberValue,
          buyerName: orderInfoRef.current.buyerName,
          merchantName: orderInfoRef.current.merchantName,
          orderImage: selectedOrder[0]?.imagePath || "",
        });
        setDrawerOpen(true);
      }
    } else {
      orderInfoRef.current.buyerName = "";
      orderInfoRef.current.merchantName = "";
      orderInfoRef.current.optionsStyle = [];
      setOrderInfo({ ...orderInfoRef.current });
    }
  }, [orderInfo.OrderNumberValue, orderData]);

  // const processLayerWithQty = (layerResponse) => {
  //   const children = layerResponse.layers.map((layer) => {
  //     const subLayerChildren = layer.subLayers.map((subLayer) => ({
  //       columnGroupShow: "open",
  //       field: subLayer.layerName
  //     }));

  //     return {
  //       field: layer.layerName,
  //       children: [
  //         { columnGroupShow: "closed", field: layer.layerName },
  //         { columnGroupShow: "open", field: layer.layerName },
  //         ...subLayerChildren
  //       ]
  //     };
  //   });

  //   return {
  //     field: "layerPlan",
  //     children: children
  //   };
  // };

  const processLayerWithQty = (layerResponse) => {
    const children = layerResponse.layers.map((layer) => {
      const subLayerChildren = (layer.subLayers || []).map((subLayer) => ({
        columnGroupShow: "open",
        field: subLayer.layerName,
      }));

      const baseChildren = [];

      if (subLayerChildren.length > 0) {
        baseChildren.push({
          columnGroupShow: "closed",
          field: layer.layerName,
        });
        baseChildren.push({ columnGroupShow: "open", field: layer.layerName });
        baseChildren.push(...subLayerChildren);
      } else {
        baseChildren.push({ field: layer.layerName });
      }

      return {
        field: layer.layerName,
        children: baseChildren,
      };
    });

    return {
      field: "layerPlan",
      children: children,
    };
  };

  const [dialogOpen, setDialogOpen] = useState(false);

  // const handleGenerateLayerPlan = async () => {
  //   const oeId = orderInfo.Stylevalue?.oeid;
  //   const generateLayerResponse = await generateLayerPlan(oeId);
  //   if (generateLayerResponse.status === 200) {
  //     if (generateLayerResponse.data) {
  //       const findLayerResponse = await findLayerPlan(oeId);
  //       if (findLayerResponse.status === 200) {
  //         const newCol = processLayerWithQty(findLayerResponse.data.lots[0]);
  //         const updateColumnDefs = (prevCols) => {
  //           return [
  //             ...prevCols,
  //             newCol
  //           ];
  //         };

  //         setColDefs((prevCols) => {
  //           return updateColumnDefs(prevCols);
  //         });
  //         const TransformData = (response) => {
  //           const extractedFields = newCol.children
  //           console.log("extractedfields", extractedFields)
  //           return response.lots.map((lot) => {
  //             const layerData = {
  //               lotNumber: lot.lotNumber,
  //               fabricMeter: lot.fabricLength,
  //               fabricWidth: lot.fabricWidth,
  //               defect: lot.defect,
  //               merchant: lot.merchantAvg,
  //               production: lot.productionAvg,
  //               planned: lot.plannedAvg,
  //             };
  //             extractedFields.map((layer, index) => {
  //               layer.children.map((subLayer, Sindex) => {
  //                 if (Sindex <= 1) {
  //                   layerData[subLayer.field] = lot.layers[index].val
  //                 } else {
  //                   layerData[subLayer.field] = lot.layers[index].subLayers[Sindex - 2].val
  //                 }

  //               })
  //             })
  //             return layerData;
  //           });
  //         };
  //         setRowData(TransformData(findLayerResponse.data));
  //       } else {
  //         alertRef.current.showAlert(findLayerResponse, ENUMTEXT.ERROR.ERROR_SERV);
  //       }
  //     }
  //   } else {
  //     alertRef.current.showAlert(generateLayerResponse, ENUMTEXT.ERROR.ERROR_SERV);
  //   }
  // };

  const handleGenerateLayerPlan = async () => {
    setGenerateBtn(true);
    const oeId = orderInfo.Stylevalue?.oeid;
    if (oeId) {
      const generateLayerResponse = await generateLayerPlan(oeId);
      if (generateLayerResponse.status === 200) {
        if (generateLayerResponse.data) {
          const findLayerResponse = await findLayerPlan(oeId);
          if (findLayerResponse.status === 200) {
            console.log("findLayerResponse", findLayerResponse);
            const newCol = processLayerWithQty(findLayerResponse.data.lots[0]);
            const updateColumnDefs = (prevCols) => {
              return [...prevCols, newCol];
            };

            setColDefs((prevCols) => {
              return updateColumnDefs(prevCols);
            });

            let addcol = {
              field: "Total (based on lots)",
            };
            setColDefs((prevCols) => [...prevCols, addcol]);
            const TransformData = (response) => {
              const extractedFields = newCol.children;
              console.log("extractedfields", extractedFields);

              const mappedData = response.lots.map((lot) => {
                const layerData = {
                  lotNumber: lot.lotNumber,
                  fabricMeter: lot.fabricLength,
                  fabricWidth: lot.fabricWidth,
                  defect: lot.defect,
                  merchant: lot.merchantAvg,
                  production: lot.productionAvg,
                  planned: lot.plannedAvg,
                };

                extractedFields.map((layer, index) => {
                  layer.children.map((subLayer, Sindex) => {
                    if (Sindex <= 1) {
                      layerData[subLayer.field] = lot.layers[index].val;
                    } else {
                      layerData[subLayer.field] =
                        lot.layers[index].subLayers[Sindex - 2].val;
                    }
                  });
                });
                return layerData;
              });

              const totalRow = {
                lotNumber: "Total",
                fabricMeter: findLayerResponse.data.totalFabricMeter,
              };
              const row = {
                lotNumber: "Total(based on sizes in layer)",
              };
              extractedFields.map((layer) => {
                layer.children.map((subLayer) => {
                  if (layer.field in response.layerWithTotalVal) {
                    totalRow[layer.field] =
                      response.layerWithTotalVal[layer.field];
                  }
                  if (subLayer.field in response.subLayerWithTotalVal) {
                    totalRow[subLayer.field] =
                      response.subLayerWithTotalVal[subLayer.field];
                  }
                });
              });
              const arr = [];
              arr.push(totalRow);
              arr.push(row);
              setBottomRowData(arr);

              // mappedData.push(totalRow);

              return mappedData;
            };

            setRowData(TransformData(findLayerResponse.data));
            console.log("sl", TransformData(findLayerResponse.data));
          } else {
            alertRef.current.showAlert(
              findLayerResponse,
              ENUMTEXT.ERROR.ERROR_SERV
            );
          }
        }
      } else {
        alertRef.current.showAlert(
          generateLayerResponse,
          ENUMTEXT.ERROR.ERROR_SERV
        );
        setRowData([]);
      }
    } else {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.UNSELECT_ORDER_STYLE,
        ENUMTEXT.ERROR.ERROR_SERV
      );
    }
  };

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ResponsiveDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              orderDetails={orderInfo}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CustomButton
              onClick={handleGenerateLayerPlan}
              disabled={generateBtn}
            >
              Generate Layer Plan
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <CustomTable
          rowData={rowData}
          colDefs={colDefs}
          pinnedBottomRowData={bottomRowData}
        />
      </Box>
    </>
  );
}
