import { TextField } from "@mui/material";
import React from "react";

const CustomTextField = ({ value, onChange, type, label, ...props }) => {
  return (
    <TextField
      fullWidth
      className="FabricWidthInput"
      type={type}
      InputLabelProps={{ shrink: true }}
      label={label}
      inputProps={{ maxLength: 12 }}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default CustomTextField;
