import api from "./Api";

async function getOrderDetails(id,oeId) {
    try {
        const url = `/table-plan/getOrderDetails?id=${id}&oeId=${oeId}`;
        const response = await api.get(url);
        return response;
    } catch (error) {
        console.error("Failed to fetch order:", error);
        return error;
    }
}

export { getOrderDetails };