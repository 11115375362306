import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import CustomButton from "../../common/buttons/CustomButton";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

function DeleteTableLayer({ open, handleConfirm, handleClose }) {
  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      maxWidth="xs"
      fullWidth={true}
      PaperProps={{
        style: {
          maxHeight: "80vh",
          borderRadius: "10px",
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding="20px"
      >
        <ReportProblemOutlinedIcon
          fontSize="large"
          style={{ fontSize: "90px" }}
        />
        <DialogTitle
          id="responsive-dialog-title"
          align="center"
          style={{ fontSize: "25px", fontWeight: "bold", padding: "0" }}
        >
          {"Table Schedule"}
        </DialogTitle>
      </Box>
      <DialogContent
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "20px",
          paddingTop: "0px",
        }}
      >
        <DialogContentText style={{ fontSize: "20px", textAlign: "center" }}>
          Are you sure you want to delete the table schedule for this layer?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: "flex",
          justifyContent: "space-between",
          paddingLeft: "24px",
          paddingRight: "24px",
          paddingBottom: "20px",
        }}
      >
        <Box style={{ margin: "auto" }}>
          <CustomButton variant="contained" onClick={handleConfirm}>
            Confirm
          </CustomButton>
        </Box>
        <Box style={{ margin: "auto" }}>
          <CustomButton variant="contained" onClick={handleClose}>
            Cancel
          </CustomButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteTableLayer;
