import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { useNavigate } from "react-router-dom";
import CustomButton from "./buttons/CustomButton";

export default function ResponsiveDialog({ open, onClose, orderDetails }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const navigate = useNavigate();

  const handleAddFabricNavigate = () => {
    navigate("/lot-plan/receive-fabric", { state: { orderInformation: orderDetails } });
  };

  const handleClose = () => {
    onClose();
    // window.location.reload();
  };

  return (
    <React.Fragment>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="xs"
        fullWidth={true}
        PaperProps={{
          style: {
            maxHeight: '80vh',
            borderRadius: "10px"
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="20px"
        >
          <ReportProblemOutlinedIcon
            fontSize="large"
            style={{ fontSize: "90px" }}
          />
          <DialogTitle
            id="responsive-dialog-title"
            align="center"
            style={{ fontSize: "25px", fontWeight: "bold", padding: "0" }}
          >
            {"No Fabric Found"}
          </DialogTitle>
        </Box>
        <DialogContent
          style={{
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "20px",
            paddingTop: "0px"
          }}
        >
          <DialogContentText
            style={{ fontSize: "20px", textAlign: "center" }}
          >
            Please add at least one fabric to create the lot plan for this order
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "24px",
            paddingRight: "24px",
            paddingBottom: "20px"
          }}
        >
          <Box style={{ margin: "auto" }}>
            <CustomButton
              variant="contained"
              onClick={handleAddFabricNavigate}
            >
              Add Fabric
            </CustomButton>
          </Box>
          <Box style={{ margin: "auto" }}>
            <CustomButton
              variant="contained"
              onClick={handleClose}
            >
              Cancel
            </CustomButton>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
