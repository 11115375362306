import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Box, FormControl, Grid, IconButton, TextField } from "@mui/material";
import { fetchOrders } from "../../services/OrderListServices";
import {
  addDraftApi,
  getAllDrafts,
  getSingleDraft,
} from "../../services/CuttingPlan";
import { GlobalContext } from "../../providers/GlobalProvider";
import { useLocation, useNavigate } from "react-router-dom";
import sortOrderIds from "../../components/common/SortOrders";
import CustomButton from "../../components/common/buttons/CustomButton";
import CustomAutoComplete from "../../components/common/dropdowns/CustomAutoComplete";
import CustomAlert from "../../components/common/CustomAlert";
import CustomTable from "../../components/common/tables/CustomTable";
import OrderDetailModel from "../../components/specific/lotplan/OrderDetailsModel";
import EditIcon from "@mui/icons-material/Edit";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import DeleteIcon from "@mui/icons-material/Delete";
import * as ENUMTEXT from "../../constants/AlertText";
import eventBus from "../../services/PubSub";
import AddLayerConfirmation from "../../components/specific/draft/AddLayerConfirmation ";
import MerchantConfirmation from "../../components/specific/draft/MerchantConfirmation";

const dummyLayor = {
  id: 0,
  qty: 0,
  markerLength: 0,
  marginLength: 0,
  sizeCombo: "",
  width: 0,
  efficiency: 0,
  validCombo: true,
  isEditLayer: false,
};

const initialLayor = {
  id: 0,
  qty: 0,
  markerLength: 0,
  marginLength: 0,
  sizeCombo: "",
  width: 0,
  efficiency: 0,
  validCombo: true,
  isEditLayer: false,
};

function checkNull(data) {
  for (let item of data) {
    for (let key in item) {
      if (key !== "id")
        if (item[key] === null || item[key] === undefined) {
          return false;
        } else if (
          [
            "qty",
            "markerLength",
            "marginLength",
            "width",
            "efficiency",
          ].includes(key) &&
          Number(item[key]) === 0
        ) {
          return false;
        }
    }
  }
  return true;
}

const AddDrafts = () => {
  const location = useLocation();
  const alertRef = useRef();
  const { orderInformation, draftId, layerId } = location.state;
  const navigate = useNavigate();
  const { globalValue } = useContext(GlobalContext);
  const [orderData, setOrderData] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
    avlFabric: 0,
  });
  const issuedFabric = 8000;

  const [addLayerConfirmationPopUp, setAddLayerConfirmationPopUp] =
    useState(false);
  const [merchantConfirmationPopup, setMerchantConfirmationPopup] =
    useState(false);
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const sizeWithQuantity = useRef({});
  const totalRequired = useRef({});
  const allowQuantity = useRef({});
  const [layers, setLayers] = useState([]);
  const [layerData, setLayerData] = useState(dummyLayor);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [overQuantity, setOverQuantity] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState({});
  const [rowData, setRowData] = useState([]);
  const remainQuantity = useRef({});
  const totalFabricConsumed = useRef(0);
  const DraftTotalConsumed = useRef(0);
  const totalEfficiency = useRef(0);
  const totalMarkerAvg = useRef(0);
  const layerAvg = useRef(0);
  const [draftData, setDraftData] = useState([]);
  const initialDataLoaded = useRef(false);
  const customTableRef = useRef();
  const [draftExceed, setDraftExceed] = useState(false);
  const [editIndexNo, setEditIndexNo] = useState(0);
  const avgLayerLength = useRef(0);
  const totalLayerLength = useRef(0);
  const totalPlyQty = useRef(0);
  const totalEfficiencyData = useRef(0);
  const [bottomRowData, setBottomRowData] = useState([]);
  const [layerEditMode, setLayerEditMode] = useState(false);
  const getAllDraftCalled = useRef(true);
  const newAllowQuantityObj = useRef({});
  const previousIndex = useRef(null);

  const setEditData = (rowEditData) => {
    console.log("Row Edit Data", rowEditData);
    const layerEditData = {
      markerLength: rowEditData.markerLength,
      marginLength: rowEditData.marginLength,
      width: rowEditData.layerWidth,
      sizeCombo: rowEditData.sizeCombinations,
      qty: rowEditData.quantity,
      efficiency: rowEditData.efficiency,
      isEditLayer: true,
      validCombo: true,
    };
    // layerData.markerLength = rowEditData.markerLength;
    // layerData.marginLength = rowEditData.marginLength;
    // layerData.width = rowEditData.layerWidth;
    // layerData.sizeCombo = rowEditData.sizeCombinations;
    // layerData.qty = rowEditData.quantity;
    // layerData.efficiency = rowEditData.efficiency;
    // layerData.isEditLayer = true;
    setLayerData(layerEditData);
  };

  const ActionCellRenderer = (props) => {
    const handleEditLayer = () => {
      let indexNo = props.node.id;
      let rowEditData = props.node.data;
      setEditData(rowEditData);
      eventBus.emit("editLayer", { message: indexNo });
    };
    const handleDeleteClick = () => {
      let indexNo = props.node.id;
      eventBus.emit("deleteLayer", { message: indexNo });
      if (customTableRef.current) {
        customTableRef.current.onBtDelete(props);
      }
    };
    return (
      <>
        <IconButton onClick={handleEditLayer}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDeleteClick}>
          <DeleteIcon />
        </IconButton>
      </>
    );
  };

  let generalColDefs = [
    {
      field: "layerNo",
    },
    {
      field: "layerLength",
    },
    { field: "sizeCombinations" },
    { field: "layerWidth" },
    {
      field: "Sizes",
      children: [
        {
          field: "demandedQty",
          children: [
            {
              field: "allowedQty",
              children: [
                {
                  headerName: "Remaining Qty",
                  field: "quantity",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      field: "total",
      children: [
        {
          headerName: "0",
          field: "0",
          children: [
            {
              headerName: "0",
              field: "0",
              children: [
                {
                  headerName: "0",
                  field: "draftTotal",
                },
              ],
            },
          ],
        },
      ],
    },
    { field: "efficiency" },
    { field: "totalFabric" },
    { field: "markerAverage" },
    {
      field: "action",
      cellRenderer: ActionCellRenderer,
    },
  ];

  const [colDefs, setColDefs] = React.useState([
    {
      field: "layerNo",
    },
    {
      field: "layerLength",
    },
    { field: "sizeCombinations" },
    { field: "layerWidth" },
    {
      field: "Sizes",
      children: [
        {
          field: "demandedQty",
          children: [
            {
              field: "allowedQty",
              children: [
                {
                  headerName: "Remaining Qty",
                  field: "quantity",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      field: "total",
      children: [
        {
          headerName: "0",
          field: "0",
          children: [
            {
              headerName: "0",
              field: "0",
              children: [
                {
                  headerName: "0",
                  field: "draftTotal",
                },
              ],
            },
          ],
        },
      ],
    },
    { field: "efficiency" },
    { field: "totalFabric" },
    { field: "markerAverage" },
    {
      field: "action",
      cellRenderer: ActionCellRenderer,
    },
  ]);

  useEffect(() => {
    dummyLayor.markerLength = 0;
    dummyLayor.marginLength = 0;
    dummyLayor.width = 0;
    dummyLayor.sizeCombo = "";
    dummyLayor.qty = 0;
    dummyLayor.efficiency = 0;
    dummyLayor.isEditLayer = false;
    setLayerData(dummyLayor);
  }, [location]);

  useEffect(() => {
    let indexNo;
    const handleEditLayerEvent = (data) => {
      indexNo = data.message;
      setEditIndexNo(indexNo);
      layerDataEdit(indexNo, layers);
    };
    eventBus.on("editLayer", handleEditLayerEvent);
    return () => {
      eventBus.off("editLayer", handleEditLayerEvent);
    };
  }, [layers, layerEditMode]);

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    setOrderInfo({ ...orderInfoRef.current });
    setLayerData(dummyLayor);
    setLayers([]);
    setBottomRowData([]);
    setOrderDetailData({});
    setDrawerOpen(false);
    // layers.current = [];
    setRowData([]);
    sizeWithQuantity.current = {};
    allowQuantity.current = {};
    remainQuantity.current = {};
    totalRequired.current = {};
    updateColumns();
  }, []);

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    setOrderInfo({ ...orderInfoRef.current });
    setLayerData(dummyLayor);
    setLayers([]);
    setBottomRowData([]);
    setOrderDetailData({
      orderNumber: orderInfoRef.current.OrderNumberValue,
      buyerName: orderInfoRef.current.buyerName,
      merchantName: orderInfoRef.current.merchantName,
      orderImage: orderInfoRef.current.orderImage || "",
    });
    // layers.current = [];
    setRowData([]);
    sizeWithQuantity.current = {};
    allowQuantity.current = {};
    remainQuantity.current = {};
    totalRequired.current = {};
    updateColumns();
  }, []);

  const processSizeWithQty = () => {
    if (
      !sizeWithQuantity.current ||
      Object.keys(sizeWithQuantity.current).length === 0
    ) {
      return [];
    }
    const updatedObject = Object.keys(sizeWithQuantity.current).map(
      (size, index) => ({
        field: size,
        children: [
          {
            field:
              sizeWithQuantity && sizeWithQuantity.current[size]
                ? `${sizeWithQuantity.current[size]}`
                : "0",
            children: [
              {
                headerName:
                  allowQuantity && allowQuantity.current[size]
                    ? `${allowQuantity.current[size]}`
                    : "0",
                children: [
                  {
                    headerName:
                      remainQuantity && remainQuantity.current[size]
                        ? `${remainQuantity.current[size]}`
                        : "0",
                    field: size,
                  },
                ],
              },
            ],
          },
        ],
      })
    );
    return updatedObject;
  };

  const updateColumns = () => {
    const newCols = processSizeWithQty();
    if (newCols.length === 0) {
      setColDefs(generalColDefs);
      return;
    }
    let totalRequiredSum = Object.values(totalRequired.current).reduce(
      (acc, qty) => acc + qty,
      0
    );
    let allowQunatitySum = Object.values(allowQuantity.current).reduce(
      (acc, qty) => acc + qty,
      0
    );
    let remainQuantitySum = Object.values(remainQuantity.current).reduce(
      (acc, qty) => acc + qty,
      0
    );

    let newColumnDefs = [...colDefs];
    newColumnDefs.forEach((colDef) => {
      if (colDef.field === "total") {
        colDef.children.forEach((child) => {
          if (child.headerName === "0") {
            child.headerName = `${totalRequiredSum}`;
          }
          child.children.forEach((grandchild) => {
            if (grandchild.field === "0") {
              grandchild.headerName = `${allowQunatitySum}`;
            }
            grandchild.children.forEach((grandofgrandchild) => {
              if (grandofgrandchild.field === "draftTotal") {
                grandofgrandchild.headerName = `${remainQuantitySum}`;
              }
            });
          });
        });
      }
    });
    if (colDefs.length === 10) {
      const insertIndex = 5;
      setColDefs([
        ...colDefs.slice(0, insertIndex),
        ...newCols,
        ...colDefs.slice(insertIndex),
      ]);
    } else {
      let value = colDefs;
      value.splice(5, newCols.length, ...newCols);
      setColDefs(value);
    }
  };

  const getAllDraft = async (oeId) => {
    let draftAllResponse = await getAllDrafts(oeId);
    if (draftAllResponse.status === 200) {
      if (location.pathname === "/cutting-plan/drafts/edit") {
        let draftResponse = await getSingleDraft(oeId, draftId);
        const newCols = processSizeWithQty();
        const totalRowData = {
          layerNo: "Total",
          layerLength: draftResponse.data.response.avgLayerLength,
          quantity: draftResponse.data.response.totalPlyQty,
          efficiency: draftResponse.data.response.totalEfficiency,
          totalFabric: draftResponse.data.response.totalFabricConsumed,
          markerAverage: draftResponse.data.response.layerAvg,
          draftTotal: draftResponse.data.response.piecesCut,
        };
        Object.keys(draftResponse.data.response.sizeWithQuantities).forEach(
          (size) => {
            totalRowData[size] =
              draftResponse.data.response.sizeWithQuantities[
                size
              ].sumOfCutQuantity;
          }
        );
        const result = draftResponse.data.response.layers.find(
          (obj) => obj.layerId === layerId
        );
        console.log("specific layer data", result);
        // const layerEditData = {
        //   markerLength: result.layerLength - result.marginLength,
        //   marginLength: result.marginLength,
        //   width: result.layerWidth,
        //   sizeCombo: result.sizeCombinations,
        //   qty: result.quantity,
        //   efficiency: result.efficiency,
        //   isEditLayer: true,
        //   validCombo: true
        // }
        // setLayerData(layerEditData);

        const transformData = (response) => {
          const extractedFields = newCols.map((item) => item.field);
          return response.layers.map((layer) => {
            const draftData = {
              layerNo: layer.number,
              layerLength: layer.length,
              sizeCombinations: layer.sizeCombo,
              layerWidth: layer.width,
              efficiency: layer.efficiency,
              totalFabric: layer.totalFabric,
              markerAverage: layer.avg,
              quantity: layer.qty,
              marginLength: layer.margin,
              markerLength:
                Math.round(
                  (parseFloat(layer.length - layer.margin) + Number.EPSILON) *
                    100
                ) / 100,
              sizeWithRemainingQty: layer.sizeWithRemainingQty,
            };
            extractedFields.forEach((size) => {
              draftData[size] = layer.sizeWithQty[size];
            });
            return draftData;
          });
          // setOverQuantity(response)
        };
        setRowData(transformData(draftResponse.data.response));
        setOverQuantity(draftResponse.data.response.quantityDeviation);
        Object.keys(allowQuantity.current).forEach((size) => {
          allowQuantity.current[size] =
            draftResponse.data.response.sizeWithQuantities[
              size
            ].allowedQuantity;
        });
        Object.keys(remainQuantity.current).forEach((size) => {
          remainQuantity.current[size] =
            draftResponse.data.response.sizeWithQuantities[
              size
            ].remainingCutQuantity;
        });
        updateColumns();
        Object.keys(newAllowQuantityObj.current).forEach((size) => {
          newAllowQuantityObj.current[size] =
            draftResponse.data.response.sizeWithQuantities[size].actualQuantity;
        });

        let percentageIncrease =
          Number(draftResponse.data.response.quantityDeviation) / 100;
        const newAllowQuantitiesForCheck = { ...newAllowQuantityObj.current };
        for (let size in newAllowQuantitiesForCheck) {
          if (newAllowQuantitiesForCheck[size] !== undefined) {
            newAllowQuantitiesForCheck[size] = Math.round(
              newAllowQuantitiesForCheck[size] * (1 + percentageIncrease)
            );
          }
        }
        newAllowQuantityObj.current = newAllowQuantitiesForCheck;

        let newLayers = draftResponse.data.response.layers;

        newLayers = newLayers.map((newlayer) => {
          newlayer["layerNo"] = newlayer["number"];
          newlayer["quantity"] = newlayer["qty"];
          newlayer["markerAverage"] = newlayer["avg"];
          newlayer["layerWidth"] = newlayer["width"];
          newlayer["layerLength"] = newlayer["length"];
          newlayer["sizeCombinations"] = newlayer["sizeCombo"];
          newlayer["sizes"] = newlayer["sizeWithQty"];
          newlayer["marginLength"] = newlayer["margin"];
          newlayer["markerLength"] =
            Math.round(
              (parseFloat(newlayer["length"] - newlayer["margin"]) +
                Number.EPSILON) *
                100
            ) / 100;

          delete newlayer["number"];
          delete newlayer["qty"];
          delete newlayer["avg"];
          delete newlayer["width"];
          delete newlayer["length"];
          delete newlayer["sizeCombo"];
          delete newlayer["sizeWithQty"];
          delete newlayer["margin"];
          return newlayer;
        });
        setLayers(newLayers);
        setDraftData(draftResponse.data.response);
        setBottomRowData([totalRowData]);

        // let newBottomData = {
        //   layerLength: 0,
        //   quantity: 0,
        //   efficiency: 0,
        //   totalFabric: 0,
        //   markerAverage: 0,
        //   sizes: {},
        //   draftTotal: 0
        // };
        console.log("new layers", newLayers);
        newLayers.forEach((obj) => {
          totalLayerLength.current += obj.layerLength;
          totalPlyQty.current += obj.quantity;
          totalEfficiencyData.current += obj.efficiency;
          totalFabricConsumed.current += parseFloat(obj.totalFabric);
          totalMarkerAvg.current += parseFloat(obj.markerAverage);
        });
      } else {
        if (draftAllResponse.data.response.length < 6) {
          setDraftData(draftAllResponse.data.response);
        } else {
          setDraftExceed(true);
          alertRef.current.showAlert(
            ENUMTEXT.WARNING.DRAFT_LIMIT_EXCEED,
            ENUMTEXT.WARNING.WARNING_SERV
          );
        }
      }
    } else {
      alertRef.current.showAlert(draftAllResponse, ENUMTEXT.ERROR.ERROR_SERV);
    }
  };

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const orderAllData = await fetchOrders();
        if (orderAllData.status === 200) {
          setOrderData(orderAllData.data.response);
          const orderIds = Object.keys(orderAllData.data.response.orders);
          const sortedOrderIds = sortOrderIds(orderIds);
          setOrderOptions(sortedOrderIds);
        } else {
          alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadOrders();
  }, []);

  useEffect(() => {
    if (
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    )
      if (orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
          orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
          orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
            label: `${order.style ?? ""} ${order.color ?? ""} ${
              order.size ?? ""
            } ${order.remarks ?? ""}`,
            oeid: order.oeId ?? "",
            sizeWithQty: order.sizeWithQty,
          }));
          if (orderInfoRef.current.optionsStyle.length === 1) {
            orderInfoRef.current.Stylevalue =
              orderInfoRef.current.optionsStyle[0];
          }
          setOrderInfo({ ...orderInfoRef.current });
          setOrderDetailData({
            orderNumber: orderInfoRef.current.OrderNumberValue,
            buyerName: orderInfoRef.current.buyerName,
            merchantName: orderInfoRef.current.merchantName,
            orderImage: selectedOrder[0]?.imagePath || "",
          });
          setDrawerOpen(true);
        }
      } else {
        setOrderInfo((prev) => ({
          ...prev,
          buyerName: "",
          merchantName: "",
          optionsStyle: [],
        }));
      }
  }, [orderInfo.OrderNumberValue, orderData]);

  useEffect(() => {
    const setOrderInfoData = async () => {
      if (
        !initialDataLoaded.current &&
        orderInformation.OrderNumberValue &&
        orderInformation.Stylevalue
      ) {
        orderInfoRef.current.OrderNumberValue =
          orderInformation.OrderNumberValue;
        orderInfoRef.current.Stylevalue = orderInformation.Stylevalue;
        orderInfoRef.current.buyerName = orderInformation.buyerName;
        orderInfoRef.current.fabricName = orderInformation.fabricName;
        orderInfoRef.current.fabricQuality = orderInformation.fabricQuality;
        orderInfoRef.current.maxNoPly = orderInformation.maxNoPly;
        orderInfoRef.current.merchantName = orderInformation.merchantName;
        orderInfoRef.current.optionsStyle = orderInformation.optionsStyle;
        orderInfoRef.current.avlFabric = orderInformation.avlFabric;
        setOrderInfo({ ...orderInfoRef.current });
        initialDataLoaded.current = true;
      }
    };
    setOrderInfoData();
  }, [orderInfo.OrderNumberValue, orderInfo.Stylevalue]);

  useEffect(() => {
    if (
      orderInfo.Stylevalue !== undefined &&
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    ) {
      if (orderInfo.Stylevalue && orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          if (orderDetail) {
            if (!orderDetail.fabAvl) {
              setDialogOpen(true);
            } else {
              orderInfoRef.current.fabricName = orderDetail.fabricName;
              orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
              orderInfoRef.current.maxNoPly = orderDetail.maxNoOfPly;
              orderInfoRef.current.avlFabric = orderDetail.avlFabric;
              setOrderInfo({ ...orderInfoRef.current });
              sizeWithQuantity.current = { ...orderDetail.sizeWithQty };
              totalRequired.current = orderDetail.sizeWithQty;
              allowQuantity.current = { ...orderDetail.sizeWithQty };
              newAllowQuantityObj.current = { ...orderDetail.sizeWithQty };
              remainQuantity.current = orderDetail.sizeWithQty;
              setOrderDetailData((prevData) => ({
                ...prevData,
                style: orderDetail.style,
                color: orderDetail.color,
                fabricName: orderDetail.fabricName,
                fabricQuality: orderDetail.fabricQuality,
                fabricWidth: orderDetail.fabricWidth,
                availableFabric: orderDetail.avlFabric,
                consumedFabric: orderDetail.consumedFabric,
                remainingFabric: orderDetail.remainingFabric,
                maxNoOfPly: orderDetail.maxNoOfPly,
                fabricInhouse: orderDetail.fabInHouse,
                ppApproval: orderDetail.ppApproval,
                cuttingStart: orderDetail.cuttingStartDate,
                cuttingEnd: orderDetail.cuttingEndDate,
                stitchingStart: orderDetail.stitchingStartDate,
                stitchingEnd: orderDetail.stitchingEndDate,
                deliveryDate: orderDetail.deliveryDate,
                sizeWithQty: orderDetail.sizeWithQty,
                sumOfDemandedQty: orderDetail.sumOfDemandedQty,
                noOfLots: orderDetail.noOfLots,
                merchantAvg: orderDetail.merchantAvg,
                plannedAvg: orderDetail.plannedAvg,
                sumOfAllowedQty: orderDetail.sumOfAllowedQty,
                sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
                fabricWidthDetails: orderDetail.fabricWidthDetails,
                addOnQuantity: orderDetail.addOnQuantity,
                totalNoOfParts: orderDetail.totalNoOfParts,
                fusingParts: orderDetail.fusingParts,
                handCuttingParts: orderDetail.handCuttingParts,
                reCuttingParts: orderDetail.reCuttingParts,
                pinning: orderDetail.pinning,
              }));
              if (getAllDraftCalled.current) {
                getAllDraft(orderInfo.Stylevalue.oeid);
                getAllDraftCalled.current = false;
              }
              updateColumns();
            }
          }
        }
      }
    }
  }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

  const handleOverQuantityChange = (event) => {
    let overQuantity = event.target.value;
    setOverQuantity(overQuantity);
    let percentageIncrease = Number(overQuantity) / 100;

    const updateAllowQuantity = () => {
      const newAllowQuantity = { ...sizeWithQuantity.current };
      for (let size in newAllowQuantity) {
        if (newAllowQuantity[size] !== undefined) {
          newAllowQuantity[size] = Math.round(
            newAllowQuantity[size] * (1 + percentageIncrease)
          );
        }
      }
      allowQuantity.current = newAllowQuantity;

      const newAllowQuantitiesForCheck = { ...sizeWithQuantity.current };
      for (let size in newAllowQuantitiesForCheck) {
        if (newAllowQuantitiesForCheck[size] !== undefined) {
          newAllowQuantitiesForCheck[size] = Math.round(
            newAllowQuantitiesForCheck[size] * (1 + percentageIncrease)
          );
        }
      }
      newAllowQuantityObj.current = newAllowQuantitiesForCheck;

      const newRemainQuantity = { ...sizeWithQuantity.current };
      for (let size in newRemainQuantity) {
        if (newRemainQuantity[size] !== undefined) {
          newRemainQuantity[size] = Math.round(
            newRemainQuantity[size] * (1 + percentageIncrease)
          );
        }
      }
      // if (layers.length > 0) {
      //   const lastLayer = layers[layers.length - 1];
      //   Object.keys(newRemainQuantity).forEach(size => {
      //     remainQuantity.current[size] = newRemainQuantity[size] - lastLayer.sizes[size];
      //   });
      // } else {
      //   remainQuantity.current = newRemainQuantity;
      // }
      if (layers.length > 0) {
        // Initialize remainQuantity.current with newRemainQuantity values
        Object.keys(newRemainQuantity).forEach((size) => {
          remainQuantity.current[size] = newRemainQuantity[size];
        });

        // Subtract the sizes for each layer
        layers.forEach((layer) => {
          Object.keys(newRemainQuantity).forEach((size) => {
            remainQuantity.current[size] -= layer.sizes[size];
          });
        });
      } else {
        remainQuantity.current = newRemainQuantity;
      }

      updateColumns();
    };
    updateAllowQuantity();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name !== "sizeCombo" && value.length > 10) {
      return;
    }

    if (name === "sizeCombo" && value.length > 25) {
      return;
    }

    if (name == "sizeCombo") {
      let upperCaseValue = value.toUpperCase();
      let pattern = null;

      if (upperCaseValue) {
        const sizeComboArray = upperCaseValue.split(/[\s,-]+/);
        const filteredValues = sizeComboArray.map(
          (size) => remainQuantity.current[size]
        );
        const smallestValue = Math.min(...filteredValues);
        layerData.qty = smallestValue;
        console.log(layerData.qty);
      } else {
        layerData.qty = 0;
      }
      if (!orderInfo?.Stylevalue) {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.UNSELECT_ORDER_STYLE,
          ENUMTEXT.WARNING.WARNING_SERV
        );
      } else {
        const sizes = Object.keys(orderInfo.Stylevalue.sizeWithQty);
        const sizesPattern = sizes.join("|");
        const patternString = `^(${sizesPattern})([ ,\\-]+(${sizesPattern}))*$`;
        pattern = new RegExp(patternString);
        let valid = pattern.test(upperCaseValue);
        if (upperCaseValue === "") {
          valid = true;
        }

        setLayerData((prevObject) => ({
          ...prevObject,
          [name]: upperCaseValue,
          validCombo: valid,
        }));
      }
    } else {
      if (value >= 0)
        setLayerData((prevObject) => ({
          ...prevObject,
          [name]: value,
        }));
    }
  };

  const checkQuantities = (tempQtyUsed, allowQuantity) => {
    let exceedsAllowed = false;

    Object.keys(tempQtyUsed).forEach((size) => {
      if (tempQtyUsed[size] > allowQuantity[size] + 5) {
        exceedsAllowed = true;
      }
    });

    return exceedsAllowed;
  };
  const tempQtyUsed = {};
  let newTotalRowData = {};

  const addLayer = () => {
    setAddLayerConfirmationPopUp(false);
    setMerchantConfirmationPopup(false);
    if (checkNull([layerData]) == false) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.REQUIRED_FIELD,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    if (draftExceed) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.DRAFT_LIMIT_EXCEED,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    if (
      draftData.length > 0 &&
      location.pathname === "/cutting-plan/drafts/edit"
    ) {
      Object.keys(sizeWithQuantity.current).forEach((size) => {
        tempQtyUsed[size] = draftData.sizeWithQuantities[size].sumOfCutQuantity;
      });
    }
    const sizes = {};
    Object.keys(sizeWithQuantity.current).forEach((size) => {
      sizes[size] = layerData.sizeCombo.split(/[\s,-]+/).includes(size)
        ? parseFloat(layerData.qty)
        : 0;
    });

    Object.keys(sizeWithQuantity.current).forEach((size) => {
      tempQtyUsed[size] = 0;
    });

    let lyrNo = layers.length + 1;
    let data = {
      layerNo: `Layer ${lyrNo}`,
      markerLength:
        Math.round(
          (parseFloat(layerData.markerLength) + Number.EPSILON) * 100
        ) / 100,
      marginLength:
        Math.round(
          (parseFloat(layerData.marginLength) + Number.EPSILON) * 100
        ) / 100,
      layerLength:
        Math.round(
          (parseFloat(layerData.marginLength) +
            parseFloat(layerData.markerLength) +
            Number.EPSILON) *
            100
        ) / 100,
      sizeCombinations: layerData.sizeCombo,
      layerWidth:
        Math.round((parseFloat(layerData.width) + Number.EPSILON) * 100) / 100,
      sizes,
      quantity: parseFloat(layerData.qty),
      efficiency:
        Math.round((parseFloat(layerData.efficiency) + Number.EPSILON) * 100) /
        100,
      totalFabric: parseFloat(
        parseFloat(
          parseFloat(layerData.qty) *
            (parseFloat(layerData.marginLength) +
              parseFloat(layerData.markerLength))
        ).toFixed(2)
      ),
      markerAverage: parseFloat(
        parseFloat(
          "" +
            (parseFloat(layerData.marginLength) +
              parseFloat(layerData.markerLength)) /
              layerData.sizeCombo.split(/[\s,-]+/).length
        ).toFixed(2)
      ),
    };
    let arr;
    arr = [...layers, data];
    arr.forEach((item) => {
      let sizes = item.sizes !== undefined ? item.sizes : item.sizeWithQty;
      if (sizes !== undefined) {
        Object.keys(tempQtyUsed).forEach((size) => {
          if (sizes[size] !== undefined) {
            tempQtyUsed[size] += sizes[size];
          }
        });
      }
    });

    if (checkQuantities(tempQtyUsed, newAllowQuantityObj.current)) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.QUANTITY_EXCEED,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    const sizeComboArray = layerData.sizeCombo.split(/[\s,-]+/);
    // let increasedQty = false;

    for (const size of sizeComboArray) {
      if (tempQtyUsed[size] > allowQuantity.current[size]) {
        let quantityIncreased = tempQtyUsed[size] - allowQuantity.current[size];
        allowQuantity.current[size] =
          allowQuantity.current[size] + quantityIncreased;
        // break;
      }
    }

    // if (increasedQty) {
    //   sizeComboArray.forEach(size => {
    //     allowQuantity.current[size] = (allowQuantity.current[size] + 5);
    //     remainQuantity.current[size] = (allowQuantity.current[size]) - tempQtyUsed[size];
    //     updateColumns();
    //   });
    // } else {
    //   sizeComboArray.forEach(size => {
    //     remainQuantity.current[size] = (allowQuantity.current[size]) - tempQtyUsed[size];
    //     updateColumns();
    //   });
    // }
    sizeComboArray.forEach((size) => {
      remainQuantity.current[size] =
        allowQuantity.current[size] - tempQtyUsed[size];
      updateColumns();
    });

    console.log(
      "Falana Dhimkana",
      totalFabricConsumed.current,
      totalEfficiencyData.current,
      totalEfficiency.current,
      totalMarkerAvg.current,
      layerAvg.current,
      totalLayerLength.current,
      avgLayerLength.current,
      totalPlyQty.current
    );

    totalFabricConsumed.current =
      totalFabricConsumed.current + parseFloat(data.totalFabric);
    totalFabricConsumed.current =
      Math.round(
        (parseFloat(totalFabricConsumed.current) + Number.EPSILON) * 100
      ) / 100;

    totalEfficiencyData.current = totalEfficiencyData.current + data.efficiency;
    totalEfficiency.current =
      Math.round(
        (parseFloat(totalEfficiencyData.current / (layers.length + 1)) +
          Number.EPSILON) *
          100
      ) / 100;

    totalMarkerAvg.current =
      totalMarkerAvg.current + parseFloat(data.markerAverage);
    totalLayerLength.current =
      totalLayerLength.current + parseFloat(data.layerLength);
    avgLayerLength.current =
      Math.round(
        (parseFloat(totalLayerLength.current / (layers.length + 1)) +
          Number.EPSILON) *
          100
      ) / 100;
    totalPlyQty.current = totalPlyQty.current + data.quantity;

    console.log(
      "After Adding data",
      totalLayerLength.current,
      totalPlyQty.current,
      totalEfficiencyData.current,
      totalFabricConsumed.current,
      totalMarkerAvg.current
    );
    let totalQtyMade = 0;
    for (let size in tempQtyUsed) {
      totalQtyMade += tempQtyUsed[size];
    }

    DraftTotalConsumed.current = totalQtyMade;

    layerAvg.current =
      Math.round(
        (parseFloat(totalFabricConsumed.current / totalQtyMade) +
          Number.EPSILON) *
          100
      ) / 100;
    let totalData = {
      layerNo: "Total",
      layerLength:
        Math.round(
          (parseFloat(avgLayerLength.current) + Number.EPSILON) * 100
        ) / 100,
      quantity: totalPlyQty.current,
      efficiency:
        Math.round(
          (parseFloat(totalEfficiency.current) + Number.EPSILON) * 100
        ) / 100,
      totalFabric: totalFabricConsumed.current,
      markerAverage:
        Math.round((parseFloat(layerAvg.current) + Number.EPSILON) * 100) / 100,
      draftTotal: totalQtyMade,
    };

    Object.keys(tempQtyUsed).forEach((size) => {
      totalData[size] = tempQtyUsed[size];
    });

    const transformedData = arr.map((item) => {
      const sizes = item.sizes !== undefined ? item.sizes : item.sizeWithQty;
      const {
        sizes: ignoredSizes,
        sizeWithQty: ignoredSizeWithQty,
        ...rest
      } = item;
      return { ...rest, ...sizes };
    });
    setLayers(arr);
    // layers.current = arr;
    setBottomRowData([totalData]);
    setRowData(transformedData);
    setLayerData(dummyLayor);
    if (layerData.isEditLayer) {
      dummyLayor.markerLength = 0;
      dummyLayor.marginLength = 0;
      dummyLayor.width = 0;
      dummyLayor.sizeCombo = "";
      dummyLayor.qty = 0;
      dummyLayor.efficiency = 0;
      dummyLayor.isEditLayer = false;
      setLayerData(dummyLayor);
    }
    setLayerEditMode(false);
    console.log(transformedData);
    console.log(remainQuantity.current);
  };

  const handleAddLayer = () => {
    addLayer();
  };

  const handleEditLayer = () => {
    if (checkNull([layerData]) == false) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.REQUIRED_FIELD,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    if (draftExceed) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.DRAFT_LIMIT_EXCEED,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    if (
      draftData.length > 0 &&
      location.pathname === "/cutting-plan/drafts/edit"
    ) {
      Object.keys(sizeWithQuantity.current).forEach((size) => {
        tempQtyUsed[size] = draftData.sizeWithQuantities[size].sumOfCutQuantity;
      });
    }
    const sizes = {};
    Object.keys(sizeWithQuantity.current).forEach((size) => {
      sizes[size] = layerData.sizeCombo.split(/[\s,-]+/).includes(size)
        ? parseFloat(layerData.qty)
        : 0;
    });

    Object.keys(sizeWithQuantity.current).forEach((size) => {
      tempQtyUsed[size] = 0;
    });

    let lyrNo = layers.length + 1;
    let data = {
      layerNo:
        layers.length === 0 ? `Layer ${lyrNo}` : layers[editIndexNo].layerNo,
      markerLength:
        Math.round(
          (parseFloat(layerData.markerLength) + Number.EPSILON) * 100
        ) / 100,
      marginLength:
        Math.round(
          (parseFloat(layerData.marginLength) + Number.EPSILON) * 100
        ) / 100,
      layerLength:
        Math.round(
          (parseFloat(layerData.marginLength) +
            parseFloat(layerData.markerLength) +
            Number.EPSILON) *
            100
        ) / 100,
      sizeCombinations: layerData.sizeCombo,
      layerWidth:
        Math.round((parseFloat(layerData.width) + Number.EPSILON) * 100) / 100,
      sizes,
      quantity: parseFloat(layerData.qty),
      efficiency:
        Math.round((parseFloat(layerData.efficiency) + Number.EPSILON) * 100) /
        100,
      totalFabric: parseFloat(
        parseFloat(
          parseFloat(layerData.qty) *
            (parseFloat(layerData.marginLength) +
              parseFloat(layerData.markerLength))
        ).toFixed(2)
      ),
      markerAverage: parseFloat(
        parseFloat(
          "" +
            (parseFloat(layerData.marginLength) +
              parseFloat(layerData.markerLength)) /
              layerData.sizeCombo.split(/[\s,-]+/).length
        ).toFixed(2)
      ),
    };

    let arr;
    layers[editIndexNo] = data;
    arr = layers;

    arr.forEach((item) => {
      let sizes = item.sizes !== undefined ? item.sizes : item.sizeWithQty;
      if (sizes !== undefined) {
        Object.keys(tempQtyUsed).forEach((size) => {
          if (sizes[size] !== undefined) {
            tempQtyUsed[size] += sizes[size];
          }
        });
      }
    });
    console.log(arr);

    if (checkQuantities(tempQtyUsed, newAllowQuantityObj.current)) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.QUANTITY_EXCEED,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    const sizeComboArray = layerData.sizeCombo.split(/[\s,-]+/);
    // let increasedQty = false;

    for (const size of sizeComboArray) {
      if (tempQtyUsed[size] > allowQuantity.current[size]) {
        let quantityIncreased = tempQtyUsed[size] - allowQuantity.current[size];
        allowQuantity.current[size] =
          allowQuantity.current[size] + quantityIncreased;
        // increasedQty = true;
        // break;
      }
    }

    // if (increasedQty) {
    //   sizeComboArray.forEach(size => {
    //     allowQuantity.current[size] = (allowQuantity.current[size] + 5);
    //     remainQuantity.current[size] = (allowQuantity.current[size]) - tempQtyUsed[size];
    //     updateColumns();
    //   });
    // } else {
    //   sizeComboArray.forEach(size => {
    //     remainQuantity.current[size] = (allowQuantity.current[size]) - tempQtyUsed[size];
    //     updateColumns();
    //   });
    // }
    sizeComboArray.forEach((size) => {
      remainQuantity.current[size] =
        allowQuantity.current[size] - tempQtyUsed[size];
      updateColumns();
    });

    totalFabricConsumed.current =
      totalFabricConsumed.current + parseFloat(data.totalFabric);
    totalEfficiencyData.current = totalEfficiencyData.current + data.efficiency;

    totalEfficiency.current =
      Math.round(
        (parseFloat(
          totalEfficiencyData.current /
            (layers.length === 0 ? layers.length + 1 : layers.length)
        ) +
          Number.EPSILON) *
          100
      ) / 100;
    totalMarkerAvg.current =
      totalMarkerAvg.current + parseFloat(data.markerAverage);

    totalLayerLength.current =
      totalLayerLength.current + parseFloat(data.layerLength);

    avgLayerLength.current =
      Math.round(
        (parseFloat(
          totalLayerLength.current /
            (layers.length === 0 ? layers.length + 1 : layers.length)
        ) +
          Number.EPSILON) *
          100
      ) / 100;
    totalPlyQty.current = totalPlyQty.current + data.quantity;

    let totalQtyMade = 0;
    for (let size in tempQtyUsed) {
      totalQtyMade += tempQtyUsed[size];
    }
    layerAvg.current =
      Math.round(
        (parseFloat(totalFabricConsumed.current / totalQtyMade) +
          Number.EPSILON) *
          100
      ) / 100;
    let totalData = {
      layerNo: "Total",
      layerLength:
        Math.round(
          (parseFloat(avgLayerLength.current) + Number.EPSILON) * 100
        ) / 100,
      quantity: totalPlyQty.current,
      efficiency:
        Math.round(
          (parseFloat(totalEfficiency.current) + Number.EPSILON) * 100
        ) / 100,
      totalFabric: totalFabricConsumed.current,
      markerAverage:
        Math.round((parseFloat(layerAvg.current) + Number.EPSILON) * 100) / 100,
      draftTotal: totalQtyMade,
    };

    Object.keys(tempQtyUsed).forEach((size) => {
      totalData[size] = tempQtyUsed[size];
    });

    const transformedData = arr.map((item) => {
      const sizes = item.sizes !== undefined ? item.sizes : item.sizeWithQty;
      const {
        sizes: ignoredSizes,
        sizeWithQty: ignoredSizeWithQty,
        ...rest
      } = item;
      return { ...rest, ...sizes };
    });
    setLayers(arr);
    // if (location.pathname === "/cutting-plan/drafts/edit") {
    //   let newBottomData = {
    //     layerLength: 0,
    //     quantity: 0,
    //     efficiency: 0,
    //     totalFabric: 0,
    //     markerAverage: 0,
    //     sizes: {},
    //     draftTotal: 0
    //   };

    //   let count = layers.length;
    //   layers.forEach(obj => {
    //     newBottomData.layerLength += obj.layerLength;
    //     newBottomData.quantity += obj.quantity;
    //     newBottomData.efficiency += obj.efficiency;
    //     newBottomData.totalFabric += parseFloat(obj.totalFabric);
    //     newBottomData.markerAverage += parseFloat(obj.markerAverage);

    //     // Sum each size
    //     for (let size in obj.sizes) {
    //       if (!newBottomData.sizes[size]) {
    //         newBottomData.sizes[size] = 0;
    //       }
    //       newBottomData.sizes[size] += obj.sizes[size];
    //     }
    //   });

    //   newBottomData.layerLength /= count;
    //   newBottomData.efficiency /= count;
    //   newBottomData.markerAverage /= count;
    //   console.log("bottom row data after", newBottomData);
    //   // Sum all sizes
    //   for (let size in newBottomData.sizes) {
    //     newBottomData.draftTotal += newBottomData.sizes[size];
    //   }
    //   newTotalRowData = {
    //     layerNo: "Total",
    //     layerLength: Math.round((parseFloat(newBottomData.layerLength) + Number.EPSILON) * 100) / 100,
    //     quantity: newBottomData.quantity,
    //     efficiency: Math.round((parseFloat(newBottomData.efficiency) + Number.EPSILON) * 100) / 100,
    //     totalFabric: newBottomData.totalFabric,
    //     markerAverage: Math.round((parseFloat(newBottomData.markerAverage) + Number.EPSILON) * 100) / 100,
    //     draftTotal: newBottomData.draftTotal,
    //   }
    //   Object.keys(newBottomData.sizes).forEach((size) => {
    //     newTotalRowData[size] = newBottomData.sizes[size];
    //   });
    //   setBottomRowData([newTotalRowData]);
    // } else {
    //   setBottomRowData([totalData]);
    // }
    // layers.current = arr;
    setBottomRowData([totalData]);
    setRowData(transformedData);
    setLayerData(dummyLayor);
    if (layerData.isEditLayer) {
      dummyLayor.markerLength = 0;
      dummyLayor.marginLength = 0;
      dummyLayor.width = 0;
      dummyLayor.sizeCombo = "";
      dummyLayor.qty = 0;
      dummyLayor.efficiency = 0;
      dummyLayor.isEditLayer = false;
      setLayerData(dummyLayor);
    }
    setLayerEditMode(false);
    console.log("TR", transformedData);
    console.log(remainQuantity.current);
    previousIndex.current = null;
  };

  const layerDataRemove = (index, layers, layerEditMode) => {
    let sizeComboArray;
    sizeComboArray = layers[index].sizeCombinations.split(/[\s,-]+/);
    if (!layerEditMode) {
      if (!layerData.isEditLayer) {
        totalMarkerAvg.current =
          totalMarkerAvg.current - parseFloat(layers[index].markerAverage);
        totalLayerLength.current =
          totalLayerLength.current === 0
            ? 0
            : totalLayerLength.current - parseFloat(layers[index].layerLength);
        sizeComboArray.forEach((size) => {
          if (allowQuantity.current[size] > newAllowQuantityObj.current[size]) {
            let quantityIncreased =
              allowQuantity.current[size] - newAllowQuantityObj.current[size];
            remainQuantity.current[size] =
              remainQuantity.current[size] +
              layers[index].sizes[size] -
              quantityIncreased;
          } else {
            remainQuantity.current[size] =
              remainQuantity.current[size] + layers[index].sizes[size];
          }
          updateColumns();
          tempQtyUsed[size] = tempQtyUsed[size] - layers[index].sizes[size];
        });
      }

      console.log(
        "Before Delete Data",
        totalLayerLength.current,
        totalPlyQty.current,
        totalEfficiencyData.current,
        totalFabricConsumed.current,
        totalMarkerAvg.current
      );
      //totalLayerLength.current = totalLayerLength.current - layers[index].layerLength;
      totalPlyQty.current = totalPlyQty.current - layers[index].quantity;
      totalEfficiencyData.current =
        totalEfficiencyData.current - layers[index].efficiency;
      totalFabricConsumed.current =
        totalFabricConsumed.current - parseFloat(layers[index].totalFabric);
      //totalMarkerAvg.current = totalMarkerAvg.current -  parseFloat(layers[index].markerAverage);
      console.log(
        "After Delete Data",
        totalLayerLength.current,
        totalPlyQty.current,
        totalEfficiencyData.current,
        totalFabricConsumed.current,
        totalMarkerAvg.current
      );

      for (const size of sizeComboArray) {
        if (allowQuantity.current[size] > newAllowQuantityObj.current[size]) {
          let quantityIncreased =
            allowQuantity.current[size] - newAllowQuantityObj.current[size];
          allowQuantity.current[size] =
            allowQuantity.current[size] - quantityIncreased;
          updateColumns();
          break;
        }
      }
    }

    if (layerData.isEditLayer) {
      dummyLayor.markerLength = 0;
      dummyLayor.marginLength = 0;
      dummyLayor.width = 0;
      dummyLayor.sizeCombo = "";
      dummyLayor.qty = 0;
      dummyLayor.efficiency = 0;
      dummyLayor.isEditLayer = false;
      setLayerData(dummyLayor);
    }

    // for (let i = index; i < layers.length; i++) {
    //   layers[i].layerNo -= 1;
    // }

    for (let i = index; i < layers.length; i++) {
      let number = layers[i].layerNo.match(/\d+/);
      let reducedNumber = parseInt(number) - 1;
      layers[i].layerNo = layers[i].layerNo.replace(number, reducedNumber);
    }

    layers.splice(index, 1);
    setLayers(layers);
    console.log(layers);
    const transformedData = layers.map((item) => {
      const { sizes, ...rest } = item;
      return { ...rest, ...sizes };
    });
    setRowData(transformedData);

    let newBottomData = {
      layerLength: 0,
      quantity: 0,
      efficiency: 0,
      totalFabric: 0,
      markerAverage: 0,
      sizes: {},
      draftTotal: 0,
    };

    let count = layers.length;
    layers.forEach((obj) => {
      newBottomData.layerLength += obj.layerLength;
      newBottomData.quantity += obj.quantity;
      newBottomData.efficiency += obj.efficiency;
      newBottomData.totalFabric += parseFloat(obj.totalFabric);
      newBottomData.markerAverage += parseFloat(obj.markerAverage);

      // Sum each size
      for (let size in obj.sizes) {
        if (!newBottomData.sizes[size]) {
          newBottomData.sizes[size] = 0;
        }
        newBottomData.sizes[size] += obj.sizes[size];
      }
    });

    newBottomData.layerLength /= count;
    newBottomData.efficiency /= count;
    //newBottomData.markerAverage /= count;

    // Sum all sizes
    for (let size in newBottomData.sizes) {
      newBottomData.draftTotal += newBottomData.sizes[size];
    }

    newBottomData.markerAverage =
      newBottomData.totalFabric / newBottomData.draftTotal;

    let newTotalRowData = {
      layerNo: "Total",
      layerLength: newBottomData.layerLength
        ? Math.round(
            (parseFloat(newBottomData.layerLength) + Number.EPSILON) * 100
          ) / 100
        : 0,
      quantity: newBottomData.quantity ? newBottomData.quantity : 0,
      efficiency: newBottomData.efficiency
        ? Math.round(
            (parseFloat(newBottomData.efficiency) + Number.EPSILON) * 100
          ) / 100
        : 0,
      totalFabric: newBottomData.totalFabric ? newBottomData.totalFabric : 0,
      markerAverage: newBottomData.markerAverage
        ? Math.round(
            (parseFloat(newBottomData.markerAverage) + Number.EPSILON) * 100
          ) / 100
        : 0,
      draftTotal: newBottomData.draftTotal ? newBottomData.draftTotal : 0,
    };
    Object.keys(newBottomData.sizes).forEach((size) => {
      newTotalRowData[size] = newBottomData.sizes[size];
    });
    setBottomRowData([newTotalRowData]);
    totalFabricConsumed.current = newBottomData.totalFabric
      ? newBottomData.totalFabric
      : 0;
    totalEfficiency.current = newBottomData.efficiency
      ? Math.round(
          (parseFloat(newBottomData.efficiency) + Number.EPSILON) * 100
        ) / 100
      : 0;
    layerAvg.current = newBottomData.markerAverage
      ? Math.round(
          (parseFloat(newBottomData.markerAverage) + Number.EPSILON) * 100
        ) / 100
      : 0;
    avgLayerLength.current = newBottomData.layerLength
      ? Math.round(
          (parseFloat(newBottomData.layerLength) + Number.EPSILON) * 100
        ) / 100
      : 0;
    totalPlyQty.current = newBottomData.quantity ? newBottomData.quantity : 0;
    if (layers.length === 0) {
      setBottomRowData([]);
    }
    setLayerEditMode(false);
  };

  useEffect(() => {
    const handleDeleteLayerEvent = (data) => {
      const indexNo = data.message;
      layerDataRemove(indexNo, layers, layerEditMode);
    };
    eventBus.on("deleteLayer", handleDeleteLayerEvent);
    return () => {
      eventBus.off("deleteLayer", handleDeleteLayerEvent);
    };
  }, [layers, layerEditMode]);

  const layerDataEdit = (index, layers) => {
    if (previousIndex.current !== index) {
      let sizeComboArray;

      //for prev index
      if (previousIndex.current !== null) {
        sizeComboArray =
          layers[previousIndex.current].sizeCombinations.split(/[\s,-]+/);
        sizeComboArray.forEach((size) => {
          if (allowQuantity.current[size] > newAllowQuantityObj.current[size]) {
            let quantityIncreased =
              allowQuantity.current[size] - newAllowQuantityObj.current[size];
            remainQuantity.current[size] =
              remainQuantity.current[size] -
              layers[previousIndex.current].sizes[size] -
              quantityIncreased;
          } else {
            remainQuantity.current[size] =
              remainQuantity.current[size] -
              layers[previousIndex.current].sizes[size];
          }
          updateColumns();
          tempQtyUsed[size] =
            tempQtyUsed[size] + layers[previousIndex.current].sizes[size];
        });

        for (const size of sizeComboArray) {
          if (allowQuantity.current[size] > newAllowQuantityObj.current[size]) {
            let quantityIncreased =
              allowQuantity.current[size] - newAllowQuantityObj.current[size];
            allowQuantity.current[size] =
              allowQuantity.current[size] - quantityIncreased;
            updateColumns();
            break;
          }
        }

        console.log("layers", layers);
        totalFabricConsumed.current =
          totalFabricConsumed.current +
          parseFloat(layers[previousIndex.current].totalFabric);
        totalEfficiencyData.current =
          totalEfficiencyData.current +
          layers[previousIndex.current].efficiency;
        // totalEfficiency.current = (totalEfficiency.current - layers[index].efficiency)
        totalMarkerAvg.current =
          totalMarkerAvg.current +
          parseFloat(layers[previousIndex.current].markerAverage);
        totalLayerLength.current =
          totalLayerLength.current === 0
            ? 0
            : totalLayerLength.current +
              parseFloat(layers[previousIndex.current].layerLength);
        totalPlyQty.current =
          totalPlyQty.current + layers[previousIndex.current].quantity;
        setLayerEditMode(true);
      }

      previousIndex.current = index;

      //for current index
      sizeComboArray = layers[index].sizeCombinations.split(/[\s,-]+/);
      sizeComboArray.forEach((size) => {
        if (allowQuantity.current[size] > newAllowQuantityObj.current[size]) {
          let quantityIncreased =
            allowQuantity.current[size] - newAllowQuantityObj.current[size];
          remainQuantity.current[size] =
            remainQuantity.current[size] +
            layers[index].sizes[size] -
            quantityIncreased;
        } else {
          remainQuantity.current[size] =
            remainQuantity.current[size] + layers[index].sizes[size];
        }
        updateColumns();
        tempQtyUsed[size] = tempQtyUsed[size] - layers[index].sizes[size];
      });

      for (const size of sizeComboArray) {
        if (allowQuantity.current[size] > newAllowQuantityObj.current[size]) {
          let quantityIncreased =
            allowQuantity.current[size] - newAllowQuantityObj.current[size];
          allowQuantity.current[size] =
            allowQuantity.current[size] - quantityIncreased;
          updateColumns();
          break;
        }
      }

      console.log("layers", layers);
      totalFabricConsumed.current =
        totalFabricConsumed.current - parseFloat(layers[index].totalFabric);
      totalEfficiencyData.current =
        totalEfficiencyData.current - layers[index].efficiency;
      // totalEfficiency.current = (totalEfficiency.current - layers[index].efficiency)
      totalMarkerAvg.current =
        totalMarkerAvg.current - parseFloat(layers[index].markerAverage);
      totalLayerLength.current =
        totalLayerLength.current === 0
          ? 0
          : totalLayerLength.current - parseFloat(layers[index].layerLength);
      totalPlyQty.current = totalPlyQty.current - layers[index].quantity;
      setLayerEditMode(true);
    }
  };
  const submit = async () => {
    console.log("layerData", layerData);
    let hasDifferentValue = false;
    for (let key in Object.keys(layerData)) {
      if (
        layerData[key] === null ||
        layerData[key] === undefined ||
        layerData[key] === ""
      ) {
        continue;
      }
      if (layerData[key] !== initialLayor[key]) {
        hasDifferentValue = true;
        break;
      }
    }
    if (hasDifferentValue) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.UNSAVE_CHANGES,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    if (
      !orderInfo.OrderNumberValue ||
      !orderInfo.Stylevalue ||
      layers.length === 0
    ) {
      alertRef.current.showAlert(
        ENUMTEXT.WARNING.ATLEAST_ONE_LAYER,
        ENUMTEXT.WARNING.WARNING_SERV
      );
      return;
    }
    const tempQtyUsed = {};
    Object.keys(sizeWithQuantity.current).forEach((size) => {
      tempQtyUsed[size] = 0;
    });

    layers.forEach((item) => {
      Object.keys(tempQtyUsed).forEach((size) => {
        if (item.sizes[size] !== undefined) {
          tempQtyUsed[size] += item.sizes[size];
        }
      });
    });

    let value = {};
    Object.keys(sizeWithQuantity.current).forEach((size) => {
      value[size] = 0;
    });

    let requestLayer = layers.map((x) => {
      let tempValue = {};
      Object.keys(value).forEach((size) => {
        tempValue[size] = value[size] + x.sizes[size];
      });

      value = tempValue;

      const sizeWithQty = {};
      Object.keys(value).forEach((size) => {
        sizeWithQty[size] = x.sizes[size];
      });

      const sizeWithRemainingQty = {};
      Object.keys(value).forEach((size) => {
        sizeWithRemainingQty[size] = allowQuantity.current[size] - value[size];
      });

      // let number;

      // if (typeof x.layerNo === 'string') {
      //   number = x.layerNo.includes("Layer") ? x.layerNo : "Layer " + x.layerNo;
      // } else {
      //   number = "Layer " + x.layerNo;
      // }

      let temp = {
        number: x.layerNo,
        qty: x.quantity,
        length: x.layerLength,
        margin: x.marginLength,
        sizeCombo: x.sizeCombinations,
        width: x.layerWidth,
        efficiency: x.efficiency,
        totalFabric: x.totalFabric,
        avg: x.markerAverage,
        sizeWithQty,
        sizeWithRemainingQty,
      };
      return temp;
    });
    let sumOfSizeWithAllowedQty = Object.values(allowQuantity.current).reduce(
      (acc, qty) => acc + qty,
      0
    );
    let remainQuantitySum = Object.values(remainQuantity.current).reduce(
      (acc, qty) => acc + qty,
      0
    );
    let remainingFabric = orderInfo.avlFabric - totalFabricConsumed.current;
    let piecesCut = sumOfSizeWithAllowedQty - remainQuantitySum;
    let sizeWithQuantities = {};
    Object.keys(sizeWithQuantity.current).forEach((size) => {
      sizeWithQuantities[size] = {
        actualQuantity: sizeWithQuantity.current[size],
        allowedQuantity: allowQuantity.current[size],
        remainingCutQuantity: remainQuantity.current[size],
        sumOfCutQuantity: tempQtyUsed[size],
      };
    });

    let body = {
      oeId: orderInfo.Stylevalue.oeid,
      orderId: orderInfo.OrderNumberValue,
      id:
        location.pathname === "/cutting-plan/drafts/edit" ? draftData.id : null,
      draftName:
        location.pathname === "/cutting-plan/drafts/edit"
          ? `${draftData.draftName}`
          : `Draft ${draftData.length + 1}`,
      freeze: false,
      orderType: globalValue,
      quantityDeviation: parseFloat(overQuantity),
      sumOfSizeWithAllowedQty: sumOfSizeWithAllowedQty,
      sumOfSizeWithCutQty: remainQuantitySum,
      avlFabric: orderInfo.avlFabric,
      totalFabricConsumed:
        location.pathname === "/cutting-plan/drafts/edit"
          ? bottomRowData[0].totalFabric
          : totalFabricConsumed.current,
      totalEfficiency:
        location.pathname === "/cutting-plan/drafts/edit"
          ? bottomRowData[0].efficiency
          : totalEfficiency.current,
      totalMarkerAvg: totalMarkerAvg.current,
      layerAvg:
        location.pathname === "/cutting-plan/drafts/edit"
          ? bottomRowData[0].markerAverage
          : layerAvg.current,
      remainingFabric: remainingFabric,
      piecesCut: piecesCut,
      piecesRemaining: remainQuantitySum,
      sizeWithQuantities: sizeWithQuantities,
      layers: requestLayer,
    };

    const addDraftApiResponse = await addDraftApi(body);
    if (addDraftApiResponse.status === 200) {
      alertRef.current.showAlert(
        ENUMTEXT.SUCCESS.DRAFTADDED,
        ENUMTEXT.SUCCESS.SUCCESS_SERV
      );
      navigate("/cutting-plan/drafts");
      setLayers([]);
      setLayerData(dummyLayor);
    } else {
      alertRef.current.showAlert(
        addDraftApiResponse,
        ENUMTEXT.ERROR.ERROR_SERV
      );
    }
  };

  const handleSubmit = async () => {
    const totalFabricCount =
      parseFloat(
        parseFloat(
          parseFloat(layerData.qty) *
            (parseFloat(layerData.marginLength) +
              parseFloat(layerData.markerLength))
        ).toFixed(2)
      ) + totalFabricConsumed.current;

    const totalPiece = parseFloat(layerData.qty) + DraftTotalConsumed.current;
    if (totalFabricCount > issuedFabric) {
      setAddLayerConfirmationPopUp(true);
    } else if (
      totalFabricCount / totalPiece + 0.06 >
      orderDetailData.merchantAvg
    ) {
      setMerchantConfirmationPopup(true);
    } else {
      submit();
    }
  };

  return (
    <Box>
      <CustomAlert ref={alertRef} />
      <ResponsiveDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        orderDetails={orderInfo}
      />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
              disabled={
                location.pathname === "/cutting-plan/drafts/edit" ? true : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
              disabled={
                location.pathname === "/cutting-plan/drafts/edit" ? true : false
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <Box>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                type="number"
                value={overQuantity}
                onChange={handleOverQuantityChange}
                label="Over or Under Quantity (%)"
                inputProps={{ maxLength: 10 }}
              />
            </Box>
          </Grid>
        </Grid>
        <FormControl sx={{ width: "100%" }}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            style={{ padding: "10px", width: "100%", marginTop: 2 }}
          >
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Marker Length"
                inputProps={{ maxLength: 10 }}
                required
                type="number"
                value={layerData.markerLength}
                name="markerLength"
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Layer Margin"
                inputProps={{ maxLength: 10 }}
                required
                type="number"
                value={layerData.marginLength}
                name="marginLength"
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Layer Width"
                inputProps={{ maxLength: 10 }}
                required
                type="number"
                value={layerData.width}
                name="width"
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                required
                label="Size Combination"
                inputProps={{ maxLength: 25 }}
                value={layerData.sizeCombo}
                error={!layerData.validCombo}
                placeholder="S-M-XL"
                name="sizeCombo"
                onChange={(e) => {
                  handleChange(e);
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Ply Quantity"
                inputProps={{ maxLength: 10 }}
                required
                type="number"
                value={layerData.qty}
                name="qty"
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <TextField
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Efficiency(%)"
                inputProps={{ maxLength: 10 }}
                type="number"
                required
                value={layerData.efficiency}
                name="efficiency"
                onChange={handleChange}
                InputProps={{
                  inputProps: { min: 0 },
                }}
                onKeyPress={(event) => {
                  if (event?.key === "-" || event?.key === "+") {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomButton
                variant="contained"
                type="submit"
                onClick={
                  layerData.isEditLayer ? handleEditLayer : handleAddLayer
                }
                disabled={
                  orderInfo.Stylevalue === "" ||
                  orderInfo.Stylevalue == null ||
                  !layerData.validCombo
                }
              >
                {location.pathname === "/cutting-plan/drafts/add"
                  ? "Add Layer"
                  : "Save Changes"}
              </CustomButton>
            </Grid>
          </Grid>
        </FormControl>
      </Box>
      <Box sx={{ padding: 2 }}>
        <CustomTable
          colDefs={colDefs}
          drawerOpen={drawerOpen}
          rowData={rowData}
          whichPage={"addDraft"}
          ref={customTableRef}
          pinnedBottomRowData={bottomRowData}
        />
        <Box sx={{ mt: 5 }}>
          <CustomButton variant="contained" onClick={handleSubmit}>
            Submit
          </CustomButton>
        </Box>
      </Box>

      <AddLayerConfirmation
        open={addLayerConfirmationPopUp}
        handleConfirm={submit}
        handleUpdateLot={() => {
          console.log("call");
          navigate("/lot-plan/add", {
            state: { autoSelectLotId: orderInfo.OrderNumberValue },
          });
        }}
        confirmText={"Submit"}
        updateText={"Update Lot"}
        Dialogtext={
          "Fabric issued is less than fabric needed in the draft, please add fabric as it may impact quantity"
        }
        Dialogtitle={"Fabric Issue"}
      />
      <MerchantConfirmation
        open={merchantConfirmationPopup}
        handleConfirm={submit}
        handleUpdateLot={() => {
          navigate("/lot-plan/receive-fabric", {
            state: { autoSelectOrderId: orderInfo.OrderNumberValue },
          });
        }}
        confirmText={"Submit"}
        updateText={"Update Average"}
      />
    </Box>
  );
};

export default AddDrafts;
