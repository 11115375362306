import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { Grid, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import sortOrderIds from "../../components/common/SortOrders";
import CustomButton from "../../components/common/buttons/CustomButton";
import OrderDetailModel from "../../components/specific/lotplan/OrderDetailsModel";
import CustomAlert from "../../components/common/CustomAlert";
import { fetchOrders } from "../../services/OrderListServices";
import { viewLotData } from "../../services/ViewLotServices";
import CustomAutoComplete from "../../components/common/dropdowns/CustomAutoComplete";
import CustomTable from "../../components/common/tables/CustomTable";
import * as ENUMTEXT from "../../constants/AlertText";
import styled from "styled-components";

export default function LotPlan() {
  const alertRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isLotAvilable = useRef(false);
  const [orderData, setOrderData] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);

  const OrderText = styled(Typography)`
    margin-bottom: 8px !important;
    font-size: 14px !important;
  `;
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
    orderImage: null,
  });
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState({});
  const navigate = useNavigate();
  const [rowData, setRowData] = useState([]);
  const [bottomRowData, setBottomRowData] = useState([]);
  const newColDefs = [
    {
      field: "lotNo",
    },
    {
      headerName: "Fabric Meter",
      field: "fabricMeter",
    },
    { field: "fabricWidth" },
    { field: "defect" },
    {
      field: "FabricAverage",
      children: [
        { field: "merchant" },
        {
          field: "production",
        },
        { field: "planned" },
      ],
    },
    {
      field: "Sizes",
      children: [
        {
          field: "ratio",
          children: [
            {
              headerName: "Cuttable Qty / Feasible Qty",
              field: "demanded",
              autoHeaderHeight: true,
            },
          ],
        },
      ],
    },
    {
      field: "total",
      children: [
        {
          headerName: "0",
          children: [
            {
              headerName: "0",
              field: "lotTotal",
            },
          ],
        },
      ],
    },
  ];
  const [colDefs, setColDefs] = useState(newColDefs);

  const processSizeWithQty = (sizeWithQty, ratio, demanded) => {
    if (!sizeWithQty || Object.keys(sizeWithQty).length === 0) {
      return [];
    }

    return Object.keys(sizeWithQty).map((size, index) => ({
      field: size,
      children: [
        {
          field: "0",
          headerName: ratio[size] !== undefined ? `${ratio[size]}` : "0",
          children: [
            {
              headerName:
                demanded[size] !== undefined
                  ? `${demanded[size]}(${size})`
                  : "0",
              field: size,
            },
          ],
        },
      ],
    }));
  };

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    setOrderInfo({ ...orderInfoRef.current });
    setRowData([]);
    setBottomRowData([]);
    setOrderDetailData({});
    setDrawerOpen(false);
    isLotAvilable.current = false;
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === child.headerName) {
              child.headerName = "0";
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "lotTotal") {
                grandchild.headerName = "0";
              }
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    setOrderInfo({ ...orderInfoRef.current });
    setRowData([]);
    setBottomRowData([]);
    setOrderDetailData({
      orderNumber: orderInfoRef.current.OrderNumberValue,
      buyerName: orderInfoRef.current.buyerName,
      merchantName: orderInfoRef.current.merchantName,
      orderImage: orderInfoRef.current.orderImage || "",
    });
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === child.headerName) {
              child.headerName = "0";
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "lotTotal") {
                grandchild.headerName = "0";
              }
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const orderAllData = await fetchOrders();
        if (orderAllData.status === 200) {
          setOrderData(orderAllData.data.response);
          const orderIds = Object.keys(orderAllData.data.response.orders);
          const sortedOrderIds = sortOrderIds(orderIds);
          setOrderOptions(sortedOrderIds);
        } else {
          alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadOrders();
  }, []);

  useEffect(() => {
    if (orderInfo.OrderNumberValue) {
      const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
      if (selectedOrder) {
        orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
        orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
        orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
          label: `${order.style ?? ""} ${order.color ?? ""} ${
            order.size ?? ""
          } ${order.remarks ?? ""}`,
          oeid: order.oeId ?? "",
          sizeWithQty: order.sizeWithQty,
        }));

        orderInfoRef.current.orderImage = selectedOrder[0]?.imagePath || "";
        if (orderInfoRef.current.optionsStyle.length === 1) {
          orderInfoRef.current.Stylevalue =
            orderInfoRef.current.optionsStyle[0];
        }
        setOrderInfo({ ...orderInfoRef.current });
        setOrderDetailData({
          orderNumber: orderInfoRef.current.OrderNumberValue,
          buyerName: orderInfoRef.current.buyerName,
          merchantName: orderInfoRef.current.merchantName,
          orderImage: selectedOrder[0]?.imagePath || "",
        });
        setDrawerOpen(true);
      }
    } else {
      orderInfoRef.current.buyerName = "";
      orderInfoRef.current.merchantName = "";
      orderInfoRef.current.optionsStyle = [];
      setOrderInfo({ ...orderInfoRef.current });
    }
  }, [orderInfo.OrderNumberValue, orderData]);

  useEffect(() => {
    const fetchLotData = async () => {
      if (orderInfo.Stylevalue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          if (orderDetail) {
            if (!orderDetail.fabAvl) {
              setDialogOpen(true);
            } else {
              const response = await viewLotData(
                orderInfo.OrderNumberValue,
                orderDetail.oeId
              );
              if (response.status === 200) {
                if (!response.data.response.hasLots) {
                  alertRef.current.showAlert(
                    ENUMTEXT.WARNING.NO_LOT_FOUND,
                    ENUMTEXT.WARNING.WARNING_SERV
                  );
                } else {
                  if (isDataValid(response.data.response)) {
                    isLotAvilable.current = true;
                    const newCols = processSizeWithQty(
                      orderInfo.optionsStyle[0].sizeWithQty,
                      response.data.response.sizeRatio,
                      response.data.response.sizeWithDemandedQty
                    );
                    const updateColumnDefs = (prevCols) => {
                      let newColumnDefs = [...colDefs];
                      newColumnDefs.forEach((colDef) => {
                        if (colDef.field === "total") {
                          colDef.children.forEach((child) => {
                            if (child.headerName === "0") {
                              child.headerName = `${response.data.response.totalRatio}`;
                            }
                            child.children.forEach((grandchild) => {
                              if (grandchild.field === "lotTotal") {
                                grandchild.headerName =
                                  response.data.response.totalQty.toString();
                              }
                            });
                          });
                        }
                      });
                      if (prevCols.length === 7) {
                        const insertIndex = 6;
                        return [
                          ...prevCols.slice(0, insertIndex),
                          ...newCols,
                          ...prevCols.slice(insertIndex),
                        ];
                      } else {
                        let value = prevCols;
                        value.splice(5, newCols.length, ...newCols);
                        return value;
                      }
                    };
                    setColDefs((prevCols) => updateColumnDefs(prevCols));

                    const transformData = (response) => {
                      const extractedFields = newCols.map((item) => item.field);
                      return response.lots.map((lot) => {
                        const lotData = {
                          lotNo: lot.lotNumber,
                          fabricMeter: lot.mtr,
                          fabricWidth: lot.width,
                          defect: lot.defect,
                          merchant: lot.merchantAvg,
                          production: lot.productionAvg,
                          planned: lot.plannedAvg,
                          demanded: lot.avlQty,
                          lotTotal: lot.avlQty,
                        };
                        extractedFields.forEach((size) => {
                          lotData[size] = lot.sizeQtyMap[size];
                        });

                        return lotData;
                      });
                    };
                    setRowData(transformData(response.data.response));

                    const transformBottomRowData = (response) => {
                      const extractedFields = newCols.map((item) => item.field);
                      const totalData = {
                        lotNo: "Total",
                        fabricMeter: response.totalFabricLength,
                        demanded: response.totalCuttableQty,
                      };
                      extractedFields.forEach((size) => {
                        totalData[size] = response.sizeWithSumQty[size];
                      });
                      return [totalData];
                    };
                    setBottomRowData(
                      transformBottomRowData(response.data.response)
                    );
                  } else {
                    setRowData();
                  }
                }
              } else {
                alertRef.current.showAlert(response, "error");
              }
            }
          }
          orderInfoRef.current.fabricName = orderDetail.fabricName;
          orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
          orderInfoRef.current.maxNoPly = orderDetail.maxNoOfPly;
          setOrderInfo({ ...orderInfoRef.current });
          setOrderDetailData((prevData) => ({
            ...prevData,
            style: orderDetail.style,
            color: orderDetail.color,
            fabricName: orderDetail.fabricName,
            fabricQuality: orderDetail.fabricQuality,
            fabricWidth: orderDetail.fabricWidth,
            availableFabric: orderDetail.avlFabric,
            consumedFabric: orderDetail.consumedFabric,
            remainingFabric: orderDetail.remainingFabric,
            maxNoOfPly: orderDetail.maxNoOfPly,
            fabricInhouse: orderDetail.fabInHouse,
            ppApproval: orderDetail.ppApproval,
            cuttingStart: orderDetail.cuttingStartDate,
            cuttingEnd: orderDetail.cuttingEndDate,
            stitchingStart: orderDetail.stitchingStartDate,
            stitchingEnd: orderDetail.stitchingEndDate,
            deliveryDate: orderDetail.deliveryDate,
            sizeWithQty: orderDetail.sizeWithQty,
            sumOfDemandedQty: orderDetail.sumOfDemandedQty,
            sumOfAllowedQty: orderDetail.sumOfAllowedQty,
            sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
            fabricWidthDetails: orderDetail.fabricWidthDetails,
            addOnQuantity: orderDetail.addOnQuantity,
            totalNoOfParts: orderDetail.totalNoOfParts,
            fusingParts: orderDetail.fusingParts,
            handCuttingParts: orderDetail.handCuttingParts,
            reCuttingParts: orderDetail.reCuttingParts,
            pinning: orderDetail.pinning,
            noOfLots: orderDetail.noOfLots,
            merchantAvg: orderDetail.merchantAvg,
            plannedAvg: orderDetail.plannedAvg,
          }));
        }
      } else {
        orderInfoRef.current.fabricName = "";
        orderInfoRef.current.fabricQuality = "";
        orderInfoRef.current.maxNoPly = "";
        setOrderInfo({ ...orderInfoRef.current });
      }
    };

    fetchLotData();
  }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

  const isDataValid = (data) => {
    return (
      data &&
      data.lots &&
      data.sizeRatio &&
      data.sizeWithDemandedQty &&
      data.totalRatio &&
      data.totalQty
    );
  };

  const handleAddLotNavigate = () => {
    navigate("/lot-plan/add", { state: { orderInformation: orderInfo } });
  };

  const handleEditLotNavigate = () => {
    navigate("/lot-plan/edit", { state: { orderInformation: orderInfo } });
  };

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
            }}
          >
            {orderInfo.OrderNumberValue !== null &&
              orderInfo.Stylevalue !== null && (
                <Typography variant="h7">Fabric Shortage:</Typography>
              )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{
              justifyContent: "flex-end",
              display: "flex",
              paddingRight: "15px",
            }}
          >
            <CustomButton variant="contained" onClick={handleAddLotNavigate}>
              {isLotAvilable.current ? "UPDATE LOT" : "ADD LOT PLAN"}
            </CustomButton>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <CustomTable
          colDefs={colDefs}
          drawerOpen={drawerOpen}
          rowData={rowData}
          whichPage={"lotPlan"}
          onClick={handleEditLotNavigate}
          oeId={orderInfo.Stylevalue?.oeid}
          pinnedBottomRowData={bottomRowData}
        />
      </Box>
      <ResponsiveDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        orderDetails={orderInfo}
      />
    </>
  );
}
