import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchOrders } from "../../services/OrderListServices";
import sortOrderIds from "../../components/common/SortOrders";
import CustomAutoComplete from "../../components/common/dropdowns/CustomAutoComplete";
import ResponsiveDialog from "../../components/common/NoStyleModel";
import OrderDetailModel from "../../components/specific/lotplan/OrderDetailsModel";
import DraftCustomTable from "../../components/specific/draft/DraftTableAg";
import EditIcon from "@mui/icons-material/Edit";
import CustomAlert from "../../components/common/CustomAlert";
import { downloadFiles, getFreezedDraft } from "../../services/GetFreezedDraft";
import DownloadIcon from "@mui/icons-material/Download";
import * as ENUMTEXT from "../../constants/AlertText";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

export default function CuttingPlan() {
  const alertRef = useRef();
  const location = useLocation();
  const { orderInformation } = location.state || {
    orderInformation: {
      OrderNumberValue: null,
      Stylevalue: null,
    },
  };
  const [orderData, setOrderData] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderDetailData, setOrderDetailData] = useState({});
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
  });
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [freezed, setFreezed] = useState(false);
  const initialDataLoaded = useRef(false);
  const sizeWithExactQuantity = useRef({});
  const totalNoOfLayers = useRef();
  const navigate = useNavigate();

  const handleDownloadFile = async (layerData, defaultFileName) => {
    let id;
    try {
      if (defaultFileName === "jc-mkr-dxf_file") {
        id = layerData.mkrId;
      } else {
        id = layerData.pdfId;
      }
      if (id === 0) {
        alertRef.current.showAlert(
          ENUMTEXT.WARNING.NOFILEAVL,
          ENUMTEXT.ERROR.ERROR_SERV
        );
      } else {
        const response = await downloadFiles(id);
        if (response.status === 200) {
          const downloadURL = URL.createObjectURL(
            new Blob([response.data], {
              type:
                defaultFileName === "jc-mkr-dxf_file"
                  ? response.data.type
                  : "application/pdf",
            })
          );
          const tempLink = document.createElement("a");
          tempLink.href = downloadURL;
          let extenstion;
          if (response.data.type === "application/pdf") {
            extenstion = "pdf";
          } else if (response.data.type === "drawing/x-dxf") {
            extenstion = "dxf";
          } else {
            extenstion = "mkr";
          }
          tempLink.setAttribute(
            "download",
            `${orderInfo.OrderNumberValue}/${totalNoOfLayers.current}/${layerData.layerNo}.${extenstion}`
          );
          document.body.appendChild(tempLink);
          tempLink.click();
        } else {
          alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ActionCellRenderer = (props) => {
    const handleDownload = () => {
      handleDownloadFile(props.node.data, "jc-mkr-dxf_file");
    };

    const handleDownloadPdf = () => {
      handleDownloadFile(props.node.data, "jc-pdf.pdf");
    };

    const handleEditDraftNavigate = () => {
      let draftId = props.node.data.id;
      navigate("/cutting-plan/drafts/edit", {
        state: {
          orderInformation: orderInfo,
          draftId: Number(draftId),
        },
      });
    };

    return (
      <>
        <IconButton onClick={handleEditDraftNavigate}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={handleDownload}>
          <DownloadIcon />
        </IconButton>
        <IconButton onClick={handleDownloadPdf}>
          <PictureAsPdfIcon />
        </IconButton>
      </>
    );
  };

  const initialColDefs = [
    {
      field: "layerNo",
      cellRenderer: "agGroupCellRenderer",
    },
    {
      field: "layerLength",
    },
    { field: "sizeCombinations" },
    { field: "layerWidth" },
    {
      field: "Sizes",
      children: [
        {
          field: "demandedQty",
          children: [
            {
              field: "allowedQty",
              children: [
                {
                  field: "remainingQty",
                },
              ],
            },
          ],
        },
      ],
    },
    {
      field: "total",
      children: [
        {
          headerName: "0",
          field: "0",
          children: [
            {
              headerName: "0",
              field: "0",
              children: [
                {
                  headerName: "0",
                  field: "draftTotal",
                  aggFunc: "sum",
                },
              ],
            },
          ],
        },
      ],
    },
    { field: "efficiency" },
    { field: "totalFabric" },
    { field: "markerAverage" },
    {
      field: "action",
      minWidth: 180,
      cellRenderer: ActionCellRenderer,
    },
  ];

  const [colDefs, setColDefs] = useState(initialColDefs);
  const [bottomRowData, setBottomRowData] = useState([]);

  const processSizeWithQty = (sizeWithQty, sizeWithQuantities) => {
    if (!sizeWithQty || Object.keys(sizeWithQty).length === 0) {
      return [];
    }

    return Object.keys(sizeWithQty).map((size, index) => ({
      field: size,
      children: [
        {
          field:
            sizeWithQuantities && sizeWithQuantities[size]
              ? `${sizeWithQuantities[size].actualQuantity}`
              : "0",
          children: [
            {
              field:
                sizeWithQuantities && sizeWithQuantities[size]
                  ? `${sizeWithQuantities[size].allowedQuantity}`
                  : "0",
              children: [
                {
                  headerName:
                    sizeWithQuantities && sizeWithQuantities[size]
                      ? `${sizeWithQuantities[size].remainingCutQuantity}`
                      : "0",
                  field: size,
                  aggFunc: "sum",
                },
              ],
            },
          ],
        },
      ],
    }));
  };

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    setOrderInfo({ ...orderInfoRef.current });
    setRowData([]);
    setBottomRowData([]);
    setOrderDetailData({});
    setDrawerOpen(false);
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === "0") {
              child.headerName = `0`;
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "0") {
                grandchild.headerName = `0`;
              }
              grandchild.children.forEach((grandofgrandchild) => {
                if (grandofgrandchild.field === "draftTotal") {
                  grandofgrandchild.headerName = `0`;
                }
              });
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    setOrderInfo({ ...orderInfoRef.current });
    setRowData([]);
    setBottomRowData([]);
    setOrderDetailData({
      orderNumber: orderInfoRef.current.OrderNumberValue,
      buyerName: orderInfoRef.current.buyerName,
      merchantName: orderInfoRef.current.merchantName,
      orderImage: orderInfoRef.current.orderImage || "",
    });
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === "0") {
              child.headerName = `0`;
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "0") {
                grandchild.headerName = `0`;
              }
              grandchild.children.forEach((grandofgrandchild) => {
                if (grandofgrandchild.field === "draftTotal") {
                  grandofgrandchild.headerName = `0`;
                }
              });
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  const loadOrders = async () => {
    try {
      const orderAllData = await fetchOrders();
      if (orderAllData.status === 200) {
        setOrderData(orderAllData.data.response);
        const orderIds = Object.keys(orderAllData.data.response.orders);
        const sortedOrderIds = sortOrderIds(orderIds);
        setOrderOptions(sortedOrderIds);
      } else {
        alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadOrders();
  }, []);

  useEffect(() => {
    if (
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    )
      if (orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
          orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
          orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
            label: `${order.style ?? ""} ${order.color ?? ""} ${
              order.size ?? ""
            } ${order.remarks ?? ""}`,
            oeid: order.oeId ?? "",
            sizeWithQty: order.sizeWithQty,
          }));
          if (orderInfoRef.current.optionsStyle.length === 1) {
            orderInfoRef.current.Stylevalue =
              orderInfoRef.current.optionsStyle[0];
          }
          setOrderInfo({ ...orderInfoRef.current });
          setOrderDetailData({
            orderNumber: orderInfoRef.current.OrderNumberValue,
            buyerName: orderInfoRef.current.buyerName,
            merchantName: orderInfoRef.current.merchantName,
            orderImage: selectedOrder[0]?.imagePath || "",
          });
          setDrawerOpen(true);
        }
      } else {
        orderInfoRef.current.buyerName = "";
        orderInfoRef.current.merchantName = "";
        orderInfoRef.current.optionsStyle = [];
        setOrderInfo({ ...orderInfoRef.current });
      }
  }, [orderInfo.OrderNumberValue, orderData]);

  useEffect(() => {
    const setOrderInfoData = async () => {
      if (
        !initialDataLoaded.current &&
        orderInformation.OrderNumberValue &&
        orderInformation.Stylevalue
      ) {
        orderInfoRef.current.OrderNumberValue =
          orderInformation.OrderNumberValue;
        orderInfoRef.current.Stylevalue = orderInformation.Stylevalue;
        orderInfoRef.current.buyerName = orderInformation.buyerName;
        orderInfoRef.current.fabricName = orderInformation.fabricName;
        orderInfoRef.current.fabricQuality = orderInformation.fabricQuality;
        orderInfoRef.current.maxNoPly = orderInformation.maxNoPly;
        orderInfoRef.current.merchantName = orderInformation.merchantName;
        orderInfoRef.current.optionsStyle = orderInformation.optionsStyle;
        orderInfoRef.current.avlFabric = orderInformation.avlFabric;
        setOrderInfo({ ...orderInfoRef.current });
        initialDataLoaded.current = true;
      }
    };
    setOrderInfoData();
  }, [orderInfo.OrderNumberValue, orderInfo.Stylevalue]);

  useEffect(() => {
    if (
      orderInfo.Stylevalue !== undefined &&
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    ) {
      if (orderInfo.Stylevalue && orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          if (orderDetail) {
            if (!orderDetail.fabAvl) {
              setDialogOpen(true);
            } else {
              orderInfoRef.current.fabricName = orderDetail.fabricName;
              orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
              orderInfoRef.current.maxNoPly = orderDetail.maxNoOfPly;
              setOrderInfo({ ...orderInfoRef.current });
              setOrderDetailData((prevData) => ({
                ...prevData,
                style: orderDetail.style,
                color: orderDetail.color,
                fabricName: orderDetail.fabricName,
                fabricQuality: orderDetail.fabricQuality,
                fabricWidth: orderDetail.fabricWidth,
                availableFabric: orderDetail.avlFabric,
                consumedFabric: orderDetail.consumedFabric,
                remainingFabric: orderDetail.remainingFabric,
                maxNoOfPly: orderDetail.maxNoOfPly,
                fabricInhouse: orderDetail.fabInHouse,
                ppApproval: orderDetail.ppApproval,
                cuttingStart: orderDetail.cuttingStartDate,
                cuttingEnd: orderDetail.cuttingEndDate,
                stitchingStart: orderDetail.stitchingStartDate,
                stitchingEnd: orderDetail.stitchingEndDate,
                deliveryDate: orderDetail.deliveryDate,
                sizeWithQty: orderDetail.sizeWithQty,
                sumOfDemandedQty: orderDetail.sumOfDemandedQty,
                noOfLots: orderDetail.noOfLots,
                merchantAvg: orderDetail.merchantAvg,
                plannedAvg: orderDetail.plannedAvg,
                sumOfAllowedQty: orderDetail.sumOfAllowedQty,
                sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
                fabricWidthDetails: orderDetail.fabricWidthDetails,
                addOnQuantity: orderDetail.addOnQuantity,
                totalNoOfParts: orderDetail.totalNoOfParts,
                fusingParts: orderDetail.fusingParts,
                handCuttingParts: orderDetail.handCuttingParts,
                reCuttingParts: orderDetail.reCuttingParts,
                pinning: orderDetail.pinning,
              }));
              const getDrafts = async () => {
                try {
                  let draftResponse = await getFreezedDraft(
                    orderInfo.Stylevalue.oeid
                  );
                  if (draftResponse.status === 200) {
                    if (draftResponse.data.response.length === 0) {
                      alertRef.current.showAlert(
                        ENUMTEXT.WARNING.NO_DRAFT_FOUND,
                        ENUMTEXT.WARNING.WARNING_SERV
                      );
                    } else {
                      const newCols = processSizeWithQty(
                        orderInfo.optionsStyle[0].sizeWithQty,
                        draftResponse.data.response.sizeWithQuantities
                      );
                      const updateColumnDefs = (prevCols) => {
                        let newColumnDefs = [...colDefs];
                        newColumnDefs.forEach((colDef) => {
                          if (colDef.field === "total") {
                            colDef.children.forEach((child) => {
                              if (child.headerName === "0") {
                                child.headerName = `${orderDetail.sumOfDemandedQty}`;
                              }
                              child.children.forEach((grandchild) => {
                                if (grandchild.field === "0") {
                                  grandchild.headerName = `${draftResponse.data.response.sumOfSizeWithAllowedQty}`;
                                }
                                grandchild.children.forEach(
                                  (grandofgrandchild) => {
                                    if (
                                      grandofgrandchild.field === "draftTotal"
                                    ) {
                                      grandofgrandchild.headerName = `${draftResponse.data.response.sumOfSizeWithCutQty}`;
                                    }
                                  }
                                );
                              });
                            });
                          }
                        });
                        if (prevCols.length === 10) {
                          const insertIndex = 5;
                          return [
                            ...prevCols.slice(0, insertIndex),
                            ...newCols,
                            ...prevCols.slice(insertIndex),
                          ];
                        } else {
                          let value = prevCols;
                          value.splice(5, newCols.length, ...newCols);
                          return value;
                        }
                      };
                      setColDefs((prevCols) => updateColumnDefs(prevCols));
                      const transformData = (response) => {
                        totalNoOfLayers.current = response.layers.length;
                        console.log("totalNoOf Layers", response.layers.length);
                        const extractedFields = newCols.map(
                          (item) => item.field
                        );
                        return response.layers.map((layer) => {
                          const draftData = {
                            id: response.id,
                            layerNo: layer.number,
                            layerLength: layer.length,
                            sizeCombinations: layer.sizeCombo,
                            layerWidth: layer.width,
                            efficiency: layer.efficiency,
                            totalFabric: layer.totalFabric,
                            markerAverage: layer.avg,
                            remainingQty: layer.qty,
                            // draftTotal: layer.qty,
                            sizeWithRemainingQty: layer.sizeWithRemainingQty,
                            pdfId: layer.pdfMediaId,
                            mkrId: layer.mkrOrDxfMediaId,
                          };
                          extractedFields.forEach((size) => {
                            draftData[size] = layer.sizeWithQty[size];
                          });

                          return draftData;
                        });
                      };
                      sizeWithExactQuantity.current =
                        draftResponse.data.response.sizeWithQuantities;
                      setRowData(transformData(draftResponse.data.response));

                      const transformBottomRowData = (response) => {
                        const extractedFields = newCols.map(
                          (item) => item.field
                        );
                        const totalData = {
                          layerNo: "Total",
                          layerLength: response.avgLayerLength,
                          markerAverage: response.layerAvg,
                          totalFabric: response.totalFabricConsumed,
                          efficiency: response.totalEfficiency,
                          remainingQty: response.totalPlyQty,
                          draftTotal: response.piecesCut,
                          action: "",
                        };
                        extractedFields.forEach((size) => {
                          totalData[size] =
                            response.sizeWithQuantities[size].sumOfCutQuantity;
                        });
                        return [totalData];
                      };
                      setBottomRowData(
                        transformBottomRowData(draftResponse.data.response)
                      );
                    }
                  } else {
                    if (draftResponse === "Data not found") {
                      alertRef.current.showAlert(
                        ENUMTEXT.WARNING.NO_DRAFT_FREEZE,
                        ENUMTEXT.WARNING.WARNING_SERV
                      );
                    } else {
                      alertRef.current.showAlert(
                        draftResponse,
                        ENUMTEXT.ERROR.ERROR_SERV
                      );
                    }
                  }
                } catch (e) {}
              };
              getDrafts();
            }
          }
        }
      } else {
        orderInfoRef.current.fabricName = "";
        orderInfoRef.current.fabricQuality = "";
        orderInfoRef.current.maxNoPly = "";
        setOrderInfo({ ...orderInfoRef.current });
      }
    }
  }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <ResponsiveDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        orderDetails={orderInfo}
      />
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}></Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            xl={3}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box style={{ marginRight: "15px" }}></Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DraftCustomTable
          colDefs={colDefs}
          drawerOpen={drawerOpen}
          rowData={rowData}
          whichPage={"cutting"}
          setFreezed={setFreezed}
          freezed={freezed}
          sizeWithQty={sizeWithExactQuantity.current}
          pinnedBottomRowData={bottomRowData}
        />
      </Box>
    </>
  );
}
