import React, {
  useEffect,
  useMemo,
  useState,
  useRef,
  useCallback,
} from "react";
import { Autocomplete, Grid, Box, TextField } from "@mui/material";
import ResponsiveDialog from "../components/common/NoStyleModel";
import { fetchOrders } from "../services/OrderListServices";
import sortOrderIds from "../components/common/SortOrders";
import CustomTable from "../components/common/tables/CustomTable";
import CustomAlert from "../components/common/CustomAlert";
import OrderDetailModel from "../components/specific/lotplan/OrderDetailsModel";
import CustomAutoComplete from "../components/common/dropdowns/CustomAutoComplete";
import { GetTicketSequencing } from "../services/TicketSequencingServices";
import * as ENUMTEXT from "../constants/AlertText";

export default function TicketSequencing() {
  const [colDefs, setColDefs] = useState([
    { field: "lotNumber" },
    { field: "fabricMeter" },
    { field: "fabricWidth" },
    { field: "defect" },
    {
      field: "FabricAverage",
      children: [
        { field: "merchant" },
        {
          field: "production",
        },
        { field: "planned" },
      ],
    },
    { field: "layerSequencing" },
  ]);
  const [rowData, setRowData] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const alertRef = useRef();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [orderOptions, setOrderOptions] = useState([]);
  const orderInfoRef = useRef({
    OrderNumberValue: null,
    buyerName: "",
    merchantName: "",
    optionsStyle: [],
    Stylevalue: null,
    fabricName: "",
    fabricQuality: "",
    maxNoPly: "",
    layerNumber: "",
    sublayerNumber: "",
  });
  const OrderNumberInputValue = useRef();
  const StyleInputValue = useRef();
  const LayerInputValue = useRef();
  const subLayerInputValue = useRef();
  const [orderInfo, setOrderInfo] = useState(orderInfoRef.current);
  const [orderDetailData, setOrderDetailData] = useState({});
  const [layerDetail, setLayerDetail] = useState([]);
  const [layerLotData, setLayerLotData] = useState();
  const [layerTickets, setLayerTickets] = useState();
  const [layerSizeValue, setLayerSizeValue] = useState();
  const [sublayerLotData, setSublayerLotData] = useState();
  const [sublayerTickets, setsubLayerTickets] = useState();
  const [subLayerData, setSubLayerData] = useState();
  const [bottomRowData, setBottomRowData] = useState([]);
  const [OptionLayerNo, setOptionLayerNo] = useState([]);
  const [OptionSubLayerNo, setOptionSubLayerNo] = useState([]);

  const handleOrderChange = useCallback((event, newValue) => {
    orderInfoRef.current.OrderNumberValue = newValue;
    orderInfoRef.current.Stylevalue = null;
    orderInfoRef.current.layerNumber = null;
    setOrderInfo({ ...orderInfoRef.current });
    setOrderDetailData({});
    setDrawerOpen(false);
    setRowData([]);
    setBottomRowData([]);
    setOptionLayerNo([]);
    setOptionSubLayerNo([]);
    setBottomRowData([]);
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === child.headerName) {
              child.headerName = "0";
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "lotTotal") {
                grandchild.headerName = "0";
              }
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  const getTicSeq = async (oeId) => {
    try {
      const response = await GetTicketSequencing(oeId);
      if (response.status === 200) {
        const ResponseData = response.data.response;
        setLayerDetail(ResponseData);
        const layerNumber = response.data.response.layerSizeValues.map(
          (item) => item.layerName
        );
        setOptionLayerNo(layerNumber);
      } else {
        alertRef.current.showAlert(response, ENUMTEXT.ERROR.ERROR_SERV);
      }
    } catch (err) {
      console.log("GetError", err);
    }
  };

  const handleStyleChange = useCallback((event, newValue) => {
    orderInfoRef.current.Stylevalue = newValue;
    orderInfoRef.current.layerNumber = null;
    setOrderInfo({ ...orderInfoRef.current });
    setOrderDetailData({
      orderNumber: orderInfoRef.current.OrderNumberValue,
      buyerName: orderInfoRef.current.buyerName,
      merchantName: orderInfoRef.current.merchantName,
      orderImage: orderInfoRef.current.orderImage || "",
    });
    setRowData([]);
    setOptionLayerNo([]);
    setBottomRowData([]);
    setOptionSubLayerNo([]);
    const updateColumnDefs = (prevCols) => {
      let newColumnDefs = [...colDefs];
      newColumnDefs.forEach((colDef) => {
        if (colDef.field === "total") {
          colDef.children.forEach((child) => {
            if (child.headerName === child.headerName) {
              child.headerName = "0";
            }
            child.children.forEach((grandchild) => {
              if (grandchild.field === "lotTotal") {
                grandchild.headerName = "0";
              }
            });
          });
        }
      });
      return newColumnDefs;
    };
    setColDefs((prevCols) => updateColumnDefs(prevCols));
  }, []);

  const handleLayerChange = (event, newValue) => {
    orderInfoRef.current.layerNumber = newValue;
    orderInfoRef.current.sublayerNumber = null;
    setOrderInfo({ ...orderInfoRef.current });
    setRowData([]);
    setBottomRowData([]);
    setOptionSubLayerNo([]);
    for (let i = 0; i < layerDetail.layerSizeValues.length; i++) {
      if (
        layerDetail.tickets[i].layerNumber === orderInfoRef.current.layerNumber
      ) {
        setLayerLotData(layerDetail.tickets[i].lots);
        setLayerTickets(layerDetail.tickets[i]);
        setLayerSizeValue(layerDetail.layerSizeValues[i]);
        break;
      }
    }

    // for (let i = 0; i < layerDetail.tickets.length; i++) {
    //   if (
    //     layerDetail.tickets[i].layerNumber ===
    //       orderInfoRef.current.layerNumber &&
    //     layerDetail.tickets[i].lots !== undefined
    //   ) {
    //     const sublayerNumber = layerDetail.tickets[i].lots[i].subLayers.map(
    //       (item) => item.subLayerName
    //     );
    //     console.log("sln" + sublayerNumber);
    //     setOptionSubLayerNo(sublayerNumber);
    //   }
    // }
    for (let i = 0; i < layerDetail.tickets.length; i++) {
      if (
        layerDetail.tickets[i].layerNumber ===
          orderInfoRef.current.layerNumber &&
        Array.isArray(layerDetail.tickets[i].lots)
      ) {
        // Use a different index for 'lots' if it needs to iterate over multiple lots
        for (let j = 0; j < layerDetail.tickets[i].lots.length; j++) {
          const lot = layerDetail.tickets[i].lots[j];

          if (lot && Array.isArray(lot.subLayers)) {
            const sublayerNumber = lot.subLayers.map(
              (item) => item.subLayerName
            );
            console.log("sln" + sublayerNumber);
            setOptionSubLayerNo(sublayerNumber);
          }
        }
      }
    }
  };

  const handlesubLayerChange = async (event, newValue) => {
    orderInfoRef.current.sublayerNumber = newValue;
    setOrderInfo({ ...orderInfoRef.current });

    const layersizedata = layerDetail?.tickets.find(
      (x) => x.layerNumber === orderInfoRef.current.layerNumber
    );
    console.log("layersizedata", layersizedata);

    if (!layersizedata) {
      console.error(
        "Layer size data not found for layer:",
        orderInfoRef.current.layerNumber
      );
      return;
    }

    const lotsdata = layersizedata.lots;
    console.log("lotdata", lotsdata);

    if (!Array.isArray(lotsdata)) {
      console.error("Invalid lots data structure:", lotsdata);
      return;
    }
    const selectedsublayerdataarr = [];
    for (let i = 0; i < lotsdata.length; i++) {
      const selectedsublayerdata = lotsdata[i]?.subLayers.find(
        (x) => x.subLayerName === newValue
      );
      console.log("selectedsublayerdata", selectedsublayerdata);

      selectedsublayerdataarr.push(selectedsublayerdata?.sizeCombination || {});
    }

    console.log("selectedsublayerdataarr ", selectedsublayerdataarr);
    let tempRowData = [...rowData];
    if (!Array.isArray(tempRowData)) {
      console.error("Invalid row data structure:", tempRowData);
      return;
    }
    for (let i = 0; i < selectedsublayerdataarr.length; i++) {
      const arr = selectedsublayerdataarr[i];

      for (let j = 0; j < arr.length; j++) {
        const obj = arr[j];

        const id = obj.id;
        const start = obj.start;
        const end = obj.end;
        const size = obj.size;

        const value = `${i + 1} ${size} ${start}- ${i + 1} ${size} ${end}`;

        // const key = Object.keys(obj)[0];

        if (tempRowData[i]) {
          if (start === 0 && end === 0) {
            tempRowData[i][size] = "0";
          } else {
            tempRowData[i][size] = value;
          }
        } else {
          console.error("Row data not found for index:", i);
        }
      }
    }
    setRowData([...tempRowData]);
    console.log("tempRowData", rowData);
  };

  const processSizeWithQty = (sizeCombo) => {
    if (!sizeCombo || Object.keys(sizeCombo).length === 0) {
      return [];
    }
    const sizeComboArray = sizeCombo.sizeCombination.split(/[\s,-]+/);

    let result = {
      field: "ticketSequencing",
      children: sizeComboArray.map((size) => ({ field: size })),
    };
    return [result];
  };

  function extractNumber(inputString) {
    const regex = /\b\w+\s+(\w+)/;
    const match = inputString.match(regex);
    return match ? match[1] : null;
  }

  useEffect(() => {
    if (orderInfo.layerNumber) {
      const newCols = processSizeWithQty(layerTickets);
      const updateColumnDefs = (prevCols) => {
        if (prevCols.length === 6) {
          const insertIndex = 6;
          return [...prevCols.slice(0, insertIndex), ...newCols];
        } else {
          let value = [...prevCols];
          value.splice(6, newCols.length, ...newCols);
          return value;
        }
      };
      setColDefs((prevCols) => updateColumnDefs(prevCols));
      const sizeValueListObject = {};
      layerSizeValue.sizeValueListMap.forEach((item) => {
        const key = Object.keys(item)[0];
        const [start, end] = item[key].split("-").map(Number);
        sizeValueListObject[key] = { start, end };
      });
      console.log("===========>>>>>>>>>>", layerLotData);
      const transformData = () => {
        const extractedFields = newCols[0].children.map((child) => child.field);
        let previousLayerSequencing = {};
        return layerLotData.map((lot, index) => {
          const lotData = {
            lotNumber: lot.lotNumber,
            fabricMeter: lot.fabricLength,
            fabricWidth: lot.fabricWidth,
            defect: lot.defect,
            merchant: lot.merchantAvg,
            planned: lot.plannedAvg,
            production: lot.productionAvg,
            layerSequencing: lot.value,
          };
          const totalRow = {
            lotNumber: "Total",
            fabricMeter: layerTickets.fabricLength,
            layerSequencing: layerTickets.ticketTotal,
          };
          if (index === 0) {
            extractedFields.forEach((size) => {
              lotData[size] = `${extractNumber(lot.lotNumber)} ${size} ${
                sizeValueListObject[size].start === 0
                  ? 1
                  : sizeValueListObject[size].start
              } - ${extractNumber(lot.lotNumber)} ${size} ${
                sizeValueListObject[size].start + lot.value
              }`;
              previousLayerSequencing[size] =
                sizeValueListObject[size].start + lot.value;
              console.log("previousLayerSequencing", previousLayerSequencing);
            });
          } else {
            extractedFields.forEach((size) => {
              lotData[size] = `${extractNumber(lot.lotNumber)} ${size} ${
                1 + previousLayerSequencing[size]
              } - ${extractNumber(lot.lotNumber)} ${size} ${
                lot.value + previousLayerSequencing[size]
              }`;
              previousLayerSequencing[size] =
                previousLayerSequencing[size] + lot.value;
            });
          }
          setBottomRowData([totalRow]);
          // previousLayerSequencing += lot.value;
          return lotData;
        });
      };

      const data = transformData();
      setRowData(data);
    }
  }, [layerLotData, orderInfo.layerNumber, layerTickets, layerSizeValue]);

  useEffect(() => {
    if (
      orderInfo.Stylevalue !== undefined &&
      orderInfo.OrderNumberValue !== undefined &&
      Object.keys(orderData?.orders ?? {}).length > 0
    ) {
      if (orderInfo.Stylevalue && orderInfo.OrderNumberValue) {
        const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
        if (selectedOrder) {
          const orderDetail = selectedOrder.find(
            (order) => order.oeId === orderInfo.Stylevalue.oeid
          );
          if (orderDetail) {
            if (!orderDetail.fabAvl) {
              setDialogOpen(true);
            } else {
              orderInfoRef.current.fabricName = orderDetail.fabricName;
              orderInfoRef.current.fabricQuality = orderDetail.fabricQuality;
              setOrderInfo({ ...orderInfoRef.current });
              getTicSeq(orderInfo.Stylevalue.oeid);
              setOrderDetailData((prevData) => ({
                ...prevData,
                style: orderDetail.style,
                color: orderDetail.color,
                fabricName: orderDetail.fabricName,
                fabricQuality: orderDetail.fabricQuality,
                fabricWidth: orderDetail.fabricWidth,
                availableFabric: orderDetail.avlFabric,
                consumedFabric: orderDetail.consumedFabric,
                remainingFabric: orderDetail.remainingFabric,
                maxNoOfPly: orderDetail.maxNoOfPly,
                fabricInhouse: orderDetail.fabInHouse,
                ppApproval: orderDetail.ppApproval,
                cuttingStart: orderDetail.cuttingStartDate,
                cuttingEnd: orderDetail.cuttingEndDate,
                stitchingStart: orderDetail.stitchingStartDate,
                stitchingEnd: orderDetail.stitchingEndDate,
                deliveryDate: orderDetail.deliveryDate,
                sizeWithQty: orderDetail.sizeWithQty,
                sumOfDemandedQty: orderDetail.sumOfDemandedQty,
                sumOfAllowedQty: orderDetail.sumOfAllowedQty,
                sizeWithAllowedQty: orderDetail.sizeWithAllowedQty,
                fabricWidthDetails: orderDetail.fabricWidthDetails,
                addOnQuantity: orderDetail.addOnQuantity,
                totalNoOfParts: orderDetail.totalNoOfParts,
                fusingParts: orderDetail.fusingParts,
                handCuttingParts: orderDetail.handCuttingParts,
                reCuttingParts: orderDetail.reCuttingParts,
                pinning: orderDetail.pinning,
                noOfLots: orderDetail.noOfLots,
                merchantAvg: orderDetail.merchantAvg,
                plannedAvg: orderDetail.plannedAvg,
              }));
            }
          }
        }
      } else {
      }
    }
  }, [orderInfo.Stylevalue, orderData, orderInfo.OrderNumberValue]);

  useEffect(() => {
    const loadOrders = async () => {
      try {
        const orderAllData = await fetchOrders();
        if (orderAllData.status === 200) {
          setOrderData(orderAllData.data.response);
          const orderIds = Object.keys(orderAllData.data.response.orders);
          const sortedOrderIds = sortOrderIds(orderIds);
          setOrderOptions(sortedOrderIds);
        } else {
          alertRef.current.showAlert(orderAllData, ENUMTEXT.ERROR.ERROR_SERV);
        }
      } catch (error) {
        console.error(error);
      }
    };
    loadOrders();
  }, []);

  useEffect(() => {
    if (orderInfo.OrderNumberValue) {
      const selectedOrder = orderData.orders[orderInfo.OrderNumberValue];
      if (selectedOrder) {
        orderInfoRef.current.buyerName = selectedOrder[0]?.buyer || "";
        orderInfoRef.current.merchantName = selectedOrder[0]?.merchant || "";
        orderInfoRef.current.optionsStyle = selectedOrder.map((order) => ({
          label: `${order.style ?? ""} ${order.color ?? ""} ${
            order.size ?? ""
          } ${order.remarks ?? ""}`,
          oeid: order.oeId ?? "",
          sizeWithQty: order.sizeWithQty,
        }));
        if (orderInfoRef.current.optionsStyle.length === 1) {
          orderInfoRef.current.Stylevalue =
            orderInfoRef.current.optionsStyle[0];
        }
        setOrderInfo({ ...orderInfoRef.current });
        setOrderDetailData({
          orderNumber: orderInfoRef.current.OrderNumberValue,
          buyerName: orderInfoRef.current.buyerName,
          merchantName: orderInfoRef.current.merchantName,
          orderImage: selectedOrder[0]?.imagePath || "",
        });
        setDrawerOpen(true);
      }
    } else {
      orderInfoRef.current.buyerName = "";
      orderInfoRef.current.merchantName = "";
      orderInfoRef.current.optionsStyle = [];
      setOrderInfo({ ...orderInfoRef.current });
    }
  }, [orderInfo.OrderNumberValue, orderData]);

  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <CustomAlert ref={alertRef} />
      <Box>
        <OrderDetailModel
          data={orderDetailData}
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
        />
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <Grid container spacing={1} style={{ padding: "10px" }}>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.OrderNumberValue}
              onChange={handleOrderChange}
              options={orderOptions}
              label="Select Order Number"
              ref={OrderNumberInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <CustomAutoComplete
              value={orderInfo.Stylevalue}
              onChange={handleStyleChange}
              options={orderInfo.optionsStyle}
              label="Select Style Details"
              ref={StyleInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ResponsiveDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              orderDetails={orderInfo}
            />
            <CustomAutoComplete
              value={orderInfo.layerNumber}
              onChange={handleLayerChange}
              options={OptionLayerNo}
              label="Select Layer Number"
              ref={LayerInputValue}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
            <ResponsiveDialog
              open={dialogOpen}
              onClose={() => setDialogOpen(false)}
              orderDetails={orderInfo}
            />
            <CustomAutoComplete
              value={orderInfo.sublayerNumber}
              onChange={handlesubLayerChange}
              options={OptionSubLayerNo}
              label="Select SubLayer Number"
              ref={subLayerInputValue}
              disabled={OptionSubLayerNo.length === 0}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: drawerOpen ? `calc(100% - 250px)` : "100%" }}>
        <CustomTable
          rowData={rowData}
          colDefs={colDefs}
          pinnedBottomRowData={bottomRowData}
        />
      </Box>
    </>
  );
}
