import React, { forwardRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Paper, Typography } from "@mui/material";
import styled from "styled-components";

const CustomLayerAutoComplete = forwardRef((props, ref) => {
  const {
    value,
    onChange,
    options,
    label,
    error,
    disabled,
    tableData,
    orderId,
  } = props;

  const check = (item, orderId) => {
    if (tableData === undefined) return null;
    const table = Object.values(tableData);
    for (let i = 0; i < table.length; i++) {
      for (let x = 0; x < table[i].length; x++) {
        if (table[i][x].layerName === item && table[i][x].orderId === orderId) {
          return "green";
        }
      }
    }
    return null;
  };

  function CustomOption(props, option) {
    return (
      <Typography
        id={props.id}
        onClick={props.onClick}
        key={props.key}
        // className={props.className}
        data-option-index={props["data-option-index"]}
        sx={{
          padding: "8px 8px 8px 8px",
          backgroundColor: check(option, orderId),
          "& .MuiAutocomplete-listbox": {
            "& .MuiAutocomplete-option[aria-selected='true']": {
              bgcolor: check(option, orderId),
              "&.Mui-focused": {
                bgcolor: check(option, orderId),
              },
            },
          },
          "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
            bgcolor: check(option),
          },
          "&:hover .icon": {
            color: "inherit", // Ensure icon color doesn't change on hover
          },
          cursor: "pointer",
        }}
      >
        {option}
      </Typography>
    );
  }

  function CustomPaper({ children }) {
    return (
      <Paper
        sx={{
          "& .MuiAutocomplete-listbox": {
            "& .MuiAutocomplete-option[aria-selected='true']": {
              bgcolor: "transparent",
              "&.Mui-focused": {
                bgcolor: "transparent",
              },
            },
          },
          "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": {
            bgcolor: "transparent",
          },
        }}
      >
        {children}
      </Paper>
    );
  }
  return (
    <Autocomplete
      value={value}
      onChange={onChange}
      PaperComponent={CustomPaper}
      id="controllable-states-demo"
      renderOption={CustomOption}
      options={options}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          ref={ref}
          value={ref.current?.value}
          InputLabelProps={{ shrink: true }}
          error={error}
          label={label}
        />
      )}
    />
  );
});

export default CustomLayerAutoComplete;
