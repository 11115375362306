import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import styled from 'styled-components';

const OrderText = styled(Typography)`
margin-bottom: 8px !important;
font-size:14px !important;
`;

const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

const FieldContainer = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 8px;
`;

const FieldName = styled(Typography)`
  font-size: 14px;
`;

const FieldValue = styled(Typography)`
  font-size: 14px;
  text-align: left;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, setOpen, leftFields, rightFields  }) {
    
    const handleClose = () => {
        setOpen(false);
    };

    const renderFields = (fields) => (
        Object.entries(fields).map(([key, value], index) => (
            <FieldContainer key={index}>
                <FieldName>{key}</FieldName>
                <FieldValue>{value}</FieldValue>
            </FieldContainer>
        ))
    );

    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                PaperProps={{
                    style: {
                        borderRadius: "10px",
                        height: '810px',
                        width: "1050px",
                        maxWidth: "1500px",
                        background: '#28313e'
                    },
                }}
            >
                <Box sx={{display:"flex",flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <DialogTitle sx={{textAlign:"center", flexGrow:"1", display:"flex", alignItems:"center", justifyContent:"center"}}>{"Order Details"}</DialogTitle>
                    <IconButton onClick={handleClose}><CancelIcon /></IconButton>
                </Box>
                <DialogContent>
                    {/* <Box>
                        {<OrderText>Order Number </OrderText>}
                    </Box> */}
                    {/* <DialogContentText id="alert-dialog-slide-description">
                        Let Google help apps determine location. This means sending anonymous
                        location data to Google, even when no apps are running.
                    </DialogContentText> */}
                    <StyledGrid container spacing={2}>
                        <Grid item xs={6} style={{borderRight:"1px solid"}}>
                            {renderFields(leftFields)}
                        </Grid>
                        <Grid item xs={6}>
                            {renderFields(rightFields)}
                        </Grid>
                    </StyledGrid>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
