import axios from "axios";
import * as ENUMTEXT from "../constants/AlertText";

const interceptor = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 1000000000,
  headers: {
    "Content-Type": "application/json",
  },
});

const api = interceptor;

interceptor.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.request.use(
  (config) => {
    const token = process.env.REACT_APP_AUTH_TOKEN;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["ngrok-skip-browser-warning"] = "69420";
    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"];
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response;
    } else if (response.status === 204) {
      return ENUMTEXT.WARNING.MESSAGE_204;
    }
  },
  (error) => {
    let errorMessage = ENUMTEXT.ERROR.UNEXPECTED;
    if (error.response) {
      const status = error.response.status;
      switch (status) {
        case 500:
          errorMessage = ENUMTEXT.ERROR.E500;
          break;
        case 401:
          errorMessage = ENUMTEXT.ERROR.E401;
          break;
        case 403:
          errorMessage = ENUMTEXT.ERROR.E403;
          break;
        case 404:
          errorMessage = ENUMTEXT.ERROR.E404;
          break;
        default:
          errorMessage = `Error: ${status}`;
      }
    } else if (error.request) {
      errorMessage = ENUMTEXT.ERROR.NO_INTERNET;
    }
    return Promise.reject(errorMessage);
  }
);

export default api;
