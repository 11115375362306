import React, { createContext, useState } from "react";

const GlobalContext = createContext();

const GlobalProvider = ({ children }) => {
  const [globalValue, setGlobalValue] = useState("all");

  const updateGlobalValue = (newValue) => {
    setGlobalValue(newValue);
  };

  return (
    <GlobalContext.Provider value={{ globalValue, updateGlobalValue }}>
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContext, GlobalProvider };
