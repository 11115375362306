import api from "./Api";

async function GetTicketSequencing(oeId) {
    try {
      const response = await api.get(`tickets/getAll?oeId=${oeId}`);
      return response;
    } catch (error) {
      console.error("Failed to fetch orders:", error);
      return error;
    }
  }

  export {GetTicketSequencing}