// src/components/Header.jsx
import React, { useContext } from "react";
import MobileNav from "./MobileNav";
import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import { Select, MenuItem, useTheme } from "@mui/material";
import { GlobalContext } from "../../providers/GlobalProvider";
import styled from 'styled-components';

const HeaderWrapper = styled.header`
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.textColor};
  position:fixed;
  width:100%;
  z-Index:100;

  .dropdown {
    color: ${(props) => props.textColor};
    .MuiSvgIcon-root {
      color: ${(props) => props.textColor};
    }
  }
`;

const Header = () => {
  const { globalValue, updateGlobalValue } = useContext(GlobalContext);
  const theme = useTheme();

  return (
    <>
      <HeaderWrapper
        bgColor={theme.palette.header.background}
        textColor={theme.palette.header.text}

      >
        <div className="nav-area">
          <Link to="/" className="logo">
            <img
              src={`${process.env.PUBLIC_URL}/images/jcLogo.jpg`}
              alt="Logo"
              className="logo"
            />
          </Link>
          <Navbar />
          <Select
            size="small"
            className="dropdown"
            sx={{
              width: 200,
            }}
            value={globalValue}
            onChange={(event) => {
              updateGlobalValue(event.target.value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="garments">Garments</MenuItem>
            <MenuItem value="home">Home</MenuItem>
          </Select>
          <MobileNav />
        </div>
      </HeaderWrapper>
      <div className="nav-area" style={{paddingTop:"70px"}}>
        <BreadCrumbs />
      </div>
    </>
  );
};

export default Header;
